<template>
  <section class="all-templates-view">
    <el-row type="flex" justify="center" align="center">
      <el-col :span="22">
        <div class="inner-navbar mb-2">
          <el-row>
            <el-col :lg="8" :md="8" :sm="24" :xs="24">
              <div class="left-block">
                <h3
                  class="title fw-normal text-dark mb-0 ls-05"
                  v-if="
                    this.$route.query &&
                    this.$route.query.group &&
                    getSingleEntityGroupData &&
                    getSingleEntityGroupData.name
                  "
                >
                  {{ getSingleEntityGroupData.name }}
                </h3>
                <h3 class="fw-medium blue-dark mb-0 ls-05" v-else :style="getIsMobile ? 'margin-top:10px' : ''">
                  All Templates
                </h3>
              </div>
            </el-col>
            <el-col
              style="text-align: right"
              class="top-buttons-wrapper"
              :lg="16"
              :md="16"
              :sm="24"
              :xs="24"
            >
              <div class="right-block">
                <el-input
                  class="search mr-2"
                  placeholder="Search"
                  prefix-icon="el-icon-search"
                  size="small"
                  v-model="search_string"
                  clearable
                  :style="getIsMobile ? 'width:130px' : 'width:170px;'"
                >
                </el-input>

                <el-select
                  v-model="selectedStatus"
                  filterable
                  clearable
                  class="select"
                  placeholder="Select Status"
                  @change="loadData"
                  :style="getIsMobile ? 'width:130px' : 'width:170px;'"
                >
                  <el-option
                    v-for="(status, index) in statusOption"
                    :key="index"
                    :value="status.value"
                    :label="status.name"
                  ></el-option>
                </el-select>

                <el-button
                  v-if="checkPermissions('createDocument')"
                  class="create-btn px-2 ml-2 p-05"
                  :style="getIsMobile ? 'width:120px' : 'width:160px;'"
                  @click="goToCreateDocument"
                  size="medium"
                  type="primary"
                >
                  <i class="fa fa-plus-square-o"></i>
                  <span :class="getIsMobile ? 'hidden-sm-and-down' : ''">
                    Create Template
                  </span>
                </el-button>
              </div>
            </el-col>
            <!-- </div> -->
          </el-row>
        </div>
        <div
          v-loading="configureLoading"
          :element-loading-text="LoadingText"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="vue-data-table-default"
        >
          <el-dropdown
            class="import-dropdown-btn mb-05"
            v-if="this.selected_Template.length"
          >
            <el-button size="mini" class="el-dropdown-link">
              Delete
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <a
                v-if="this.selectedStatus != 'ACTIVE'"
                @click="selectedTemplateStatus()"
              >
                <el-dropdown-item>
                  <i class="el-icon-document-checked"></i>
                  Active
                </el-dropdown-item>
              </a>
              <a v-if="this.selectedStatus == 'ACTIVE'" @click="bulkdelete()">
                <el-dropdown-item>
                  <i class="el-icon-delete"></i>
                  Delete
                </el-dropdown-item>
              </a>
              <a v-else @click="bulkpermanentdelete">
                <el-dropdown-item>
                  <i class="el-icon-delete-solid"></i>
                  <span>Permanent Delete</span>
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <vue-horizontal scroll> -->
          <data-tables-server
            :fullscreen="true"
            :total="total"
            style="width: 100%"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            :data="templates"
            @query-change="loadData"
            @sort-change="sortChange"
            v-if="!getIsMobile"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="Name"
              id="name"
              prop="title"
              width="350"
              fixed="left"
              sortable="custom"
            >
              <template slot-scope="scope">
                <div class="icon-block">
                  <div class="icon">
                    <div class="image-file" v-if="isImage(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/image-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                    <div class="pdf-file" v-if="isPdf(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/pdf-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                    <div
                      class="doc-file"
                      v-if="
                        !isImage(scope.row.file_type) &&
                        !isPdf(scope.row.file_type)
                      "
                    >
                      <img
                        src="@/assets/img/icons/doc-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="icon-text">
                    <template v-if="scope.row.title">
                      <el-popover
                        width="250"
                        trigger="hover"
                        :content="scope.row.title"
                      >
                        <p
                          slot="reference"
                          class="doc-title"
                          @click="onEdit(scope.row)"
                          style="cursor: pointer"
                        >
                          {{ getDocumentTitle(scope.row.title) }}
                        </p>
                      </el-popover>
                      <span v-if="scope.row.user_id == authid" class="mt-3">
                        <p class="doc-sub">
                          Created by: {{ getSharedUser(scope.row) }}
                        </p>
                      </span>
                      <span
                        v-else-if="
                          scope.row.company_id &&
                          getActiveWorkspace &&
                          getActiveWorkspace.company_id &&
                          scope.row.user_id != authid &&
                          scope.row.company_id == getActiveWorkspace.company_id
                        "
                      >
                        <p class="doc-sub">
                          Created by: {{ getSharedUser(scope.row) }}
                        </p>
                      </span>
                      <span
                        v-else-if="
                          scope.row.shared_users &&
                          scope.row.shared_users.indexOf(
                            getAuthenticatedUser.email
                          ) != -1
                        "
                      >
                        <p class="doc-sub">
                          Shared by: {{ getSharedUser(scope.row) }}
                        </p>
                      </span>
                      <!-- <span v-else>
                        <p class="doc-sub">
                          Created by: {{ getSharedUser(scope.row) }}
                        </p>
                      </span> -->
                    </template>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Actions"
              prop="action"
              class="action"
              width="230"
            >
              <template slot-scope="scope">
                <ul class="action-buttons hover-buttons">
                  <el-tooltip
                    v-if="
                      scope.row.user_id == authid ||
                      checkPermissions('sharedUserCreateDocument') ||
                      scope.row.user_id == authid ||
                      checkPermissions('userCreateDocment')
                    "
                    content="Create Document"
                  >
                    <li>
                      <el-button
                        v-if="scope.row.status == 'ACTIVE'"
                        plain
                        type="primary"
                        size="mini"
                        class="px-2"
                        @click="handleSendDocumentToUsers(scope.row)"
                      >
                        <i class="el-icon-circle-plus-outline"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <li>
                    <el-dropdown>
                      <el-button size="mini" class="el-dropdown-link px-2">
                        Actions
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <a
                          v-if="
                            getAccess('documents', 'editTemplate', '') &&
                            (scope.row.user_id == authid ||
                              checkPermissions('editDocument')) &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                          @click="onEdit(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-edit-outline"></i> Edit
                            Template</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="sentToContacts(scope.row)"
                          v-if="
                            getAccess('documents', 'sendToContact', '') &&
                            (scope.row.user_id == authid ||
                              checkPermissions('send2Contacts')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE' &&
                            scope.row.company_id &&
                            getActiveWorkspace &&
                            getActiveWorkspace.company_id &&
                            scope.row.company_id ==
                              getActiveWorkspace.company_id
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-s-promotion"></i> Send To
                            Contacts</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="bulkSend(scope.row)"
                          v-if="
                            getAccess('documents', 'bulkSend', '') &&
                            (scope.row.user_id == authid ||
                              checkPermissions('bulkSend')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-files"></i> Bulk
                            Send</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="getLinkForTemplate(scope.row)"
                          v-if="
                            getAccess('documents', 'getLink', '') &&
                            (scope.row.user_id == authid ||
                              checkPermissions('getLink')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-link"></i> Get
                            Link</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="printCheque(scope.row)"
                          v-if="getAccess('documents', 'printCheque', '')"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-printer"></i>Print
                            Cheque</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="shareTemplate(scope.row)"
                          v-if="
                            getAccess('documents', 'shareWithUsers', '') &&
                            (scope.row.user_id == authid ||
                              checkPermissions('shareWithUsers')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-share"></i> Share With
                            Users</el-dropdown-item
                          >
                        </a>

                        <a
                          v-if="
                            scope.row.status == 'ACTIVE' &&
                            getAccess('documents', 'settings', '') &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                          @click="visibleSettingsVisible(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-s-tools"></i>Settings
                          </el-dropdown-item>
                        </a>
                        <a
                          v-if="
                            getAccess('documents', 'changeOwner', '') &&
                            ((getUserType &&
                              getUserType.name &&
                              getUserType.name == 'OWNER') ||
                              scope.row.user_id == authid) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE' &&
                            scope.row.shared_users &&
                            scope.row.shared_users.indexOf(
                              getAuthenticatedUser.email
                            ) == -1
                          "
                          @click="changeTemplateUser(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-user"></i>Change Owner Of
                            Template
                          </el-dropdown-item>
                        </a>
                        <a
                          @click="modalTemplateDataView(scope.row)"
                          v-if="
                            getAccess('documents', 'viewData', '') &&
                            (scope.row.user_id == authid ||
                              checkPermissions('viewData')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE'
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-view"></i>View
                            Data</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="
                            getAccess('documents', 'duplicateTemplate', '') &&
                            (scope.row.user_id == authid ||
                              checkPermissions('duplicateTemplate')) &&
                            scope.row.status == 'ACTIVE'
                          "
                          @click="
                            openNameModal(
                              scope.row._id,
                              scope.row.title,
                              'duplicate',
                              scope.row.user_id,
                              scope.row.configurable_document_id,
                              scope.row.company_id
                            )
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-copy-document"></i>Duplicate
                            Template</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="
                            (scope.row.status == 'ACTIVE' ||
                              scope.row.status == 'INACTIVE') &&
                            scope.row.user_id != authid &&
                            scope.row.shared_users &&
                            scope.row.shared_users.indexOf(
                              getAuthenticatedUser.email
                            ) != -1
                          "
                          @click="removeSharedUser(scope.row)"
                        >
                          <el-tooltip
                            content="You can remove shared template"
                            placement="left-start"
                          >
                            <el-dropdown-item class="centered-item ml-1"
                              >&nbsp;<i class="el-icon-circle-close"></i> Remove
                              From Shared Template</el-dropdown-item
                            >
                          </el-tooltip>
                        </a>
                        <!--<a
                        @click="Templatesettings(scope.row)"
                        v-if="
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'EXPIRED' &&
                          scope.row.document_status != 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-setting"></i
                          >Settings</el-dropdown-item
                        >
                      </a> -->
                        <a
                          v-if="
                            isInDocumentUsers(scope.row.document_users) ||
                            (checkPermissionForDocument(
                              scope.row,
                              'downloadOriginal'
                            ) &&
                              scope.row &&
                              !scope.row.is_ck_editor_document) ||
                            scope.row.is_ck_editor_document === false
                          "
                          @click="originalDownload(scope.row)"
                        >
                          <el-tooltip
                            content="You can download the original document uploaded by 'Document Admin'"
                            placement="left-end"
                          >
                            <el-dropdown-item class="centered-item ml-1">
                              &nbsp;
                              <i class="el-icon-download"></i> Download
                              Original</el-dropdown-item
                            >
                          </el-tooltip>
                        </a>
                        <a
                          v-if="
                            getAccess('documents', 'renameTemplate', '') &&
                            (scope.row.user_id == authid ||
                              checkPermissions('renameRename')) &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                          @click="
                            openNameModal(
                              scope.row._id,
                              scope.row.title,
                              'rename',
                              scope.row.user_id,
                              scope.row.configurable_document_id,
                              scope.row.company_id
                            )
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-edit-outline"></i>
                            Rename Template</el-dropdown-item
                          >
                        </a>
                        <!-- <el-dropdown-item @click="onDownload(scope.row)"
                        v-if="scope.row.status == 'ACTIVE'"
                          >Download</el-dropdown-item
                        > -->
                        <a
                          @click="makeInactive(scope.row)"
                          v-if="
                            getAccess('documents', 'inActiveTemplate', '') &&
                            (scope.row.user_id == authid ||
                              checkPermissions('inactiveInactive')) &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                        >
                          <el-dropdown-item>
                            <i
                              v-if="scope.row.status == 'ACTIVE'"
                              class="el-icon-document-delete"
                            ></i>
                            <i v-else class="el-icon-document-checked"></i>
                            {{
                              scope.row.status == "ACTIVE"
                                ? "Inactive"
                                : "Active"
                            }}</el-dropdown-item
                          >
                        </a>
                        <template>
                          <a
                            @click="onDeleteConfirmation(scope.row)"
                            v-if="
                              getAccess('documents', 'deleteTemplate', '') &&
                              (scope.row.user_id == authid ||
                                checkPermissions('deleteDelete')) &&
                              scope.row.status != 'ARCHIVED' &&
                              (scope.row.user_id == authid ||
                                (scope.row.company_id &&
                                  getActiveWorkspace.company_id &&
                                  scope.row.company_id ==
                                    getActiveWorkspace.company_id))
                            "
                          >
                            <el-dropdown-item>
                              <i class="el-icon-delete"></i>
                              Delete Template</el-dropdown-item
                            >
                          </a>
                        </template>
                        <template
                          v-if="
                            scope.row.user_id == authid ||
                            (getUserType &&
                              getActiveWorkspace.company_id &&
                              (getUserType.name == 'ADMIN' ||
                                getUserType.name == 'OWNER'))
                          "
                        >
                          <a
                            @click="onPermanentDeleteConfirmation(scope.row)"
                            v-if="
                              getAccess('documents', 'deleteTemplate', '') &&
                              scope.row.status == 'ARCHIVED' &&
                              (scope.row.user_id == authid ||
                                (scope.row.company_id &&
                                  getActiveWorkspace.company_id &&
                                  scope.row.company_id ==
                                    getActiveWorkspace.company_id))
                            "
                          >
                            <el-dropdown-item>
                              <i class="el-icon-delete"></i>Permanent
                              Delete</el-dropdown-item
                            >
                          </a>
                        </template>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>
            <el-table-column label="Status" id="category" width="160">
              <template slot-scope="scope" id="category">
                <div class="d-flex activestatus">
                  <div
                    :class="scope.row.status.toLowerCase() + ' circle'"
                  ></div>
                  <div>
                    <p v-if="scope.row.is_published">Published</p>
                    <p v-else>Unpublished</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Created at"
              width="300"
              prop="created_at"
              sortable="custom"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.createdAt">
                  <span class="d-inline-block pr-2 text-muted">
                    {{ scope.row.createdAt | globalDateTimeFormat }}
                  </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              label="Last Modified"
              width="300"
              prop="updated_at"
              sortable="custom"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.updated_at">
                  <span
                    class="fs-8 d-inline-block pr-2 text-muted activestatus"
                  >
                    {{ scope.row.updated_at | globalDateTimeFormat }}
                  </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="forms"
              label="Documents Created"
              width="180"
              fixed="right"
            >
              <template slot-scope="scope">
                <template>
                  <el-tag
                    size="mini"
                    class="mr-1"
                    @click="
                      gotoLinkedDocuments(
                        scope.row.configurable_document_id,
                        'LINKED',
                        scope.row.title
                      )
                    "
                  >
                    <el-tooltip
                      placeholder="top-start"
                      content="Form responses"
                    >
                      <span class="fs-8">
                        {{ getLikendCount(scope.row.configurable_document_id) }}
                      </span>
                    </el-tooltip>
                  </el-tag>
                  <el-tag
                    type="warning"
                    size="mini"
                    class="mr-1"
                    @click="
                      gotoLinkedDocuments(
                        scope.row.configurable_document_id,
                        'PROGRESS',
                        scope.row.title
                      )
                    "
                  >
                    <el-tooltip placeholder="top-start" content="In Progress">
                      <span class="fs-8">
                        {{
                          getProgressCount(scope.row.configurable_document_id)
                        }}
                      </span>
                    </el-tooltip>
                  </el-tag>
                  <el-tag
                    type="success"
                    size="mini"
                    @click="
                      gotoLinkedDocuments(
                        scope.row.configurable_document_id,
                        'COMPLETED',
                        scope.row.title
                      )
                    "
                  >
                    <el-tooltip placeholder="top-start" content="Completed">
                      <span class="fs-8">
                        {{
                          getCompletedCount(scope.row.configurable_document_id)
                        }}
                      </span>
                    </el-tooltip>
                  </el-tag>
                </template>
              </template>
            </el-table-column>
          </data-tables-server>
          <!-- </vue-horizontal> -->
          <data-tables-server
            :fullscreen="true"
            :data="templates"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            style="width: 100%"
            v-if="getIsMobile"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Status:</div>
                  <div class="expand-column-item-content">
                    <div class="d-flex activestatus">
                      <div
                        :class="scope.row.status.toLowerCase() + ' circle'"
                      ></div>
                      <div>
                        <p v-if="scope.row.is_published">Published</p>
                        <p v-else>Unpublished</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Documents Created</div>
                  <div class="expand-column-item-content">
                    <el-tag
                      size="mini"
                      class="mr-1"
                      @click="
                        gotoLinkedDocuments(
                          scope.row.configurable_document_id,
                          'LINKED',
                          scope.row.title
                        )
                      "
                    >
                      <el-tooltip
                        placeholder="top-start"
                        content="Form responses"
                      >
                        <span class="fs-8">
                          {{
                            getLikendCount(scope.row.configurable_document_id)
                          }}
                        </span>
                      </el-tooltip>
                    </el-tag>
                    <el-tag
                      type="warning"
                      size="mini"
                      class="mr-1"
                      @click="
                        gotoLinkedDocuments(
                          scope.row.configurable_document_id,
                          'PROGRESS',
                          scope.row.title
                        )
                      "
                    >
                      <el-tooltip placeholder="top-start" content="In Progress">
                        <span class="fs-8">
                          {{
                            getProgressCount(scope.row.configurable_document_id)
                          }}
                        </span>
                      </el-tooltip>
                    </el-tag>
                    <el-tag
                      type="success"
                      size="mini"
                      @click="
                        gotoLinkedDocuments(
                          scope.row.configurable_document_id,
                          'COMPLETED',
                          scope.row.title
                        )
                      "
                    >
                      <el-tooltip placeholder="top-start" content="Completed">
                        <span class="fs-8">
                          {{
                            getCompletedCount(
                              scope.row.configurable_document_id
                            )
                          }}
                        </span>
                      </el-tooltip>
                    </el-tag>
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Last Modified:</div>
                  <div class="expand-column-item-content">
                    <template v-if="scope.row.updated_at">
                      <span class="fs-8 d-inline-block pr-2 text-muted">
                        {{ scope.row.updated_at | globalDateTimeFormat }}
                      </span>
                    </template>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Name" id="name" min-width="130" fixed>
              <template slot-scope="scope">
                <div class="icon-block">
                  <div class="icon">
                    <div class="image-file" v-if="isImage(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/image-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                    <div class="pdf-file" v-if="isPdf(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/pdf-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                    <div
                      class="doc-file"
                      v-if="
                        !isImage(scope.row.file_type) &&
                        !isPdf(scope.row.file_type)
                      "
                    >
                      <img
                        src="@/assets/img/icons/doc-file.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="icon-text">
                    <template v-if="scope.row.title"
                      >{{ scope.row.title }}

                      <span
                        v-if="
                          scope.row.company_id &&
                          getActiveWorkspace.company_id &&
                          scope.row.user_id != authid &&
                          scope.row.company_id == getActiveWorkspace.company_id
                        "
                      >
                        <p class="doc-sub-mobile">
                          Shared by:<br />
                          {{ getSharedUser(scope.row) }}
                        </p>
                      </span>
                      <span
                        v-else-if="
                          scope.row.shared_users &&
                          scope.row.shared_users.indexOf(
                            getAuthenticatedUser.email
                          ) != -1
                        "
                      >
                        <p class="doc-sub-mobile">
                          Shared by: {{ getSharedUser(scope.row) }}
                        </p>
                      </span>

                      <span
                        v-else-if="scope.row.user_id == authid"
                        class="mt-3"
                      >
                        <p class="doc-sub-mobile">
                          Created by:<br />
                          {{ getSharedUser(scope.row) }}
                        </p>
                      </span>
                    </template>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="action" class="action" width="100" fixed>
              <template slot-scope="scope">
                <ul class="action-buttons hover-buttons">
                  <li style="margin-right: 5px">
                    <el-button
                      v-if="scope.row.status == 'ACTIVE'"
                      plain
                      type="primary"
                      size="mini"
                      class="px-2"
                      @click="handleSendDocumentToUsers(scope.row)"
                    >
                      <!-- @click="onEdit(scope.row)" -->
                      <!-- <i class="el-icon-view"></i> -->
                      <i class="el-icon-circle-plus-outline"></i>
                    </el-button>
                    <el-dropdown>
                      <el-button
                        size="mini"
                        class="el-dropdown-link"
                        style="padding: 2px"
                      >
                        Actions
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <a
                          v-if="
                            (scope.row.user_id == authid ||
                              checkPermissions('editDocument')) &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                          @click="onEdit(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-edit-outline"></i> Edit
                            Template</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="sentToContacts(scope.row)"
                          v-if="
                            (scope.row.user_id == authid ||
                              checkPermissions('send2Contacts')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE' &&
                            scope.row.company_id &&
                            getActiveWorkspace &&
                            getActiveWorkspace.company_id &&
                            scope.row.company_id ==
                              getActiveWorkspace.company_id
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-s-promotion"></i> Send To
                            Contacts</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="bulkSend(scope.row)"
                          v-if="
                            (scope.row.user_id == authid ||
                              checkPermissions('bulkSend')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-files"></i> Bulk
                            Send</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="getLinkForTemplate(scope.row)"
                          v-if="
                            (scope.row.user_id == authid ||
                              checkPermissions('getLink')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-link"></i> Get
                            Link</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="shareTemplate(scope.row)"
                          v-if="
                            (scope.row.user_id == authid ||
                              checkPermissions('shareWithUsers')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-share"></i> Share With
                            Users</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="modalTemplateDataView(scope.row)"
                          v-if="
                            (scope.row.user_id == authid ||
                              checkPermissions('viewData')) &&
                            scope.row.status != 'ARCHIVED' &&
                            scope.row.status == 'ACTIVE'
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-view"></i>View
                            Data</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="
                            (scope.row.user_id == authid ||
                              checkPermissions('duplicateTemplate')) &&
                            scope.row.status == 'ACTIVE'
                          "
                          @click="
                            openNameModal(
                              scope.row._id,
                              scope.row.title,
                              'duplicate',
                              scope.row.user_id,
                              scope.row.configurable_document_id,
                              scope.row.company_id
                            )
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-copy-document"></i>Duplicate
                            Template</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="
                            (scope.row.status == 'ACTIVE' ||
                              scope.row.status == 'INACTIVE') &&
                            scope.row.user_id != authid &&
                            scope.row.shared_users &&
                            scope.row.shared_users.indexOf(
                              getAuthenticatedUser.email
                            ) != -1
                          "
                          @click="removeSharedUser(scope.row)"
                        >
                          <el-tooltip
                            content="You can remove shared template"
                            placement="left-start"
                          >
                            <el-dropdown-item
                              ><i class="el-icon-circle-close"></i> Remove From
                              Shared Template</el-dropdown-item
                            >
                          </el-tooltip>
                        </a>
                        <!--<a
                        @click="Templatesettings(scope.row)"
                        v-if="
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'EXPIRED' &&
                          scope.row.document_status != 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-setting"></i
                          >Settings</el-dropdown-item
                        >
                      </a> -->
                        <a
                          v-if="
                            (scope.row.user_id == authid ||
                              checkPermissions('renameRename')) &&
                            scope.row.status == 'ACTIVE' &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                          @click="
                            openNameModal(
                              scope.row._id,
                              scope.row.title,
                              'rename',
                              scope.row.user_id,
                              scope.row.configurable_document_id,
                              scope.row.company_id
                            )
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-edit-outline"></i>
                            Rename Template</el-dropdown-item
                          >
                        </a>
                        <!-- <el-dropdown-item @click="onDownload(scope.row)"
                        v-if="scope.row.status == 'ACTIVE'"
                          >Download</el-dropdown-item
                        > -->
                        <a
                          @click="makeInactive(scope.row)"
                          v-if="
                            (scope.row.user_id == authid ||
                              checkPermissions('inactiveInactive')) &&
                            (scope.row.user_id == authid ||
                              (scope.row.company_id &&
                                getActiveWorkspace.company_id &&
                                scope.row.company_id ==
                                  getActiveWorkspace.company_id))
                          "
                        >
                          <el-dropdown-item>
                            <i
                              v-if="scope.row.status == 'ACTIVE'"
                              class="el-icon-document-delete"
                            ></i>
                            <i v-else class="el-icon-document-checked"></i>
                            {{
                              scope.row.status == "ACTIVE"
                                ? "Inactive"
                                : "Active"
                            }}</el-dropdown-item
                          >
                        </a>
                        <template>
                          <a
                            @click="onDeleteConfirmation(scope.row)"
                            v-if="
                              (scope.row.user_id == authid ||
                                checkPermissions('deleteDelete')) &&
                              scope.row.status != 'ARCHIVED' &&
                              (scope.row.user_id == authid ||
                                (scope.row.company_id &&
                                  getActiveWorkspace.company_id &&
                                  scope.row.company_id ==
                                    getActiveWorkspace.company_id))
                            "
                          >
                            <el-dropdown-item>
                              <i class="el-icon-delete"></i>
                              Delete Template</el-dropdown-item
                            >
                          </a>
                        </template>
                        <template
                          v-if="
                            scope.row.user_id == authid ||
                            (getUserType &&
                              getActiveWorkspace.company_id &&
                              (getUserType.name == 'ADMIN' ||
                                getUserType.name == 'OWNER'))
                          "
                        >
                          <a
                            @click="onPermanentDeleteConfirmation(scope.row)"
                            v-if="
                              scope.row.status == 'ARCHIVED' &&
                              (scope.row.user_id == authid ||
                                (scope.row.company_id &&
                                  getActiveWorkspace.company_id &&
                                  scope.row.company_id ==
                                    getActiveWorkspace.company_id))
                            "
                          >
                            <el-dropdown-item>
                              <i class="el-icon-delete"></i>Permanent
                              Delete</el-dropdown-item
                            >
                          </a>
                        </template>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </data-tables-server>
        </div>
      </el-col>
    </el-row>
    <dialog-component
      class="DialogueStyle"
      title="Warning"
      :visible.sync="centerDialogVisible"
      width="25%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span v-if="this.selected_Template.length == 1"
        >Are you sure you want to delete 1 selected template?</span
      >
      <span
        v-if="
          this.selected_Template.length == 0 ||
          this.selected_Template.length > 1
        "
      >
        Are you sure you want to delete
        {{ this.selected_Template.length }} selected templates?</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="selectedTemplateDelete()"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
    <el-dialog
      class="DialogueStyle"
      title="Confirmation"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="centerDialogVisiblepermanentdelete"
      width="27%"
      center
    >
      <span v-if="this.selected_Template.length == 1"
        >Are you sure you want to permanently delete 1 selected template?</span
      >
      <span
        v-if="
          this.selected_Template.length == 0 ||
          this.selected_Template.length > 1
        "
        >Are you sure you want to permanently delete "{{
          this.selected_Template.length
        }}" selected templates?</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisiblepermanentdelete = false"
          >Cancel</el-button
        >
        <el-button type="primary" @click="selectedTemplatePermanentDelete()"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <dialog-component
      title="Change Owner"
      :visible.sync="changeTemplatesOwner"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="changeTemplateOwner"
      v-draggable
    >
      <div>
        <el-input
          type="text"
          v-model="searchCompanyUserEmail"
          placeholder="Search by Email"
          class="mb-1"
          @input="searchCompanyUsers"
          clearable
          ><i slot="prefix" class="el-input__icon el-icon-search"></i
        ></el-input>
        <p style="font-weight: 600">{{ templateTitleOfChangeOwner }}</p>
        <el-divider></el-divider>
        <div
          :class="
            companyUsers && companyUsers.length && companyUsers.length > 7
              ? 'sidemenu'
              : ''
          "
          v-if="companyUsers && companyUsers.length > 1"
        >
          <el-radio-group v-model="selectedComapnyUsers">
            <div
              v-for="(userName, index) in companyUsers.filter(
                (e) => e.email != this.createdByEmailCheck.email
              )"
              :key="index"
            >
              <p>_____________________________________</p>
              <el-radio class="mt-1 ml-1" :label="userName.email"> </el-radio>
            </div>
          </el-radio-group>
        </div>
        <div v-else-if="companyUsers && companyUsers.length == 1">
          <p>
            Please invite users to your workspace so that you can change the
            ownership of the template. Currently, there are no users in this
            workspace.
          </p>
          <router-link to="/company/users" style="color: #1989fa">
            <!-- <i class="el-icon-plus"></i> -->
            <i class="el-icon-s-custom"></i>
            Go to company users</router-link
          >
        </div>
        <el-divider></el-divider>
      </div>
      <span slot="footer">
        <el-button @click="changeTemplateOwner()">Cancel</el-button>
        <el-button
          v-if="companyUsers && companyUsers.length"
          type="primary"
          @click="changeOwnerOfATemplate()"
          >Change</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :title="modalData.action + ' Template'"
      :visible.sync="documentActionModal"
      :containerWidth="getIsMobile ? '100%' : '50%'"
      class="doc-modal"
      v-loading="modalLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-draggable
    >
      <span>
        <label>Title :</label>
        <el-input
          type="text"
          placeholder="Title"
          v-model="modalData.name"
        ></el-input>
        <p class="error" v-if="getError">
          {{ getError }}
        </p>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="documentActionModal = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="handleDocAction"
          :disabled="!modalData.name.trim()"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      title="Share"
      :visible.sync="templateShareModal"
      :width="getIsMobile ? '100%' : '50%'"
      class="doc-modal"
      v-loading="modalLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="resetShareWithUsers"
      v-draggable
    >
      <div>
        <p>You can share this template with any of your contacts.</p>
        <el-input
          type="text"
          v-model="searchedContact"
          :placeholder="
            activeName == 'contacts'
              ? 'Search by Email'
              : 'Search by Contact Type'
          "
          class="mb-1"
          @input="searchContact($event)"
          clearable
          ><i slot="prefix" class="el-input__icon el-icon-search"></i
        ></el-input>
        <el-tabs v-model="activeName">
          <el-tab-pane label="Contacts" name="contacts">
            <div
              v-if="
                contactUsers && contactUsers.length && !searchedContact.length
              "
            >
              <div style="border-bottom: 1px solid #eaeaea; height: 45px">
                <el-checkbox
                  :label="selectAllContacts ? 'Deselect All' : 'Select All'"
                  class="mt-1"
                  @change="selectAllEmails"
                  v-model="selectAllContacts"
                ></el-checkbox>
              </div>
              <el-scrollbar wrap-style="max-height: 150px;">
                <el-checkbox-group v-model="selectedUsers">
                  <div
                    v-for="(el, i) in contactUsers"
                    :key="i"
                    style="border-bottom: 1px solid #eaeaea; height: 45px"
                  >
                    <el-checkbox :label="el.email" class="mt-1"></el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-scrollbar>
            </div>
            <div v-else-if="searchedContact.length">
              <div style="border-bottom: 1px solid #eaeaea; height: 45px">
                <el-checkbox
                  :label="selectAllContacts ? 'Deselect All' : 'Select All'"
                  class="mt-1"
                  @change="selectAllEmails"
                  v-model="selectAllContacts"
                ></el-checkbox>
              </div>
              <el-scrollbar wrap-style="max-height: 150px;">
                <el-checkbox-group
                  v-model="selectedUsers"
                  v-if="searchedContacts.length"
                >
                  <div
                    v-for="(el, i) in searchedContacts"
                    :key="i"
                    style="border-bottom: 1px solid #eaeaea; height: 50px"
                  >
                    <el-checkbox :label="el.email" class="mt-1"></el-checkbox>
                  </div>
                </el-checkbox-group>
                <p v-else>No Result</p>
              </el-scrollbar>
            </div>
            <div v-else style="text-align: center; height: 100px">
              No contacts<br />
              <el-button
                class="mt-1"
                type="primary"
                size="mini"
                @click="goToAddContacts"
                >Add Contacts</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="Contact types" name="contact_types">
            <div
              v-if="
                contactTypes && contactTypes.length && !searchedContact.length
              "
            >
              <div style="border-bottom: 1px solid #eaeaea; height: 45px">
                <el-checkbox
                  :label="selectAllContactTypes ? 'Deselect All' : 'Select All'"
                  class="mt-1"
                  @change="selectAllContactsTypes"
                  v-model="selectAllContactTypes"
                ></el-checkbox>
              </div>
              <el-scrollbar wrap-style="max-height: 150px;">
                <el-checkbox-group
                  v-model="selectedContactTypes"
                  @change="selectContactTypeContacts"
                >
                  <div
                    v-for="(el, i) in contactTypes"
                    :key="i"
                    style="border-bottom: 1px solid #eaeaea; height: 50px"
                  >
                    <el-checkbox :label="el.name" class="mt-1"> </el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-scrollbar>
            </div>
            <div v-if="searchedContact.length">
              <div style="border-bottom: 1px solid #eaeaea; height: 45px">
                <el-checkbox
                  :label="selectAllContactTypes ? 'Deselect All' : 'Select All'"
                  class="mt-1"
                  @change="selectAllContactsTypes"
                  v-model="selectAllContactTypes"
                ></el-checkbox>
              </div>
              <el-scrollbar wrap-style="max-height: 150px;">
                <el-checkbox-group
                  v-model="selectedContactTypes"
                  @change="selectContactTypeContacts"
                >
                  <div
                    v-for="(el, i) in searchedContactTypes"
                    :key="i"
                    style="border-bottom: 1px solid #eaeaea; height: 50px"
                  >
                    <el-checkbox :label="el.name" class="mt-1"> </el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-scrollbar>
            </div>
          </el-tab-pane>
        </el-tabs>
        <span style="margin-right: 20%; color: #000000"
          >{{ this.selectedUsers.length }} Contact(s) Selected</span
        >
      </div>
      <span slot="footer">
        <el-button @click="resetShareWithUsers">Cancel</el-button>
        <el-button type="primary" @click="shareTemplateToContacts"
          >Share</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      title="Bulk Import Recipients"
      :visible.sync="bulkSendActionModal"
      :width="getIsMobile ? '100%' : '60%'"
      class="doc-modal"
      :before-close="resetBulkModal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-draggable
    >
      <div class="sidemenu">
        <el-steps
          :space="300"
          :active="activeStep"
          finish-status="success"
          align-center
          v-if="getBulkSendPercentage != 100 && !isContactsCreated"
          class="bulk-step"
        >
          <el-step title="Create CSV" @click.native="gotoCSV"> </el-step>
          <el-step title="Upload your CSV"> </el-step>
          <el-step title="Review & Send"> </el-step>
        </el-steps>
        <el-divider></el-divider>
        <div v-if="activeStep == 0">
          <p
            v-if="selectedTemplate && selectedTemplate.title"
            style="color: #999999; font-size: 12px"
          >
            Template: {{ selectedTemplate.title }}
          </p>
          <div style="display: flex">
            <p>
              We’ve generated a CSV format for the Template you selected.
              Download it and fill with your data.
            </p>
            <el-button
              size="mini"
              @click="generateCSVFile"
              class="download-csv ml-3"
              >Download Blank CSV</el-button
            >
          </div>
          <div>
            <el-collapse v-model="activeHeadingNames">
              <el-collapse-item
                :title="el.name + ' Variables'"
                :name="index"
                v-for="(el, index) of getVariablesOfusers"
                :key="index"
              >
                <div>
                  <div
                    class="mt-1"
                    v-for="x in el.fields"
                    :key="x"
                    style="display: flex"
                  >
                    {{ x }}
                    <div
                      v-if="
                        el.name != 'SENDER' &&
                        (x.split('::')[1] == 'first_name' ||
                          x.split('::')[1] == 'last_name')
                      "
                      class="ml-1"
                      style="color: red; font-size: 12px; display: flex"
                    >
                      <span>Required</span>
                      <p style="color: #a0a0a9" class="ml-1">
                        (If contact is not there)
                      </p>
                    </div>
                    <span
                      v-else-if="
                        el.name != 'SENDER' && x.split('::')[1] == 'email'
                      "
                      class="ml-1"
                      style="color: red; font-size: 12px"
                      >Required</span
                    >
                    <span
                      class="ml-1"
                      style="color: #8370d6; font-size: 12px"
                      v-else-if="
                        x.split('::').pop().split('_')[0] == 'checkbox'
                      "
                      >Please fill with values: true or false</span
                    >
                    <span
                      class="ml-1"
                      style="color: #8370d6; font-size: 12px"
                      v-else-if="x.split('::').pop().split('_')[0] == 'yes'"
                      >Please fill with values: yes or no</span
                    >
                    <span
                      class="ml-1"
                      style="color: #8370d6; font-size: 12px"
                      v-else-if="x.split('::').pop().split('_')[0] == 'select'"
                      >Please fill with one of the value in options</span
                    >
                    <span
                      class="ml-1"
                      style="color: #8370d6; font-size: 12px"
                      v-else-if="x.split('::').pop().split('_')[0] == 'multi'"
                      >Please fill with values in options separated by #</span
                    >
                    <span
                      class="ml-1"
                      style="color: #8370d6; font-size: 12px"
                      v-else-if="x.split('::').pop().split('_')[0] == 'date'"
                      >Please fill date in the form of 'MM-dd-yyyy'</span
                    >
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div v-if="activeStep == 1" style="text-align: center">
          <div v-if="!uploadedCSVDataResult.length">
            <el-upload
              class="upload-csv-file"
              drag
              name="logo"
              :on-change="uploadBulkSendCSV"
              action
              :show-file-list="false"
              :auto-upload="false"
              accept=".csv"
            >
              <div class="upload-file">
                <div class="mt-2">
                  <img
                    src="@/assets/img/icons/upload-file.svg"
                    alt="Upload File"
                    height="30"
                  />
                </div>
                <p>Drag &amp; Drop CSV file here</p>
                <el-button
                  size="mini"
                  style="
                    background-color: transparent;
                    border-color: #f754a2;
                    color: #f754a2;
                    border-radius: 0;
                  "
                  >Import Bulk List</el-button
                >
              </div>
            </el-upload>
          </div>
          <div v-else>
            <el-tabs v-model="activeNameForBulkSend">
              <el-tab-pane
                :label="`All Recipients ${successfullCSVData.length}`"
                name="success"
              >
                <el-scrollbar wrap-style="">
                  <div class="claim_company">
                    <el-table
                      :data="getCurrentPageOfSuccessfullCSVData"
                      style="width: 100%"
                      @cell-mouse-enter="editSuccessCSVData"
                      @cell-mouse-leave="resetSuccessCSVData"
                      row-key="id"
                      class="bulk-send-table"
                    >
                      <el-table-column
                        v-for="(row, index) in primaryHeaders"
                        :key="index"
                        :label="row"
                        width="220"
                      >
                        <template slot-scope="scope">
                          <div
                            v-if="
                              currentActiveSuccessRowIndex == scope.row['id']
                            "
                          >
                            <el-input
                              size="mini"
                              v-model="scope.row[row]"
                            ></el-input>
                          </div>
                          <div v-else>{{ scope.row[row] }}</div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-scrollbar>
                <!-- @size-change="handleSizeChangeForBS"
                  @current-change="handleCurrentChangeForBS" -->
                <el-pagination
                  :current-page.sync="currentPageForBS"
                  :page-sizes="paginationPropsForBS"
                  :page-size="pageSizeForBS"
                  layout="prev, pager, next, jumper, total"
                  :total="totalForBS"
                ></el-pagination>
              </el-tab-pane>
              <el-tab-pane
                :label="`Errors ${errorfullCSVData.length}`"
                name="error"
                class="error-tab"
              >
                <el-scrollbar wrap-style="">
                  <div class="claim_company">
                    <el-table
                      :data="getCurrentPageOfErrorfullCSVData"
                      style="width: 100%"
                      @cell-mouse-enter="editErrorCSVData"
                      @cell-mouse-leave="resetErrorCSVData"
                      row-key="id"
                      class="bulk-send-table"
                    >
                      <el-table-column
                        v-for="(row, index) in primaryHeaders"
                        :key="index"
                        :label="row"
                        width="220"
                      >
                        <template slot-scope="scope">
                          <div v-if="currentActiveRowIndex == scope.row['id']">
                            <el-input
                              size="mini"
                              v-model="scope.row[row]"
                            ></el-input>
                          </div>
                          <div v-else>
                            <p
                              v-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                row.split('::').pop().split('_')[0] ==
                                  'phone' &&
                                !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                                  scope.row[row]
                                ) &&
                                scope.row[row].length >= 3
                              "
                              style="color: red"
                            >
                              Invalid phone
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                row.split('::').pop().split('_')[0] == 'company'
                              "
                            >
                              {{ scope.row[row] }}
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                row.split('::').pop().split('_')[0] == 'address'
                              "
                            >
                              {{ scope.row[row] }}
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] &&
                                scope.row[row].length &&
                                (row.split('::')[1] == 'first_name' ||
                                  row.split('::')[1] == 'email') &&
                                scope.row[row].length < 1
                              "
                              style="color: red"
                            >
                              Requires At least 3 letters
                            </p>
                            <p
                              v-else-if="
                                scope.row[row] && scope.row[row].length
                              "
                            >
                              {{ scope.row[row] }}
                            </p>
                            <p v-else style="color: red">Missing Value</p>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-scrollbar>
                <!-- @size-change="handleSizeChangeForBSError"
                  @current-change="handleCurrentChangeForBSError" -->
                <el-pagination
                  :current-page.sync="currentPageForBSError"
                  :page-sizes="paginationPropsForBS"
                  :page-size="pageSizeForBSError"
                  layout="prev, pager, next, jumper, total"
                  :total="totalForBSError"
                ></el-pagination>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div
          v-if="
            activeStep == 2 &&
            !isContactsCreated &&
            !documentSettingsVisibleCheckForBulkSendAndTOSendToContacts
          "
          v-loading="contactLoading"
          element-loading-text="Processing..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <span>From</span><br />
          <el-input
            disabled
            v-model="getAuthenticatedUser.email"
            :style="getIsMobile ? 'width: 100%' : 'width:50%'"
            class="mt-1 mb-1"
          ></el-input
          ><br />
          <span class="mt-1">To</span><br />
          <el-input
            disabled
            placeholder="multiple recipients"
            :style="getIsMobile ? 'width: 100%' : 'width:50%'"
            class="mt-1"
          ></el-input>
          <br />
          <!-- <el-checkbox v-model="update_contacts" class="mt-1"
            >Update contacts with recipient's entered data</el-checkbox
          > -->
          <el-divider></el-divider>

          <div
            style="
              text-align: center;
              background-color: #f5f7fa;
              border: 1px solid #dcdfe6;
              border-radius: 2px;
              width: 100%;
              height: 100px;
            "
          >
            <p
              style="margin-top: 4%"
              v-if="
                getAuthenticatedUser &&
                getAuthenticatedUser.first_name &&
                getAuthenticatedUser.last_name
              "
            >
              {{
                getAuthenticatedUser.first_name +
                " " +
                getAuthenticatedUser.last_name
              }}
              is about to send {{ successfullCSVData.length }} documents with
              custom messages from {{ uploadedCSVFile }}.
            </p>
          </div>
        </div>
        <div
          v-else-if="
            activeStep == 2 &&
            isContactsCreated &&
            !documentSettingsVisibleCheckForBulkSendAndTOSendToContacts
          "
          style="text-align: center"
        >
          <div v-if="getBulkSendPercentage < 100">
            <!-- <img
              src="@/assets/img/animation_500_ksue100n 1.gif"
              alt="Upload File"
              height="300"
            /> -->
            <el-progress
              :percentage="getBulkSendPercentage"
              :status="getBulkSendPercentage == 100 ? 'success' : null"
              :format="format"
            ></el-progress>
            <span v-if="completedPercentage >= 1">{{
              completedPercentage +
              " out of " +
              this.successfullCSVData.length +
              " documents has sent successfully"
            }}</span>
            <span v-if="completedPercentage < 1">Sending...</span>
          </div>
        </div>
        <div
          v-else-if="
            activeStep == 2 &&
            !isContactsCreated &&
            documentSettingsVisibleCheckForBulkSendAndTOSendToContacts
          "
        >
          <configureSettings
            :configurableId="
              this.selectedCofigurable && this.selectedCofigurable._id
                ? this.selectedCofigurable._id
                : ''
            "
            @updateDocSettingsData="closeAndSendDocuments"
            @closeAndCancle="
              documentSettingsVisibleCheckForBulkSendAndTOSendToContacts = false
            "
          ></configureSettings>
        </div>
        <div v-if="getBulkSendPercentage == 100" style="text-align: center">
          <img src="@/assets/img/bulksend.svg" alt="Upload File" height="50" />
          <h2>Done! Your bulk send was delivered successfully.</h2>
          <p>
            You can find your sent documents on your dashboard. We will notify
            you by sending an email notification when any action is taken on the
            document: Viewing, Signing, or Declined, etc.
          </p>
          <el-button @click="gotoDashboard" type="danger"
            >Go to Documents</el-button
          >
        </div>
      </div>
      <span slot="footer" v-if="activeStep == 0">
        <el-button @click="bulkSendActionModal = false">Cancel</el-button>
        <el-button type="primary" @click="activeStep = 1">Continue</el-button>
      </span>
      <span
        slot="footer"
        v-if="activeStep == 1 && uploadedCSVDataResult.length"
      >
        <el-button @click="resetCSVData">Re-Upload CSV</el-button>
        <el-button
          type="danger"
          :disabled="successfullCSVData && !successfullCSVData.length"
          @click="activeStep = 2"
          >{{
            errorfullCSVData && errorfullCSVData.length
              ? "Continue Anyway"
              : "Continue"
          }}</el-button
        >
      </span>
      <span
        slot="footer"
        v-if="
          activeStep == 2 &&
          !isContactsCreated &&
          !documentSettingsVisibleCheckForBulkSendAndTOSendToContacts
        "
      >
        <el-button @click="activeStep = 1">Back</el-button>
        <el-button
          type="primary"
          :loading="bulkSendBtnLoding"
          @click="checkSettingsVisibleOrNot"
          >{{ `Send documents` }}</el-button
        >
      </span>
    </dialog-component>
    <el-dialog
      :title="documentsText"
      :visible.sync="selectedDocumentsModal"
      :width="getIsMobile ? '100%' : '70%'"
      :before-close="close"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-draggable
    >
      <div>
        <el-scrollbar wrap-style="max-height: 400px;">
          <el-table :data="selectedDocuments" style="width: 100%" border>
            <el-table-column
              prop="name"
              label="Document Receivers"
              min-width="160"
            >
              <template
                slot-scope="scope"
                v-if="scope.row.user_type != 'SENDER' && !scope.row.is_cc"
              >
                <div
                  v-for="(user, index) in scope.row.document_users"
                  :key="index"
                >
                  <p v-if="user && user.first_name">
                    {{ user.first_name + " " + user.last_name }}
                    <el-tooltip
                      content="You can communicate with recipients by sending an email"
                    >
                      <a
                        style="font-family: inter"
                        v-if="!user.is_cc && user.type != 'SENDER'"
                        :href="`mailto: ${user.email}`"
                        >Send Email</a
                      >
                    </el-tooltip>
                  </p>
                </div>
              </template>
              <!---->
            </el-table-column>
            <el-table-column
              prop="document_tracking_status"
              label="Tracking status"
              min-width="150"
            >
              <template
                slot-scope="scope"
                v-if="
                  scope.row.document_tracking_status &&
                  scope.row.document_tracking_status.type
                "
              >
                <el-popover
                  placement="top-start"
                  style="font-family: inter; font-size: 10px"
                  :title="getWaitingUser(scope.row)"
                  width="100%"
                  trigger="hover"
                  v-if="scope.row.document_status == 'SENT'"
                >
                  <p
                    slot="reference"
                    style="font-family: inter; font-size: 14px"
                    class="text-secondary-sent"
                  >
                    {{ scope.row.document_tracking_status.description }}
                  </p>
                </el-popover>
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  v-else
                >
                  All signatures are completed view or download the document.
                  <p slot="reference" class="text-secondary-sent">
                    {{ scope.row.document_tracking_status.description }}
                  </p>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              label="Last Modified"
              prop="updated_at"
              min-width="120"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.updated_at">
                  <span class="d-inline-block pr-2 text-muted">
                    {{ scope.row.updated_at | globalDateTimeFormat }}
                  </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="document_status"
              label="Document Status"
              min-width="100"
            ></el-table-column>

            <el-table-column label="Actions" width="180">
              <template slot-scope="scope">
                <!-- {{getUserType.permissionSet}} -->
                <div style="display: flex">
                  <el-button
                    style="background-color: #f754a2; color: #ffffff"
                    size="mini"
                    @click="onView(scope.row._id)"
                  >
                    View
                  </el-button>
                  <el-button
                    size="mini"
                    v-if="
                      (scope.row.document_status == 'SENT' ||
                        scope.row.document_status == 'APPROVER') &&
                      !scope.row.document_filling_status &&
                      checkToOnlyResend(scope.row)
                    "
                    @click="resendDocLink(scope.row)"
                  >
                    Resend
                  </el-button>
                  <el-button
                    size="mini"
                    v-else-if="
                      checkPermissionForDocument(
                        scope.row,
                        'downloadDocument'
                      ) && scope.row.document_status == 'COMPLETED'
                    "
                    @click="onDownload(scope.row)"
                  >
                    <el-tooltip content="Download Document"
                      ><i
                        size="mini"
                        style="color: #f754a2; width: -5px"
                        class="el-icon-download"
                      ></i>
                    </el-tooltip>
                  </el-button>
                  <el-dropdown>
                    <img
                      src="@/assets/img/icons/more-icon.svg"
                      alt="icon"
                      class="el-dropdown-link"
                    />
                    <el-dropdown-menu slot="dropdown">
                      <a @click="getAllDocLogs(scope.row)">
                        <el-dropdown-item
                          ><i class="el-icon-refresh-right"></i>
                          History</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="alertCorrectedDoc(scope.row)"
                        v-if="
                          ((checkPermissionForDocument(
                            scope.row,
                            'correctDocument'
                          ) ||
                            scope.row.user_id === authid) &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.document_status != 'EXPIRED') ||
                          isfilledOrNot(scope.row)
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-checked"></i> Correct the
                          document</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="EditVoidDocument(scope.row, scope.row._id)"
                        v-if="
                          (checkPermissionForDocument(
                            scope.row,
                            'cloneDocument'
                          ) ||
                            scope.row.user_id === authid) &&
                          (scope.row.document_status == 'VOIDED' ||
                            scope.row.document_status == 'COMPLETED')
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-copy"></i>
                          Clone</el-dropdown-item
                        >
                      </a>
                      <a>
                        <!-- <el-dropdown-item>
                    <i class="el-icon-setting"></i>Settings
                  </el-dropdown-item> -->
                      </a>
                      <a
                        @click="alertvoided(scope.row)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'voidDocument'
                          ) &&
                          scope.row &&
                          scope.row.document_status &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'DECLINED' &&
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'EXPIRED' &&
                          scope.row.document_status != 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item>
                          <i class="el-icon-remove-outline"></i>Void Document
                        </el-dropdown-item>
                      </a>
                      <a
                        v-if="
                          isInDocumentUsers(scope.row.document_users) ||
                          (checkPermissionForDocument(
                            scope.row,
                            'downloadOriginal'
                          ) &&
                            scope.row &&
                            !scope.row.is_ck_editor_document) ||
                          scope.row.is_ck_editor_document === false
                        "
                        @click="originalDownload(scope.row)"
                      >
                        <el-tooltip
                          content="You can download the original document uploaded by 'Document Admin'"
                          placement="left-end"
                        >
                          <el-dropdown-item class="centered-item"
                            ><i class="el-icon-download"></i>Download
                            Original</el-dropdown-item
                          >
                        </el-tooltip>
                      </a>
                      <a
                        v-if="
                          scope.row &&
                          scope.row.document_status &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'DECLINED' &&
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'EXPIRED'
                        "
                      >
                        <el-dropdown-item disabled>
                          <i class="el-icon-timer"></i>Expires On
                          {{ expiraydate | globalDateFormat }}
                        </el-dropdown-item>
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showTemplateDataView"
      :title="documentsText"
      :fullscreen="true"
      v-draggable
    >
      <el-tooltip content="Go back to templates">
        <el-button @click="goBack" class="backBtn">
          <i class="el-icon-back"></i>
          Back
        </el-button>
      </el-tooltip>
      <templateSubmittedDataTable
        v-if="showTemplateDataView"
        :configurableDocumentId="selectedConfigurableDocumentId"
        :configurableDocumentData="selectedConfigurable"
      ></templateSubmittedDataTable>
    </el-dialog>
    <dialog-component
      :visible.sync="sentContactsModal"
      title="Select contacts to send template(s)"
      :width="getIsMobile ? '100%' : '50%'"
      :before-close="closeSentContacts"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-draggable
    >
      <el-scrollbar wrap-style="max-height: 600px;padding:13px;">
        <div
          v-if="
            !isContactsCreated &&
            !documentSettingsVisibleCheckForBulkSendAndTOSendToContacts
          "
          v-loading="sentToContactsLoading"
        >
          <el-input
            type="text"
            v-model="searchedContactForSend"
            placeholder="Search by Email"
            class="mb-1"
            @input="searchContactForSend"
            clearable
            ><i slot="prefix" class="el-input__icon el-icon-search"></i
          ></el-input>

          <div
            v-if="
              selectedContactTypeContacts && selectedContactTypeContacts.length
            "
          >
            <div style="border-bottom: 1px solid #eaeaea; height: 45px">
              <el-checkbox
                :label="
                  selectAllContactsForSend ? 'Deselect All' : 'Select All'
                "
                class="mt-1"
                @change="selectAllContactsMethod"
                v-model="selectAllContactsForSend"
              ></el-checkbox>
              <span style="margin-left: 25%; color: #000000"
                >Selected Contacts:{{ this.SelectedAndFilterdContacts.length }}
              </span>
            </div>

            <el-scrollbar wrap-style="max-height: 200px;">
              <el-checkbox-group
                v-model="SelectedAndFilterdContacts"
                v-if="!searchedContactForSend"
              >
                <div
                  v-for="el in selectedContactTypeContacts"
                  :key="el.email"
                  style="border-bottom: 1px solid #eaeaea; height: 50px"
                >
                  <el-checkbox :label="el.email" class="mt-1"></el-checkbox>
                </div>
              </el-checkbox-group>
              <el-checkbox-group v-model="SelectedAndFilterdContacts" v-else>
                <div
                  v-for="(el, i) in searchedContactsForSend"
                  :key="i"
                  style="border-bottom: 1px solid #eaeaea; height: 50px"
                >
                  <el-checkbox :label="el.email" class="mt-1"></el-checkbox>
                </div>
              </el-checkbox-group>
              <p
                v-if="searchedContactForSend && !searchedContactsForSend.length"
              >
                No results
              </p>
            </el-scrollbar>
          </div>
          <div v-else>
            No contacts found for this contact type
            <b v-if="selectedContactType && selectedContactType.name">{{
              selectedContactType.name
            }}</b>
          </div>
          <div style="margin-top: 6px">
            <el-button
              class="el-button--primary is-plain"
              @click="goToCreateContact"
            >
              <i class="el-icon-circle-plus-outline"></i>
              Create Contact
            </el-button>
            <el-row>
              <el-col :span="8" class="mr-1 mt-1">
                <label>Email Subject</label>
                <el-input
                  type="textarea"
                  v-model="mailSubject"
                  placeholder="Subject"
                  :autosize="{ minRows: 2 }"
                  maxlength="70"
                  show-word-limit
                >
                </el-input>
                <!-- <div class="text-muted fs-9 opacity-80">Characters remaining :  70</div> -->
              </el-col>
              <el-col :span="14" class="m-1 mt-1">
                <label>Email Message</label>
                <el-input
                  type="textarea"
                  v-model="mailContent"
                  placeholder="Type your message here"
                  class="border-0"
                  rows="5"
                  maxlength="10000"
                  show-word-limit
                ></el-input>
                <!-- <div class="text-muted fs-9 opacity-80">Characters remaining : 70</div> -->
              </el-col>
            </el-row>
          </div>
        </div>
        <div
          v-else-if="
            documentSettingsVisibleCheckForBulkSendAndTOSendToContacts &&
            !isContactsCreated
          "
        >
          <configureSettings
            :configurableId="
              this.selectedConfigurable && this.selectedConfigurable._id
                ? this.selectedConfigurable._id
                : ''
            "
            @updateDocSettingsData="closeAndSendDocuments"
            @closeAndCancle="
              documentSettingsVisibleCheckForBulkSendAndTOSendToContacts = false
            "
          ></configureSettings>
        </div>
        <div
          v-if="
            isContactsCreated &&
            !documentSettingsVisibleCheckForBulkSendAndTOSendToContacts
          "
          style="text-align: center"
        >
          <div v-if="getBulkSendPercentage < 100">
            <el-progress
              :percentage="getBulkSendPercentage"
              :status="getBulkSendPercentage == 100 ? 'success' : null"
              :format="format"
            ></el-progress>
            <span v-if="completedPercentage >= 1">{{
              completedPercentage +
              " out of " +
              this.SelectedAndFilterdContacts.length +
              " documents has sent successfully"
            }}</span>
            <span v-if="completedPercentage < 1">Sending...</span>
          </div>
          <div v-if="getBulkSendPercentage == 100" style="text-align: center">
            <img
              src="@/assets/img/bulksend.svg"
              alt="Upload File"
              height="50"
            />
            <h2>Done! send to contacts was delivered successfully.</h2>
            <p>
              You can find your sent documents on your dashboard. We will notify
              you by sending an email notification when any action is taken on
              the document: Viewing, Signing, or Declined, etc.
            </p>
            <el-button @click="gotoDashboard" type="danger"
              >Go to Documents</el-button
            >
          </div>
        </div>
      </el-scrollbar>
      <span
        slot="footer"
        v-if="
          !isContactsCreated &&
          !documentSettingsVisibleCheckForBulkSendAndTOSendToContacts
        "
      >
        <el-button @click="closeSentContacts">Cancel</el-button>
        <el-button
          type="primary"
          :disabled="!this.SelectedAndFilterdContacts.length"
          @click="modalToCheck()"
          >Send</el-button
        >
      </span>
    </dialog-component>
    <el-dialog
      class="DialogueStyle"
      title="Confirmation Alert"
      :visible.sync="visibleDialog"
      :width="getIsMobile ? '100%' : '32%'"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span v-if="this.SelectedAndFilterdContacts.length >= 1"
        >Are you sure to send document to
        {{ this.SelectedAndFilterdContacts.length }} selected contact(s)?</span
      >
      <span slot="footer">
        <el-divider></el-divider>
        <el-button @click="closeSendToContactsAlert()">Cancel</el-button>
        <el-button type="primary" @click="checkSettingsVisibleOrNot"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Document History"
      :destroy-on-close="true"
      :visible.sync="viewDetailedHistory"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :lock-scroll="false"
      :width="getIsMobile ? '100%' : '55%'"
      :before-close="resetHistory"
    >
      <el-scrollbar wrap-style="max-height: 600px;padding:13px;">
        <div style="height: 430px">
          <vue-html2pdf
            :show-layout="true"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            filename="hee hee"
            :pagebreak="{
              mode: 'css',
            }"
            :pdf-quality="10"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            :html-to-pdf-options="{
              html2canvas: { useCORS: true },
            }"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <section id="details-report">
                <div style="padding: 10px">
                  <h3 class="float-left fw-bold fs-22">Document Details</h3>
                  <div class="d-flex">
                    <el-row :gutter="10">
                      <el-col
                        :xl="{ span: 12 }"
                        :lg="{ span: 12 }"
                        :md="{ span: 12 }"
                        :sm="{ span: 12 }"
                        :xs="{ span: 12 }"
                      >
                        <span class="details">Document Name</span>
                        <p class="table">
                          {{
                            this.selectedHistroyDocuments.Docname
                              | truncate(20, "...")
                          }}
                        </p>
                      </el-col>
                      <el-col
                        :xl="{ span: 12 }"
                        :lg="{ span: 12 }"
                        :md="{ span: 12 }"
                        :sm="{ span: 12 }"
                        :xs="{ span: 12 }"
                      >
                        <span class="details">Document Recipients</span>
                        <div class="d-flex">
                          <div
                            v-for="(el, index) in this.selectedHistroyDocuments
                              .Doc_recipients"
                            :key="index"
                          >
                            <p class="table" v-if="el && el.type != 'SENDER'">
                              {{ el.first_name + " " + el.last_name + "," }}
                            </p>
                          </div>
                        </div>
                      </el-col>
                      <el-col
                        :xl="{ span: 12 }"
                        :lg="{ span: 12 }"
                        :md="{ span: 12 }"
                        :sm="{ span: 12 }"
                        :xs="{ span: 12 }"
                      >
                        <span class="details">Date Created</span>
                        <p class="table">
                          {{
                            this.selectedHistroyDocuments.created_at
                              | globalDateTimeFormat
                          }}
                        </p>
                      </el-col>
                      <el-col
                        :xl="{ span: 12 }"
                        :lg="{ span: 12 }"
                        :md="{ span: 12 }"
                        :sm="{ span: 12 }"
                        :xs="{ span: 12 }"
                      >
                        <span class="details">Document ID</span>
                        <p
                          v-if="
                            this.selectedHistroyDocuments.documentIdforHistory
                          "
                        >
                          {{ documentIdforHistory }}
                        </p>
                        <p v-else>-- --</p>
                      </el-col>
                      <el-col
                        :xl="{ span: 12 }"
                        :lg="{ span: 12 }"
                        :md="{ span: 12 }"
                        :sm="{ span: 12 }"
                        :xs="{ span: 12 }"
                      >
                        <span class="details">Sender</span>
                        <p class="table">
                          {{ this.selectedHistroyDocuments.senderName }}
                        </p>
                      </el-col>

                      <el-col
                        :xl="{ span: 12 }"
                        :lg="{ span: 12 }"
                        :md="{ span: 12 }"
                        :sm="{ span: 12 }"
                        :xs="{ span: 12 }"
                      >
                        <span class="details">Document Status</span>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'SENT'
                          "
                        >
                          Sent
                        </p>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'COMPLETED'
                          "
                        >
                          Completed
                        </p>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'VOIDED'
                          "
                        >
                          Voided
                        </p>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'DECLINED'
                          "
                        >
                          Declined
                        </p>
                        <p
                          class="table"
                          v-if="
                            this.selectedHistroyDocuments.Document_Status ==
                            'ARCHIVED'
                          "
                        >
                          Archived
                        </p>
                      </el-col>
                      <el-col
                        :xl="{ span: 24 }"
                        :lg="{ span: 24 }"
                        :md="{ span: 24 }"
                        :sm="{ span: 24 }"
                        :xs="{ span: 24 }"
                      >
                        <span class="details">
                          Date Format
                          <p
                            style="
                              font-family: inter;
                              font-size: 12px;
                              font-weight: normal;
                            "
                          >
                            {{ dateFormat }}
                          </p>
                        </span>
                      </el-col>
                    </el-row>
                  </div>
                  <el-divider></el-divider>
                  <h3>Activities</h3>
                </div>
                <el-table
                  style="width: 100%"
                  border
                  :data="documentLogs"
                  stripe
                >
                  <el-table-column label="Document User Name" min-width="110">
                    <template
                      slot-scope="scope"
                      v-if="scope.row.user_id || scope.row.contact_id"
                    >
                      <p v-if="scope.row.user_id" class="table">
                        {{
                          scope.row.user_id.first_name +
                          " " +
                          scope.row.user_id.last_name
                        }}
                      </p>
                      <p v-if="scope.row.contact_id" class="table">
                        {{
                          scope.row.contact_id.first_name +
                          " " +
                          scope.row.contact_id.last_name
                        }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Status" min-width="95">
                    <template
                      slot-scope="scope"
                      v-if="scope.row.user_id || scope.row.contact_id"
                    >
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_VIEWED'"
                      >
                        Viewed
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_SETTINGS_CHANGED'"
                      >
                        Settings Changed
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_SIGNED'"
                      >
                        Signed
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_SENT'"
                      >
                        Sent
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_DECLINED'"
                      >
                        Declined
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'ACCEPTED_TERMS_&_CONDITIONS'"
                      >
                        Accepted <br />Terms & Conditions
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_VOIDED'"
                      >
                        Voided
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_CORRECTED'"
                      >
                        Corrected
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'DOCUMENT_APPROVED'"
                      >
                        Approved
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'Document Deleted'"
                      >
                        Deleted
                      </p>
                      <p
                        class="table"
                        v-if="scope.row.status == 'Undo Deleted Document'"
                      >
                        Undo Document
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Document Activity" min-width="200">
                    <template slot-scope="scope">
                      <p v-if="scope.row.user_id" class="table">
                        {{ scope.row.description }}
                      </p>
                      <p v-else class="table">{{ scope.row.description }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Date & Time" min-width="110">
                    <template
                      slot-scope="scope"
                      v-if="scope.row.user_id || scope.row.contact_id"
                    >
                      <p class="table">
                        {{ scope.row.created_at | globalDateTimeFormat }}
                      </p>
                    </template>
                  </el-table-column>
                </el-table>
              </section>
            </section>
          </vue-html2pdf>
        </div>
      </el-scrollbar>
      <div
        v-if="this.checkDocumentStatusForCert == 'COMPLETED'"
        style="display: none"
      >
        <vue-html2pdf
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          filename="hee hee"
          :pagebreak="{
            mode: 'css',
          }"
          :pdf-quality="10"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          :html-to-pdf-options="{
            html2canvas: { useCORS: true },
          }"
          ref="doc2Cer"
        >
          <section slot="pdf-content">
            <section id="certificate-report">
              <div style="padding: 10px">
                <img
                  src="@/assets/img/eSigns Logo Black.svg"
                  alt="Nimble logo"
                />
                <div style="background: #f2f6fc; padding: 5px">
                  <p style="font-size: 16px" class="fw-bold fs-22">
                    Document Details
                  </p>
                </div>
                <div class="mt-1">
                  <el-row>
                    <el-col :span="12">
                      <div class="d-flex">
                        ID:
                        <p class="fw-bold ml-1">{{ documentCompletedID }}</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Signatures:
                        <p class="fw-bold ml-1">{{ getSignaturesCount }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Subject:
                        <p class="fw-bold ml-1">{{ getDocumentSubject }}</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Initials:
                        <p class="fw-bold ml-1">{{ getInitialsCount }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Document Pages:
                        <p class="fw-bold ml-1">{{ pdfPages }}</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Status:
                        <p class="fw-bold ml-1">COMPLETED</p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Certificate pages:
                        <p class="fw-bold ml-1">1</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Sender:
                        <p class="fw-bold ml-1">{{ getSenderEmail }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Time Zone:
                        <p class="fw-bold ml-1">{{ getDocumentCreatedZone }}</p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Ip address:
                        <p class="fw-bold">{{ getSenderIpAddress }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <div style="background: #f2f6fc; padding: 5px" class="mt-1">
                    <p style="font-size: 16px" class="fw-bold fs-22">
                      Document Tracking
                    </p>
                  </div>
                  <el-row class="mt-1">
                    <el-col :span="12">
                      <div class="d-flex">
                        Document Created:
                        <p class="fw-bold">
                          {{ getDocumentCreatedAt | globalDateTimeFormat }}
                        </p>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="d-flex">
                        Document Completed:
                        <p class="fw-bold">
                          {{ getDocumentCompletedAt | globalDateTimeFormat }}
                        </p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row
                    style="background: #f2f6fc; padding: 5px"
                    class="mt-1"
                  >
                    <el-col :span="8">
                      <p style="font-size: 16px" class="fw-bold fs-22">
                        Signer Details
                      </p>
                    </el-col>
                    <el-col :span="8">
                      <p style="font-size: 16px" class="fw-bold fs-22">
                        Signature
                      </p>
                    </el-col>
                    <el-col :span="8">
                      <p style="font-size: 16px" class="fw-bold fs-22">
                        Timeframe
                      </p>
                    </el-col>
                  </el-row>
                  <el-row
                    v-for="(el, i) in getUsersForCertificate"
                    :key="i"
                    style="border-bottom: 1px solid #e1e1e1; padding: 10px"
                  >
                    <el-col :span="8" style="font-size: 12px">
                      <div class="d-flex">
                        Name:
                        <p class="fw-bold fs-22 ml-1">{{ el.name }}</p>
                      </div>
                      <div class="d-flex">
                        Email:
                        <p class="fw-bold fs-22 ml-1">{{ el.email }}</p>
                      </div>
                    </el-col>
                    <el-col :span="8" style="font-size: 12px">
                      <div v-if="el.signature_source && !el.print_complete">
                        <img :src="el.signature_source" style="width: 200px" />
                      </div>
                      <div v-if="el.print_complete" style="color: #409eff">
                        <h4>Uploaded paper with hand signature</h4>
                      </div>
                      <div class="d-flex">
                        Signature Adoption:
                        <p class="fw-bold fs-22">
                          {{
                            el.print_complete
                              ? "Signed on Paper"
                              : "Drawn on Device"
                          }}
                        </p>
                      </div>
                      <div class="d-flex">
                        Using IP Address:
                        <p class="fw-bold fs-22 ml-1">{{ el.user_ip }}</p>
                      </div>
                    </el-col>
                    <el-col :span="8" style="font-size: 12px">
                      <div class="d-flex">
                        Sent:
                        <p class="fw-bold fs-22" v-if="el.sent_time">
                          {{ el.sent_time | globalDateTimeFormat }}
                        </p>
                      </div>
                      <div class="d-flex">
                        Terms & Conditions:
                        <p class="fw-bold fs-22" v-if="el.accept_time">
                          {{ el.accept_time | globalDateTimeFormat }}
                        </p>
                      </div>
                      <div class="d-flex">
                        Viewed:
                        <p class="fw-bold fs-22" v-if="el.view_time">
                          {{ el.view_time | globalDateTimeFormat }}
                        </p>
                      </div>
                      <div class="d-flex">
                        Signed:
                        <p class="fw-bold fs-22" v-if="el.completed_time">
                          {{ el.completed_time | globalDateTimeFormat }}
                        </p>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </section>
          </section>
        </vue-html2pdf>
      </div>
      <span slot="footer">
        <el-button
          :loading="detailsDownloadForCert"
          v-if="this.checkDocumentStatusForCert == 'COMPLETED'"
          @click="generateCertificate()"
          >Download Certificate</el-button
        >
        <el-button
          type="primary"
          @click="generateReport"
          :loading="detailsDownload"
          >Download History</el-button
        >
      </span>
    </el-dialog>
    <!-- <el-dialog
      title="Warning"
      :visible.sync="dialogVisibleForBulkDelete"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="28%"
    >
      <p class="warningmsg">You can only delete the documents which are created by you.</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleForBulkDelete= false">Close</el-button>
      </span>
    </el-dialog> -->
    <dialog-component
      title="Print Cheque Info"
      :visible="showAPIDataPopup"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :lock-scroll="false"
      :width="getIsMobile ? '100%' : '100%'"
      v-draggable
    >
      <el-button
        size="mini"
        @click="copyKeys"
        type="primary"
        style="float: right; margin-bottom: 10px"
        ><i class="el-icon-copy-document"></i
      ></el-button>
      <h2>Keys to pass data to API</h2>
      <div
        class=""
        v-loading="tableLoading"
        :element-loading-text="tableLoadingText"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <span>Document Template Id: {{ docTempId }}</span> <br />
        <span>Configurable Document Id: {{ configDocId }}</span>
        <el-table
          class="tableMenu"
          :data="keysAndLabelsArray"
          border
          style="width: 100%"
        >
          <el-table-column min-width="100" label="S.No">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column
            prop="label"
            min-width="150"
            label="Label"
          ></el-table-column>
          <el-table-column prop="key" min-width="390" label="Key">
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.data_table_obj &&
                  scope.row.data_table_obj.table_columns_data
                "
              >
                <p>Field Key: {{ scope.row.key }}</p>
                <div class="d-flex">
                  <el-tooltip content="Copy Table Column Names" placement="top">
                    <p>
                      Copy Names:<i
                        class="el-icon-copy-document"
                        @click="
                          copyNames1(
                            scope.row.data_table_obj.table_columns_data
                          )
                        "
                      ></i>
                    </p>
                  </el-tooltip>

                  <el-tooltip content="Copy Table Column Keys" placement="top">
                    <p class="ml-1">
                      Copy Keys:<i
                        class="el-icon-copy-document"
                        @click="
                          copyKeys1(scope.row.data_table_obj.table_columns_data)
                        "
                      ></i>
                    </p>
                  </el-tooltip>
                </div>
                <el-collapse v-model="scope.row.isCollapse">
                  <el-collapse-item title="Table Columns">
                    <el-table
                      :data="scope.row.data_table_obj.table_columns_data"
                      border
                      style="width: 100%"
                    >
                      <el-table-column
                        label="Name"
                        prop="name"
                      ></el-table-column>
                      <el-table-column label="Key" prop="key"></el-table-column>
                    </el-table>
                  </el-collapse-item>
                </el-collapse>
              </div>
              <div v-else>{{ scope.row.key }}</div>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="key" label="Key"></el-table-column>
        <el-table-column label="Table Columns Data">
        <template slot-scope="scope" v-if="scope.row.data_table_obj">
          <el-collapse v-model="scope.row.isCollapse">
            <el-collapse-item title="Table Columns">
              <el-table :data="scope.row.data_table_obj.table_columns_data" border style="width: 100%">
                <el-table-column label="Name">
                  <template slot-scope="column">{{ column.row.name }}</template>
                </el-table-column>
                <el-table-column label="Key">
                  <template slot-scope="column">{{ column.row.key }}</template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </template>
      </el-table-column> -->
        </el-table>
        <!-- <el-table class="tableMenu" :data="keysAndLabelsArray" border style="width: 100%">
          <el-table-column min-width="20" label="S.No">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="label" label="Label"></el-table-column>
          <el-table-column prop="key" label="Key"></el-table-column>
        </el-table> -->
      </div>
      <span slot="footer">
        <el-button @click="showAPIDataPopup = false"> Close </el-button>
      </span>
    </dialog-component>
    <el-dialog
      title="Edit Template"
      :destroy-on-close="true"
      :visible.sync="is_shared"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :lock-scroll="false"
      :width="getIsMobile ? '100%' : '45%'"
    >
      <p
        style="
          text-align: left;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
        "
      >
        You are not allowed to edit the template!
      </p>
      <p
        style="
          text-align: left;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
        "
      >
        If you want to edit this template you can duplicate or create document
        from template.
      </p>
      <span slot="footer">
        <el-button
          @click="
            openNameModal(
              selectedSharedTemplate._id,
              selectedSharedTemplate.title,
              'duplicate',
              selectedSharedTemplate.configurable_document_id,
              selectedSharedTemplate.company_id
            )
          "
          >Duplicate</el-button
        >
        <el-button
          v-if="
            checkPermissions('sharedUserCreateDocument') ||
            checkPermissions('userCreateDocment')
          "
          @click="handleSendDocumentToUsers(selectedSharedTemplate)"
          >Create Document</el-button
        >
      </span>
    </el-dialog>
    <ConfigureGetLinkTemplate
      v-if="getLinkModal"
      :configurableDocument="selectedConfigurable"
      v-on:close="closeGetLink"
    >
    </ConfigureGetLinkTemplate>

    <!-- <ConfigureSettings
      v-if="docSettingsVisible"
      :configurableDocumentId="configurableDocumentId"
      :docSettingsVisible="docSettingsVisible"
      @close="docSettingsVisible = false"
    ></ConfigureSettings> -->
    <ConfigureTemplateSettings
      v-if="docSettingsVisible"
      :docSettingsVisible="docSettingsVisible"
      @close="docSettingsVisible = false"
      :configurableId="this.configIdForSettings ? this.configIdForSettings : ''"
    >
    </ConfigureTemplateSettings>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "@/config/axios";
import UserDocumentsHelper from "@/mixins/UserDocumentsHelper";
import VueHtml2pdf from "vue-html2pdf";
import appConfig from "@/config/app";
import PermissionsHelper from "@/mixins/permissionsHelper";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
const ConfigureGetLinkTemplate = () =>
  import("@/components/templates/configureGetLinkTemplate.vue");
const templateSubmittedDataTable = () =>
  import("@/components/templates/templateSubmittedData.vue");
var pdfjsLib = window["pdfjs-dist/build/pdf"];
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js";
const configureSettings = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigBulkSendSettings.vue"
  );
const ConfigureTemplateSettings = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigureSettingsNew.vue"
  );
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications.js";
import NavigationHelper from "@/mixins/navigationHelper";
import AccessManagement from "@/mixins/AccessManagement";
export default {
  name: "templates-All-Templates",
  mixins: [
    UserDocumentsHelper,
    PermissionsHelper,
    NavigationHelper,
    DocumentsHelper,
    ComanyDocumentsPresignedURLsHelper,
    AccessManagement,
  ],
  components: {
    configureSettings,
    ConfigureGetLinkTemplate,
    templateSubmittedDataTable,
    ConfigureTemplateSettings,
    VueHtml2pdf,
    //VueHorizontal,
  },

  data() {
    return {
      mailSubject: "",
      mailContent: "",
      keysAndLabelsArray: [],
      docTempId: null,
      configDocId: null,
      showAPIDataPopup: false,
      isTemplateDeleting: false,
      isTemplateDelete: false,
      centerDialogVisible: false,
      centerDialogVisiblepermanentdelete: false,
      visibleDialog: false,
      selected_Template: [],
      configIdForSettings: null,
      totalForBS: 0,
      mapped_entity_fields: {},
      currentPageForBS: 1,
      showAddContact: false,
      pageSizeForBS: 5,
      totalForBSError: 0,
      currentPageForBSError: 1,
      searchedContactResult: false,
      pageSizeForBSError: 5,
      is_shared: false,
      documentLogs: [],
      loading: false,
      editDailogVisible: false,
      viewDetailedHistory: false,
      documentUsers: [],
      selectedCompanyDocument: "",
      search_string: "",
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      activeNameForBulkSend: "success",
      activeName: "contacts",
      templateShareModal: false,
      configureLoading: false,
      LoadingText: "",
      tableLoading: false,
      tableLoadingText: "",
      openSendDocumentsModal: false,
      showSendDocumentoUserSettings: false,
      enforceSignatureOrder: null,
      order_type: null,
      expiraydate: null,
      order_by: null,
      templates: [],
      selectedUsers: [],
      selectedUser: [],
      selectedComapnyUsers: [],
      selectedContactTypes: [],
      selectedContactType: "",
      selectedStatus: "ACTIVE",
      statusOption: [
        { name: "Active", value: "ACTIVE" },
        { name: "Inactive", value: "INACTIVE" },
        { name: "Archived", value: "ARCHIVED" },
      ],
      contactUsers: [],
      contactTypes: [],
      modalData: {
        template_id: "",
        name: "",
        action: "",
        isShareTemplate: false,
        is_duplicate: false,
        configId: null,
      },
      documentActionModal: false,
      selectedTemplateId: null,
      template_title: " ",
      modalLoading: false,
      changeTemplatesOwner: false,
      modalX: 0,
      modalY: 0,
      checkDocumentStatusForCert: "",
      dragging: false,
      CompanyIdForIndividualDocument: "",
      ConfigurableDocumentIdForIndividualDocument: "",
      companyUsers: [],
      searchingCompanyUsers: [],
      selectedCompanyUser: "",
      authid: "",
      userId: null,
      companyId: null,
      is_shared_template: null,
      is_shared_template_id: null,
      currentExistedUsers: [],
      searchedContact: "",
      searchedContacts: [],
      searchedContactTypes: [],
      activeHeadingNames: [],
      selectAllContacts: false,
      selectAllContactTypes: false,
      bulkSendActionModal: false,
      selectedCofigurable: null,
      selectedTemplate: null,
      primaryHeaders: [],
      userPrimaryDetails: [],
      isGenerateCSVFile: false,
      uploadedCSVDataResult: [],
      successfullCSVData: [],
      errorfullCSVData: [],
      contactLoading: false,
      isContactsCreated: false,
      selectedSharedTemplate: null,
      selectedDuplicateTemplate: null,
      getBulkSendPercentage: 0,
      currentActiveRowIndex: -1,
      currentActiveSuccessRowIndex: -1,
      bulkSendBtnLoding: false,
      templateTitleOfChangeOwner: "",
      createdByEmailCheck: "",
      //dialogVisibleForBulkDelete:false,
      uploadedCSVFile: "",
      isBulkSendIsProccessing: false,
      completedPercentage: 0,
      docSettingsVisible: false,
      documentSettingsVisibleCheckForBulkSendAndTOSendToContacts: false,
      changeOwner: false,
      getLinkModal: false,
      selectedConfigurable: null,
      documentId: null,
      eventLength: "",
      show_drawer: false,
      configurableDocumentId: null,
      selectedDocuments: [],
      selectedDocumentsModal: false,
      documentsText: "",
      showTemplateDataView: false,
      selectedConfigurableDocumentId: null,
      update_contacts: false,
      primaryHeads: [],
      sentContactsModal: false,
      selectedContactTypeContacts: [],
      pdfPages: 1,
      selectAllContactsForSend: false,
      SelectedAndFilterdContacts: [],
      searchCompanyUserEmail: "",
      searchedContactsForSend: [],
      searchedContactForSend: "",
      documentUsersForSend: [],
      isBulkSend: true,
      sender_auto_fill: false,
      csvUploadLoading: false,
      documentCompletedID: "",
      isUserHasSign: true,
      sentToContactsLoading: false,
      senderFilledAllFields: false,
      detailsDownload: false,
      detailsDownloadForCert: false,
      fieldsDataForPrintCheque: {},
      printChequesData: [],
      selectedHistroyDocuments: {
        created_at: null,
        DocId: null,
        Docname: "",
        senderName: "",
        Doc_recipients: "",
        Document_Status: "",
        DocumentId: "",
        DateCreated: "",
        documentIdforHistory: "",
        checkDocumentStatus: false,
      },
      allTemplatesData: {},
    };
  },
  computed: {
    getPdfPages() {
      return this.pdfPages;
    },
    documentIdforHistory() {
      let ids = [];

      if (
        this.getSingleDocumentData.data.document_generated_ids &&
        this.getSingleDocumentData.data.document_generated_ids.length
      ) {
        if (
          this.getSingleDocumentData.data.document_generated_ids.length === 1
        ) {
          ids = [this.getSingleDocumentData.data.document_generated_ids[0]];
        } else {
          ids = this.getSingleDocumentData.data.document_generated_ids.map(
            (id, index) => `${index + 1}) ${id}`
          );
        }
      }

      if (
        !ids.length &&
        this.getSingleDocumentData.data.document_generated_id
      ) {
        ids.push(this.getSingleDocumentData.data.document_generated_id);
      }

      if (!ids.length) {
        let doc_url =
          this.getSingleDocumentData.data.document_completed_urls &&
          this.getSingleDocumentData.data.document_completed_urls.length
            ? this.getSingleDocumentData.data.document_completed_urls
            : this.getSingleDocumentData.data.document_completed_url
            ? [this.getSingleDocumentData.data.document_completed_url]
            : [];
        if (doc_url.length) {
          doc_url.forEach((e) => {
            let data = e.split("/");
            ids.push(data[data.length - 1].replace(".pdf", "").split("_")[0]);
          });
        }
      }

      return ids.length ? ids.join("\n") : "";
    },
    getSignaturesCount() {
      let count = 0;
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data.pages &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0] &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0]
          .fields
      ) {
        this.getSingleDocumentData.data.configurable_document_data.pages[0].fields.forEach(
          (el) => {
            if (el && (el.type == "SIGNATURE" || el.type == "my-signature")) {
              count = count + 1;
            }
          }
        );
      }
      return count;
    },
    getDocumentSubject() {
      let sub = "Requesting esign";
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.email_content &&
        this.getSingleDocumentData.data.email_content.subject
      ) {
        sub = "";
        sub = this.getSingleDocumentData.data.email_content.subject;
      }
      return sub;
    },
    getInitialsCount() {
      let count = 0;
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data.pages &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0] &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0]
          .fields
      ) {
        this.getSingleDocumentData.data.configurable_document_data.pages[0].fields.forEach(
          (el) => {
            if (el && (el.type == "INITIAL" || el.type == "MY_INITIAL")) {
              count = count + 1;
            }
          }
        );
      }
      return count;
    },
    checkDocumentStatus() {
      // if (
      //   this.getSingleDocumentData &&
      //   this.getSingleDocumentData.data &&
      //   this.getSingleDocumentData.data.document_status &&
      //   this.getSingleDocumentData.data.document_status == "COMPLETED"
      // ) {
      //   return true;
      // }else{
      return false;
      //   }
    },

    getCurrentPageOfErrorfullCSVData() {
      const from = (this.currentPageForBSError - 1) * this.pageSizeForBSError;
      let to = from + this.pageSizeForBSError;
      if (to > this.totalForBSError) {
        to = this.totalForBSError;
      }
      return this.errorfullCSVData.slice(from, to);
    },
    paginationPropsForBS() {
      return [5, 10, 20, 50];
    },
    getSenderIpAddress() {
      let ip = "-";
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data
          .sender_ip_address
      ) {
        ip = "";
        ip =
          this.getSingleDocumentData.data.configurable_document_data
            .sender_ip_address;
      } else {
        ip = "";
        ip =
          this.getSingleDocumentData &&
          this.getSingleDocumentData.data &&
          this.getSingleDocumentData.data.sender_ip_address;
      }
      return ip;
    },

    getSenderEmail() {
      let sender = "";
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_users
      ) {
        let tempSender = this.getSingleDocumentData.data.document_users.find(
          (el) => el && el.type && el.type == "SENDER"
        );
        sender = tempSender.email;
      }
      return sender;
    },
    getDocumentCompletedAt() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.completed_at
      ) {
        return this.getSingleDocumentData.data.completed_at;
      }
      return "-";
    },
    getDocumentCreatedAt() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.created_at
      ) {
        return this.getSingleDocumentData.data.created_at;
      }
      return "-";
    },
    getDocumentCreatedZone() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.sender_time_zone
      ) {
        return this.getSingleDocumentData.data.sender_time_zone;
      }
      return "-";
    },
    getCurrentPageOfSuccessfullCSVData() {
      const from = (this.currentPageForBS - 1) * this.pageSizeForBS;
      let to = from + this.pageSizeForBS;
      if (to > this.totalForBS) {
        to = this.totalForBS;
      }
      return this.successfullCSVData.slice(from, to);
    },
    basePdfdownloadUrl() {
      // return appConfig.JAVA_BASE_URL;
      return appConfig.S3_BUCKET_URL;
    },
    dateFormat() {
      let dateHistory = "";
      if (
        this.getApplicationSettings &&
        this.getApplicationSettings.data &&
        this.getApplicationSettings.data.date
      ) {
        dateHistory = this.getApplicationSettings.data.date;
        return dateHistory;
      } else {
        let date_hist = this.getDefaultDateFormat;
        return date_hist;
      }
    },
    //  documentIdforHistory() {
    //   let doc_url = "";
    //   if (
    //     this.getSingleDocumentData &&
    //     this.getSingleDocumentData.data &&
    //     this.getSingleDocumentData.data.document_completed_url &&
    //     this.getSingleDocumentData.data.document_completed_url.slice(36, 72)
    //   ) {
    //     doc_url = this.getSingleDocumentData.data.document_completed_url.slice(
    //       36,
    //       72
    //     );
    //   }
    //   return doc_url;
    // },
    paginationPropsForIC() {
      return [5, 10, 20, 50];
    },
    ...mapGetters("documents", [
      "getAllTemplates",
      "getDocumentTemplateDeleteStatus",
      "getDocumentTemplatePermanentDeleteStatus",
      "getDraftDocumentCreateStatus",
      "getDraftDocumentCreateData",
      "getDocumentLogs",
      "getCloneVoidDocStatus",
      "getCloneVoidDocData",
      "getAllCompanyUsers",
      "getCreateBulkContactsStatus",
      "getDocumentsCountByTemplatId",
      "getChangeOwnershipOfTemplateData",
      "getChangeOwnershipOfTemplatestatus",
      "getDeleteDocumentTemplateError",
      "getFetchKeysForPCFields",
    ]),
    ...mapGetters("errors", ["getError"]),
    ...mapGetters("templates", [
      "getRenameTemplateStatus",
      "getDuplicateTemplateStatus",
      "getUpdateCompanyDocumentStatus",
      "getShareTemplateStatus",
    ]),

    ...mapGetters("configurableDocuments", [
      "getConfigurableDocumentDataById",
      "getConfigurableDocumentStatusUpdateStatus",
      "getConfigurableDocumentExpirationSettigns",
      "getWordFileContent",
    ]),

    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("employeeDocuments", [
      "getAuthorizedRepresentativeSignatureStatus",
      "getDocumentUserSignatureStatus",
      "getDocumentApprovalStatus",
      "getDocumentCompletedAfterApproval",
    ]),

    ...mapGetters("documents", [
      "getResendDocumentStatus",
      "getSingleDocumentData",
      "getDocumentVoidStatus",
      "getDocumentCorrectStatus",
      "getArchiveDocumentStatus",
      "getDocumentCorrectErrors",
      "getDocumentDeclineStatus",
      "getDocumentCompletedStatus",
      "getCompanyDocumentStatus",
      "getCompanyDocumentData",
      "getCompanyDocumentErrors",
    ]),
    ...mapGetters("companyDocuments", [
      "getErrors",
      "getDocumentUploadStatus",
      "getCompanyDocumentAddStatus",
      "getNewCompanyDocument",
      "getDocumentUploadStatusUpdated",
      "getCompanyDocumentUpdateStatus",
    ]),

    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("contactTypes", ["getAllContactTypes"]),
    ...mapGetters("contacts", ["getContactUser", "getAllContacts"]),
    ...mapGetters("settings", ["getApplicationSettings"]),
    ...mapGetters("entities", ["getCompanyEntityDetails", "getAllEntities"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("templatesData", ["getTemplatesData"]),
    ...mapGetters("entityGroups", ["getSingleEntityGroupData"]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),

    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    paginationPropsTable() {
      return [10, 20, 50];
    },
    isImage() {
      return (type) => (type && type.indexOf("image") > -1 ? true : false);
    },
    getSuccessfullCSVData() {
      return this.successfullCSVData;
    },
    isPdf() {
      return (type) => (type && type.indexOf("pdf") > -1 ? true : false);
    },

    getUsersForCertificate() {
      let users = [];
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_users &&
        this.documentLogs &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data.pages &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0] &&
        this.getSingleDocumentData.data.configurable_document_data.pages[0]
          .fields
      ) {
        this.getSingleDocumentData.data.document_users.forEach((user) => {
          let obj = {};
          if (user.user_type != "CC") {
            if (user.sent_on) {
              obj.sent_time = user.sent_on;
            }
            if (user.document_filling_date) {
              obj.completed_time = user.document_filling_date;
            }
            obj.email = user.email;
            obj.name = user.first_name + " " + user.last_name;
            obj.print_complete =
              user && user.completed_by_print ? user.completed_by_print : false;
            if (
              user &&
              user.type == "SENDER" &&
              this.getSingleDocumentData.data.configurable_document_data
                .sender_ip_address
            ) {
              obj.user_ip =
                this.getSingleDocumentData.data.configurable_document_data.sender_ip_address;
            }
            this.documentLogs.forEach((el) => {
              if (
                !obj.user_ip &&
                user &&
                user.email &&
                el &&
                el.contact_id &&
                el.ip_address &&
                el.contact_id.email == user.email
              ) {
                obj.user_ip = el.ip_address;
              }
              if (
                !obj.accept_time &&
                user &&
                user.email &&
                el &&
                el.contact_id &&
                el.contact_id.email == user.email &&
                el.status == "ACCEPTED_TERMS_&_CONDITIONS"
              ) {
                obj.accept_time = el.time;
              }
              if (
                !obj.accept_time &&
                user &&
                user.email &&
                el &&
                el.contact_id &&
                el.contact_id.email == user.email &&
                el.status == "DOCUMENT_VIEWED"
              ) {
                obj.view_time = el.time;
              }
            });
            let elements =
              this.getSingleDocumentData.data.configurable_document_data
                .pages[0].fields;
            for (let index = 0; index < elements.length; index++) {
              let field = elements[index];
              const documentValue = this.getValueFromSavedDocument(field.key);
              if (
                (elements[index].type == "SIGNATURE" ||
                  elements[index].type == "INITIAL") &&
                documentValue
              ) {
                elements[index].source = documentValue;
                this.$set(elements[index], "source", documentValue);
              }
              if (
                elements[index].type == "PRIMARY_FIELDS" &&
                !elements[index].content
              ) {
                this.$set(elements[index], "content", documentValue);
              }
              if (documentValue) {
                this.$set(elements[index], "value", documentValue);
              }
            }
            elements.forEach((el) => {
              if (
                el &&
                (el.type == "SIGNATURE" || el.type == "my-signature") &&
                el.filled_by == user.value &&
                el.source
              ) {
                obj.signature_source = el.source;
              }
              // if (
              //   el &&
              //   (el.type == "INITIAL" || el.type == "MY_INITIAL") &&
              //   el.filled_by == user.value &&
              //   el.source
              // ) {
              //   obj.initial_source = el.source;
              // }
            });
            users.push(obj);
          }
        });
      }
      return users;
    },
    checkDocumentStatusIsCompletedOrNot() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_status &&
        this.getSingleDocumentData.data.document_status == "COMPLETED"
      ) {
        return true;
      } else {
        return false;
      }
    },
    fetchDocumentusers(doc) {
      let recipientUsers = [];
      if (
        doc &&
        doc.document_users &&
        doc.document_users.length &&
        doc.document_users.length > 1
      ) {
        doc.document_users.forEach((user) => {
          if (user && user.type != "SENDER" && !user.is_cc) {
            let obj = {};
            let name = user.first_name + " " + user.last_name;
            if (name && name.length) {
              obj.fullname = name;
              obj.email = user.email;
              recipientUsers.push(obj);
              return recipientUsers;
            } else {
              let users = "No Receivers";
              return users;
            }
          }
        });
        return recipientUsers;
      } else {
        let users = "SENDER";
        return users;
      }
    },

    getVariablesOfusers() {
      let fields = [];
      if (
        this.selectedCofigurable &&
        this.selectedCofigurable.document_users &&
        this.selectedCofigurable.pages &&
        this.selectedCofigurable.pages[0] &&
        this.selectedCofigurable.pages[0].fields
      ) {
        this.selectedCofigurable.document_users.forEach((row) => {
          let obj = {};
          obj["name"] = row.name;
          obj["fields"] = [];
          if (row.type != "SENDER" && !row.constant_user) {
            obj["fields"].push(row.name + "::" + "first_name");
            obj["fields"].push(row.name + "::" + "last_name");
            obj["fields"].push(row.name + "::" + "email");
            this.selectedCofigurable.pages[0].fields.forEach((el) => {
              if (
                el &&
                el.type != "SIGNATURE" &&
                el.type != "INITIAL" &&
                el.type != "my-signature" &&
                el.type != "MY_INITIAL" &&
                el.type != "ATTACHMENT" &&
                el.type != "IMAGE" &&
                el.type != "DATE_SIGNED"
              ) {
                if (
                  (row.value == el.filled_by ||
                    (row.value == el.selected_user &&
                      el.selected_tag != "first_name" &&
                      el.selected_tag != "last_name" &&
                      el.selected_tag != "email")) &&
                  row.name &&
                  el.key
                ) {
                  const uniqueKey = this.generateUniqueKey(
                    el.label,
                    Object.keys(this.mapped_entity_fields)
                  );
                  this.mapped_entity_fields[uniqueKey] = el.key;

                  if (
                    el.field_type == "ENTITY_VARIABLE" ||
                    el.field_type == "ENTITY"
                  ) {
                    obj["fields"].push(row.name + "::" + uniqueKey);
                  } else {
                    obj["fields"].push(row.name + "::" + el.key);
                  }
                  // obj["fields"].push(row.name + "::" + el.key);
                }
              }
            });
          } else {
            this.selectedCofigurable.pages[0].fields.forEach((el) => {
              if (
                el &&
                el.type != "SIGNATURE" &&
                el.type != "INITIAL" &&
                el.type != "my-signature" &&
                el.type != "MY_INITIAL" &&
                el.type != "ATTACHMENT" &&
                el.type != "IMAGE" &&
                el.type != "DATE_SIGNED"
              ) {
                if (
                  (row.value == el.filled_by ||
                    (row.value == el.selected_user &&
                      el.selected_tag != "first_name" &&
                      el.selected_tag != "last_name" &&
                      el.selected_tag != "email")) &&
                  row.name &&
                  el.key
                ) {
                  const uniqueKey = this.generateUniqueKey(
                    el.label,
                    Object.keys(this.mapped_entity_fields)
                  );
                  this.mapped_entity_fields[uniqueKey] = el.key;
                  let userName = row.name.toLowerCase();
                  if (
                    el.field_type == "ENTITY_VARIABLE" ||
                    el.field_type == "ENTITY"
                  ) {
                    obj["fields"].push(userName + "::" + uniqueKey);
                  } else {
                    obj["fields"].push(userName + "::" + el.key);
                  }
                  // let userName = row.name.toLowerCase();
                  // obj["fields"].push(userName + "::" + el.key);
                }
              }
            });
          }
          fields.push(obj);
        });
      }
      return fields;
    },
  },
  async mounted() {
    this.authid =
      this.getAuthenticatedUser && this.getAuthenticatedUser._id
        ? this.getAuthenticatedUser._id
        : null;
    Promise.all([
      this.fetchTemplates(),
      this.fetchContactTypes(),
      this.companyEntityDetails(),
      this.$store.dispatch("contactTypes/fetchAllContactTypes", {
        get_all: true,
        type: "CONTACT_TYPE",
      }),
    ]);
  },
  beforeDestroy() {
    this.$store.commit(
      "navigationOpen/setCurrentMenu",
      {},
      {
        root: true,
      }
    );
  },
  methods: {
    isInDocumentUsers(documentUsers) {
      let hasUser = documentUsers
        ? documentUsers.findIndex(
            (cu) =>
              cu &&
              cu.email &&
              this.getAuthenticatedUser &&
              this.getAuthenticatedUser.email &&
              cu.email == this.getAuthenticatedUser.email
          )
        : -1;
      if (hasUser != -1) {
        return true;
      } else {
        return false;
      }
    },
    async fetchContacts() {
      try {
        await this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,
          type: "CONTACT",
        });
      } catch (err) {
        console.log("fetchContacts", err);
      }
    },
    async originalDownload(data) {
      console.log(data);
      if (
        data &&
        data.company_document_data &&
        data.company_document_data.document_original_download_urls &&
        data.company_document_data.document_original_download_urls &&
        data.company_document_data.document_original_download_urls.length
      ) {
        let url = data.company_document_data.document_original_download_urls;
        this.configureLoading = true;
        this.configureLoadingMessage = "Document(s) Downloading....";
        await this.downloadFilesFromS3Paths(url, data.document_names);
        this.configureLoading = false;
      } else if (
        data &&
        data.document_original_download_urls &&
        data.document_original_download_urls.length
      ) {
        let url = data.document_original_download_urls
          ? data.document_original_download_urls
          : data?.configurable_document_data?.document_upload_urls;
        this.configureLoading = true;
        this.configureLoadingMessage = "Document(s) Downloading....";
        await this.downloadFilesFromS3Paths(url, data.document_names);
        this.configureLoading = false;
      } else if (
        data &&
        data?.configurable_document_data?.document_upload_urls?.length &&
        data?.document_names
      ) {
        let urls = data?.configurable_document_data?.document_upload_urls
          ? data.configurable_document_data.document_upload_urls
          : data.configurable_document_data.document_upload_url;
        this.configureLoading = true;
        this.configureLoadingMessage = "Document(s) Downloading....";
        urls.forEach(async (url, i) => {
          let nameDoc = data?.document_names[i] || `Document_${i + 1}`;
          await this.downloadFilesFromS3Paths([url], [nameDoc]);
        });
        this.configureLoading = false;
      } else {
        let url = data.configurable_document_data
          ? data.configurable_document_data.document_upload_url
          : data.document_upload_url;
        this.configureLoading = true;
        this.configureLoadingMessage = "Document(s) Downloading....";
        let nameDoc = data.configurable_document_data
          ? data.name
          : data.title
          ? data.title
          : data.name;
        /** MIXINS */
        await this.downloadFilesFromS3Paths([url], [nameDoc]);
        this.configureLoading = false;
      }
    },
    startDrag(event) {
      this.dragging = true;
      this.modalX = event.clientX - event.target.offsetLeft;
      this.modalY = event.clientY - event.target.offsetTop;

      document.addEventListener("mousemove", this.doDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    doDrag(event) {
      if (this.dragging) {
        this.modalX = event.clientX;
        this.modalY = event.clientY;
      }
    },
    stopDrag() {
      this.dragging = false;
      document.removeEventListener("mousemove", this.doDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    changeTemplateOwner() {
      this.changeTemplatesOwner = false;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.fetchTemplates();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.fetchTemplates();
    },
    visibleSettingsVisible(data) {
      this.docSettingsVisible = true;
      this.configIdForSettings = data.configurable_document_id;
    },
    async printCheque(data) {
      this.tableLoading = true;
      this.docTempId = data._id;
      this.configDocId = data.configurable_document_id;
      this.tableLoadingText = "Fetching keys...";
      this.showAPIDataPopup = true;
      if (data && data.configurable_document_id) {
        let conId = data.configurable_document_id;
        await this.$store.dispatch("documents/fetchKeysForPrintChequeFields", {
          conId,
        });
        if (this.getFetchKeysForPCFields) {
          this.keysAndLabelsArray = this.getFetchKeysForPCFields.data;
          this.printChequesData = [...this.keysAndLabelsArray];
        }
      }
      this.tableLoading = false;
    },
    copyNames1(data) {
      const names = data.map((item) => item.name).join(", ");
      navigator.clipboard
        .writeText(names)
        .then(() => {
          this.$message.success("Keys copied to clipboard");
        })
        .catch((err) => {
          console.error("Unable to copy keys", err);
          this.$message.error("Failed to copy keys");
        });
    },
    copyKeys1(data) {
      const keys = data.map((item) => item.key).join(", ");
      navigator.clipboard
        .writeText(keys)
        .then(() => {
          this.$message.success("Keys copied to clipboard");
        })
        .catch((err) => {
          console.error("Unable to copy keys", err);
          this.$message.error("Failed to copy keys");
        });
    },
    copyKeys() {
      const keys = this.keysAndLabelsArray.map((item) => item.key).join("\n");
      navigator.clipboard
        .writeText(keys)
        .then(() => {
          this.$message.success("Keys copied to clipboard");
        })
        .catch((err) => {
          console.error("Unable to copy keys", err);
          this.$message.error("Failed to copy keys");
        });
    },
    async changeTemplateUser(data) {
      this.loading = true;
      this.templateTitleOfChangeOwner = data.title;
      this.createdByEmailCheck = data.created_by;
      this.ConfigurableDocumentIdForIndividualDocument =
        data.configurable_document_id;
      this.changeTemplatesOwner = true;
      this.changeOwner = true;
      let params = {
        change_template_owner: this.changeOwner,
        company_id:
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : data.company_id,
      };
      await this.$store.dispatch("documents/fetchAllCompanyUsers", params);
      let companyUserName = " ";
      // let fullName = this.getAuthenticatedUser.email
      if (this.getAllCompanyUsers && this.getAllCompanyUsers.data) {
        let companyUsersData = this.getAllCompanyUsers.data;
        this.loading = false;
        this.companyUsers = companyUsersData.filter((el) => {
          companyUserName = el.email;
          return companyUserName;
        });
        this.searchingCompanyUsers = companyUsersData.filter((el) => {
          companyUserName = el.email;
          return companyUserName;
        });
      }
    },
    async changeOwnerOfATemplate() {
      if (this.createdByEmailCheck.email == this.selectedComapnyUsers) {
        this.$message.warning(
          "The email which you selected is already a owner of this template.!"
        );
      } else {
        this.changeTemplatesOwner = false;
        let params = {
          configurable_document_id:
            this.ConfigurableDocumentIdForIndividualDocument,
          selectedCompanyUser: this.selectedComapnyUsers,
        };
        await this.$store.dispatch(
          "documents/ChangeOwnershipOfTemplate",
          params
        );

        if (this.getChangeOwnershipOfTemplatestatus) {
          this.$notify.success({
            title: "Success",
            message: "Template Owner Changed Successfully.",
          });
          await this.$store.dispatch(
            "configurableDocuments/fetchConfigurableDocumentById",
            { id: this.ConfigurableDocumentIdForIndividualDocument }
          );
          this.changeTemplatesOwner = false;
          await this.fetchTemplates();
        }
      }
    },
    async companyEntityDetails() {
      try {
        await this.$store.dispatch("entities/fetchCompanyEntityDetails");
        // this.getCompanyEntityDetails.entity_data_details[0]._id
      } catch (err) {
        console.log("companyEntityDetails", err);
      }
    },
    gotoCSV() {
      this.activeStep = 0;
    },
    // statusOfPublishedandUnpublished(data){
    //   let status = data.is_published
    //   return status
    // },

    bulkdelete() {
      // if (!this.checkPermissions("deleteDelete")) {
      //   this.$notify.error({
      //     title: "Error",
      //     message:
      //       "Permission denied for delete templates.Please contact owner.",
      //   });
      // } else {
      this.centerDialogVisible = true;
      //  }
    },
    bulkpermanentdelete() {
      this.centerDialogVisiblepermanentdelete = true;
    },
    async goToCreateContact() {
      let query = this.getNavigationQuery(this.$route.query);
      if (this.selectedContactType && this.selectedContactType._id) {
        this.$router.push({
          name: "add-contact",
          params: { contactTypeId: this.selectedContactType._id },
          query,
        });
      } else {
        let emptyUser = null;
        if (
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.document_users &&
          this.getConfigurableDocumentDataById.document_users.length
        ) {
          emptyUser = this.getConfigurableDocumentDataById.document_users.find(
            (user) => {
              if (user && user.type != "SENDER" && !user.contact_type) {
                return user;
              }
            }
          );
        }
        if (emptyUser && emptyUser.name) {
          this.$message.error(
            `Recipient (${emptyUser.name}) don't have contact-type. Please select.`
          );
        } else {
          this.$message
            .error(`Template users are not configured well.May be contact type is
        missing`);
        }
      }
    },
    handleSelectionChange(val) {
      this.selected_Template = [];
      this.selected_Template = val;
    },
    async selectedTemplateStatus() {
      this.selected_Template.forEach(async (el) => {
        if (
          el &&
          el.status &&
          (el.status == "ARCHIVED" || el.status == "INACTIVE")
        ) {
          let params = {};
          params.status = "ACTIVE";
          params.id = el._id;
          await this.$store.dispatch(
            "configurableDocuments/updateConfigurableTemplateStatus",
            params
          );
        }
        window.location.reload();
      });
    },
    async selectedTemplatePermanentDelete() {
      this.centerDialogVisiblepermanentdelete = false;
      this.selected_Template.forEach(async (e, index) => {
        let params = {
          company_document_id: e._id,
          configurable_document_id: e.configurable_document_id,
        };
        if (
          e &&
          e.status == "ARCHIVED" &&
          this.getAuthenticatedUser &&
          e.created_by._id == this.getAuthenticatedUser._id
        ) {
          this.configureLoading = true;
          this.LoadingText = "Deleting Template...";
          await this.$store.dispatch(
            "documents/permanentdeleteDocumentTemplate",
            params
          );
          this.configureLoading = false;
          if (index == this.selected_Template.length - 1) {
            this.$notify.success({
              title: "Success",
              message: "Templates deleted successfully",
            });
            await this.fetchTemplates();
          }
        } else {
          this.$message({
            showClose: true,
            message:
              "You can only delete the documents which are created by you.",
            type: "warning",
          });
        }
        await this.fetchTemplates();
      });
    },
    async selectedTemplateDelete() {
      this.centerDialogVisible = false;
      this.isTemplateDelete = true;
      let deleted = 0;
      if (this.selected_Template.length == 1) {
        if (
          this.selected_Template[0] &&
          this.getAuthenticatedUser &&
          this.selected_Template[0].created_by._id ==
            this.getAuthenticatedUser._id
        ) {
          this.configureLoading = true;
          this.LoadingText = "Deleting Template";
          await this.$store.dispatch(
            "documents/deleteDocumentTemplate",
            this.selected_Template[0]._id
          );
          if (this.getDocumentTemplateDeleteStatus) {
            this.$notify.success({
              title: "Success",
              message: `Template Deleted Successfully`,
            });
          } else if (this.getDeleteDocumentTemplateError) {
            this.$notify.error({
              title: "Error",
              message: this.getDeleteDocumentTemplateError,
            });
          }
          await this.fetchTemplates();
          this.configureLoading = false;
          // setTimeout(() => {
          //   this.refreshDocument();
          // }, 1000);
          return;
        }
      }

      this.selected_Template.forEach(async (e, index) => {
        if (
          e &&
          this.getAuthenticatedUser &&
          e.created_by._id == this.getAuthenticatedUser._id
        ) {
          this.configureLoading = true;
          this.LoadingText = "Deleting Template";
          await this.$store.dispatch("documents/deleteDocumentTemplate", e._id);
          if (this.getDocumentTemplateDeleteStatus) {
            deleted = deleted + 1;
          }
          setTimeout(() => {
            this.refreshDocument();
          }, 3000);
          if (index == this.selected_Template.length - 1) {
            let message = `Templates Deleted Successfully`;
            if (this.selected_Template.length > deleted) {
              message = ` ${deleted} Templates Deleted Successfully,
                         ${
                           this.selected_Template.length - deleted
                         } Document templates  associated with groups `;
            }
            this.$notify.success({
              title: "Success",
              message: message,
            });
            window.location.reload();
          }
        } else {
          this.$message({
            showClose: true,
            message:
              "You can only delete the documents which are created by you.",
            type: "warning",
          });
        }
      });
    },
    closeSentDocModal() {},
    async EditVoidDocument(data, id) {
      if (!this.checkPermissionForDocument(data, "editDraft")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied. Please contact owner for edit draft permission to clone document.",
        });
      } else {
        try {
          await this.$store.dispatch("documents/EditVoidDocument", id);
          if (this.getCloneVoidDocStatus && this.getCloneVoidDocData) {
            let new_id = this.getCloneVoidDocData.data._id;
            successNotification("Document cloned successfully");
            let query = this.getNavigationQuery(this.$route.query);
            this.$router.push({
              name: "employee-documents-custom-document-edit",
              params: {
                employee_document_id: new_id,
              },
              query,
            });
          } else {
            this.$notify({
              title: "Error",
              message: "Error in cloning document",
              type: "error",
            });
          }
        } catch (err) {
          console.log("EditVoidDocument", err);
        }
      }
    },
    setData() {
      this.documentName = this.getSingleDocumentData.data.title;
      this.document_url_path =
        this.getSingleDocumentData.data.configurable_document_data.document_upload_url;
      this.isDraft = this.getSingleDocumentData.data.document_status == "DRAFT";
      this.configurableDocumentId =
        this.getSingleDocumentData.data.configurable_document_id;
      this.documentId = this.getSingleDocumentData.data.company_document_id;
      this.workflowName = this.$route.query.workflow_name || "Company Document";
      this.documentCompletedID =
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_completed_url
          ? this.getSingleDocumentData.data.document_completed_url
              .split("download/")
              .pop()
              .split(".pdf")[0]
          : "";
    },
    generateReport() {
      this.detailsDownload = true;
      this.$refs.html2Pdf.generatePdf();
      this.detailsDownload = false;
    },
    async generateCertificate() {
      await this.$refs.doc2Cer.generatePdf();
    },
    getValueFromSavedDocument(key) {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_data
      ) {
        return this.getSingleDocumentData.data.document_data[key];
      }
      return null;
    },
    getDocumentUsersForSend(el) {
      let data = [];
      let newusers = [];
      for (let i = 0; i < this.selectedCofigurable.document_users.length; i++) {
        newusers.push(this.selectedCofigurable.document_users[i]);
      }
      newusers.forEach((user) => {
        if (user.type != "SENDER" && !user.constant_user) {
          let newObj = {};
          newObj = { ...newObj, ...user };
          let contactUser = this.getAllContacts.data.find((e) => e.email == el);
          let selectedContactTypes =
            this.getContactTypesFromContact(contactUser);
          newObj["email"] = contactUser.email;
          newObj["first_name"] = contactUser.first_name;
          newObj["last_name"] = contactUser.last_name;
          newObj["address"] = contactUser.address ? contactUser.address : "";
          newObj["phone"] = contactUser.phone_number
            ? contactUser.phone_number
            : "";
          newObj["company_name"] = contactUser.company_name
            ? contactUser.company_name
            : "";
          newObj["title"] = contactUser.title ? contactUser.title : "";
          if (selectedContactTypes && selectedContactTypes.length) {
            let dataId = selectedContactTypes.find(
              (e) =>
                e.contact_type == user.contact_type ||
                e.contact_type == user.contact_type._id
            );
            if (dataId?.account_data_id) {
              newObj["entity_data_id"] = dataId.account_data_id;
            }
          }
          data.push(newObj);
        } else {
          if (el && el.type == "SENDER") {
            user.first_name = this.getAuthenticatedUser.first_name;
            user.last_name = this.getAuthenticatedUser.last_name;
            user.email = this.getAuthenticatedUser.email;
            if (
              this.getCompanyEntityDetails &&
              this.getCompanyEntityDetails.entity_details &&
              this.getCompanyEntityDetails.entity_details[0] &&
              this.getCompanyEntityDetails.entity_details[0]._id
            ) {
              user.entity_data_id =
                this.getCompanyEntityDetails.entity_data_details[0]._id;
            }
          }
          data.push(user);
        }
      });
      newusers = [];
      return data;
    },
    async getEntityFieldData() {
      let params = {
        entity_data_ids: [],
      };
      this.SelectedAndFilterdContacts.forEach((email) => {
        let contactUser = this.getAllContacts.data.find(
          (e) => e.email == email
        );
        if (contactUser && contactUser.account_data_id) {
          if (contactUser.account_data_id._id) {
            params.entity_data_ids.push(contactUser.account_data_id._id);
          } else {
            params.entity_data_ids.push(contactUser.account_data_id);
          }
        }
      });
      if (
        this.getCompanyEntityDetails &&
        this.getCompanyEntityDetails.entity_details &&
        this.getCompanyEntityDetails.entity_details[0] &&
        this.getCompanyEntityDetails.entity_details[0]._id
      ) {
        params.entity_data_ids.push(
          this.getCompanyEntityDetails.entity_data_details[0]._id
        );
      }
      await this.$store.commit("templatesData/setTemplatesData", [], {
        root: true,
      });
      if (params.entity_data_ids && params.entity_data_ids.length) {
        await this.$store.dispatch(
          "templatesData/fetchUserTemplateData",
          params
        );
        await this.$store.dispatch("entities/fetchEntityDataByQuery", params);
      }
    },
    setUsersEntityData(users) {
      console.log("usersss", users);
    },
    prepareDocumentDataForUsers(el, users) {
      let data = {};
      let allTemplatesData = {};
      users.forEach((user) => {
        const seletedUserTemplateData = (this.getTemplatesData || []).filter(
          (e) =>
            (user.entity_data_id &&
              user.entity_data_id._id &&
              e.entity_data_id == user.entity_data_id._id) ||
            e.entity_data_id == user.entity_data_id
        );
        seletedUserTemplateData.forEach((e) => {
          allTemplatesData[user.value + "-" + e.template_id] = e.template_data;
        });
      });
      let selctedUserValue = users.find(
        (e) => e.type != "SENDER" && !e.constant_user
      );
      this.selectedCofigurable.pages[0].fields.forEach((element) => {
        if (element.type == "HEADING" || element.type == "PARAGRAPH") {
          data[element.selected_tag] = element.content;
        } else if (
          element.type == "PRIMARY_FIELDS" &&
          selctedUserValue &&
          element.selected_user == selctedUserValue.value
        ) {
          let contactUser = this.getAllContacts.data.find((e) => e.email == el);
          if (element.selected_tag == "phone" && contactUser) {
            data[element.key] = contactUser["phone_number"]
              ? contactUser["phone_number"]
              : "";
          } else {
            data[element.key] = contactUser[element.selected_tag]
              ? contactUser[element.selected_tag]
              : "";
          }
        } else if (
          element.template_id &&
          element.field_type == "ENTITY_FIELD"
        ) {
          let rawKey = element.selected_user + "-" + element.template_id;
          if (allTemplatesData[rawKey]) {
            let value = allTemplatesData[rawKey][element.key.split("-")[1]];
            if (element.key.split("-")) {
              let value = allTemplatesData[rawKey][element.key.split("-")[1]];
              if (value) {
                data[element.key] = value;
              } else if (element.group_key) {
                let value = allTemplatesData[rawKey][element.group_key];
                let checkBoxKey = element.key.split("/")[1];
                return value &&
                  value[checkBoxKey] &&
                  value[checkBoxKey].indexOf(checkBoxKey) !== -1
                  ? true
                  : false;
              }
            }
            if (value) {
              data[element.key] = value;
            } else {
              element.type == "CHECKBOX" && element.group_key
                ? (data[element.key] = false)
                : (data[element.key] = "");
            }
          } else if (element.type != "CHECKBOX" && !element.group_key) {
            data[element.key] = "";
          }
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else if (element.content) {
          data[element.key] = element.content;
        } else {
          element.type == "CHECKBOX" && element.group_key
            ? (data[element.key] = false)
            : (data[element.key] = "");
        }
      });
      return data;
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
    async sendTemplateToContacts() {
      this.isContactsCreated = true;
      this.completedPercentage = 0;
      this.isBulkSendIsProccessing = true;
      this.selectedCofigurable = this.selectedConfigurable;
      await this.getEntityFieldData();
      this.SelectedAndFilterdContacts.forEach(async (el) => {
        let documentUsers = await this.getDocumentUsersForSend(el);
        // await this.setUsersEntityData(documentUsers);
        let documentData = this.prepareDocumentDataForUsers(el, documentUsers);
        let timeZone = this.getTimeZone();
        this.documentUsers.map((el) => {
          if (
            el &&
            el.type === "SENDER" &&
            el.e_signature_order &&
            (el.e_signature_order > 0 || el.e_signature_order != 0) &&
            el.e_signature_required
          ) {
            this.senderFilledAllFields = false;
          }
        });
        this.selectedCofigurable.pages[0].fields.map((el) => {
          if (
            (el && el.filled_by == "SENDER" && el.type === "DATE_SIGNED") ||
            el.type === "SIGNATURE" ||
            el.type == "MY_INITIAL" ||
            el.type == "INITIAL"
          ) {
            el.source = "";
            el.value = "";
          }
        });
        let params = {
          document_template_id: this.selectedConfigurable.company_document_id,
          document_users: documentUsers,
          email_data: this.prepareEmailData(""),
          document_data: documentData,
          sender_time_zone: timeZone,
          is_anyone_can_approve:
            this.selectedConfigurable.is_anyone_can_approve,
          enforce_signature_order:
            this.selectedConfigurable.enforce_signature_order,
          configurable_document_id: this.selectedConfigurable._id,
          senderFilledAllFields: this.senderFilledAllFields,
          isFromTemplates: true,
          is_from_ck_editor: true,
        };

        params.document_users.map((el) => {
          if (
            el &&
            el.type === "SENDER" &&
            el.e_signature_order &&
            (el.e_signature_order > 0 || el.e_signature_order != 0) &&
            el.e_signature_required
          ) {
            this.senderFilledAllFields = false;
          }
        });
        await this.$store.dispatch("documents/createSentDocument", params);
        if (this.getDraftDocumentCreateStatus) {
          this.completedPercentage = this.completedPercentage + 1;
          this.getBulkSendPercentage =
            (this.completedPercentage /
              this.SelectedAndFilterdContacts.length) *
            100;
          if (this.getBulkSendPercentage == 100) {
            this.isBulkSendIsProccessing = false;
          }
        }
        // await this.resetFields();
      });
      this.documentSettingsVisibleCheckForBulkSendAndTOSendToContacts = false;
    },
    selectAllContactsMethod() {
      this.SelectedAndFilterdContacts = [];
      if (this.selectAllContactsForSend) {
        this.selectedContactTypeContacts.forEach((el) =>
          this.SelectedAndFilterdContacts.push(el.email)
        );
      }
    },
    searchContactForSend() {
      if (this.searchedContactForSend) {
        this.searchedContactsForSend = this.selectedContactTypeContacts.filter(
          (el) =>
            el.email.includes(`${this.searchedContactForSend.toLowerCase()}`)
        );
      }
    },
    searchCompanyUsers() {
      //  let searchedCompanyUsers = this.companyUsers
      this.companyUsers = this.searchingCompanyUsers.filter((e) =>
        e.email.includes(this.searchCompanyUserEmail.toLowerCase())
      );
    },
    async getSelectedContacts() {
      if (
        this.selectedConfigurable &&
        this.selectedConfigurable.document_users
      ) {
        this.selectedContactType = "";
        let senderUser = this.selectedConfigurable.document_users.find(
          (el) => el.type == "SENDER"
        );
        let constantUsers = this.selectedConfigurable.document_users
          .filter((el) => el.type != "SENDER" && el.constant_user)
          .flatMap((e) => e.email);
        let selectedContactType = this.selectedConfigurable.document_users.find(
          (user) => user.type != "SENDER"
        );
        if (senderUser) {
          senderUser.first_name = this.getAuthenticatedUser.first_name;
          senderUser.last_name = this.getAuthenticatedUser.last_name;
          senderUser.email = this.getAuthenticatedUser.email;
          senderUser.user_id = this.getAuthenticatedUser._id;
        }
        if (selectedContactType && selectedContactType.contact_type) {
          let params = { get_all: true };
          await this.$store.dispatch("contactTypes/fetchContactTypes", params);
          if (this.getAllContactTypes) {
            this.selectedContactType = this.getAllContactTypes.data.find(
              (e) => e._id == selectedContactType.contact_type
            );
          }
        }
        let query = {
          get_all: true,
          type: "CONTACT",
        };
        if (this.selectedContactType.type != "STANDARD") {
          query["contact_type"] = selectedContactType.contact_type;
        }
        await this.$store.dispatch("contacts/fetchAllContacts", query);
        if (this.getAllContacts && this.getAllContacts.data) {
          this.selectedContactTypeContacts = this.getAllContacts.data.filter(
            (el) => {
              if (
                el &&
                el.email &&
                constantUsers.indexOf(el.email) == -1 &&
                el.email != this.getAuthenticatedUser.email
              ) {
                return el;
              }
            }
          );
          // this.selectedContactTypeContacts.forEach((el) =>
          //   this.SelectedAndFilterdContacts.push(el.email)
          // );
          this.sentToContactsLoading = false;
        } else {
          this.sentToContactsLoading = false;
        }
      }
    },
    async closeSentContacts() {
      if (!this.isBulkSendIsProccessing) {
        this.sentContactsModal = false;
        this.visibleDialog = false;
        this.selectedConfigurable = null;
        this.selectedTemplate = null;
        this.selectedContactTypeContacts = [];
        this.selectAllContactsForSend = false;
        this.SelectedAndFilterdContacts = [];
        this.searchedContactsForSend = [];
        this.searchedContactForSend = "";
        this.documentUsersForSend = [];
        this.mailContent = "";
        this.mailSubject = "";
        this.isBulkSend = true;
        this.documentSettingsVisibleCheckForBulkSendAndTOSendToContacts = false;
        this.resetBulkModal();
        await this.fetchTemplates();
      }
    },
    async sentToContacts(data) {
      let isCkTemplate = false;
      if (data && data.configurable_document_id) {
        let configurable_document_id = data.configurable_document_id;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: configurable_document_id,
          }
        );
        if (
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.ck_editor_content &&
          this.getConfigurableDocumentDataById.ck_editor_content !== ""
        ) {
          isCkTemplate = true;
        }
        if (
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.document_users
        ) {
          let nonConstantUsers =
            this.getConfigurableDocumentDataById.document_users.filter(
              (user) => {
                if (user && !user.constant_user && user.type != "SENDER") {
                  return user;
                }
              }
            );
          if (
            this.getConfigurableDocumentDataById &&
            this.getConfigurableDocumentDataById.document_users &&
            this.getConfigurableDocumentDataById.document_users.length &&
            this.getConfigurableDocumentDataById.document_users.length == 1
          ) {
            this.$confirm(
              "There is no recipients. Please add atleast one recipient."
            )
              .then(() => {
                this.onEdit(data);
              })
              .catch(() => {});
          } else if (
            this.getConfigurableDocumentDataById &&
            this.getConfigurableDocumentDataById.document_users &&
            nonConstantUsers &&
            nonConstantUsers.length &&
            nonConstantUsers.length == 1
          ) {
            if (
              !isCkTemplate &&
              this.getConfigurableDocumentDataById.pages &&
              this.getConfigurableDocumentDataById.pages[0] &&
              this.getConfigurableDocumentDataById.pages[0].fields &&
              this.getConfigurableDocumentDataById.pages[0].fields &&
              !this.getConfigurableDocumentDataById.pages[0].fields.length
            ) {
              this.$confirm("There is no fields. Please add some fields.")
                .then(() => {
                  this.onEdit(data);
                })
                .catch(() => {});
            } else if (
              (this.getConfigurableDocumentDataById.pages &&
                this.getConfigurableDocumentDataById.pages[0] &&
                this.getConfigurableDocumentDataById.pages[0].fields &&
                this.getConfigurableDocumentDataById.pages[0].fields &&
                this.getConfigurableDocumentDataById.pages[0].fields.length) ||
              isCkTemplate
            ) {
              let signUsers = [];
              let userCount = 0;
              let checkFilledByUses = isCkTemplate
                ? this.getConfigurableDocumentDataById.ckeditor_fields
                : this.getConfigurableDocumentDataById.pages[0].fields;
              signUsers = checkFilledByUses.flatMap(
                (element) => element.filled_by
              );
              if (signUsers && signUsers.length) {
                userCount =
                  this.getConfigurableDocumentDataById.document_users.filter(
                    (user) => {
                      if (
                        user.user_type == "SIGNER" &&
                        user.type != "SENDER" &&
                        signUsers.indexOf(user.value) == -1
                      ) {
                        return user;
                      }
                    }
                  );
                if (userCount.length) {
                  this.$confirm(
                    "Every signing user must have at least one fillable field."
                  )
                    .then(() => {
                      this.onEdit(data);
                      isCkTemplate = false;
                    })
                    .catch(() => {});
                } else {
                  this.selectedConfigurable =
                    this.getConfigurableDocumentDataById;
                  this.selectedTemplate = data;
                  this.getSelectedContacts();
                  this.sentToContactsLoading = true;
                  this.sentContactsModal = true;
                  this.isBulkSend = false;
                }
              } else {
                this.$confirm(
                  "Every signing user must have at least one fillable field."
                )
                  .then(() => {
                    this.onEdit(data);
                  })
                  .catch(() => {});
              }
            }
          } else {
            this.$confirm("There must be  only one recipient")
              .then(() => {
                this.onEdit(data);
              })
              .catch(() => {});
          }
        }
      }
    },
    logsStatus() {
      this.getDocumentLogs.data;
    },
    async getDocLogs() {
      await this.$store.dispatch("documents/fetchDocumentLogsById", {
        document_id: this.$route.params.employee_document_id,
      });
      if (this.getDocumentLogs && this.getDocumentLogs.data) {
        this.documentLogs = this.getDocumentLogs.data;
      }
    },
    async modalTemplateDataView(data) {
      this.selectedConfigurable = null;
      let configurable_document_id = data.configurable_document_id;
      await this.$store.dispatch(
        "configurableDocuments/fetchConfigurableDocumentById",
        {
          id: configurable_document_id,
        }
      );
      this.selectedConfigurable = this.getConfigurableDocumentDataById;
      this.documentsText = "View data of " + data.title + " template";
      this.selectedConfigurableDocumentId = data.configurable_document_id;
      this.showTemplateDataView = true;
    },
    resetDocuments() {
      this.selectedDocuments = [];
      this.selectedDocumentsModal = false;
      this.documentsText = "";
    },
    close() {
      this.$emit("close");
      this.selectedDocumentsModal = false;
    },

    /* isSendersTurnOrNot() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_users
      ) {
        let currentUser = this.getSingleDocumentData.data.document_users.find(
          (x) => x.email === this.currentAuthUserEmail
        );
        if (
          this.getSingleDocumentData.data.enforce_signature_order &&
          currentUser &&
          currentUser.email &&
          !is_cc &&
          currentUser.email !=
            this.getSingleDocumentData.data.current_active_user &&
          !currentUser.document_filling_status
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },*/
    async resendDocLink(doc) {
      let sendinguser = doc.document_users.find((x) => {
        return (
          x.sent_status &&
          !x.document_filling_status &&
          !x.is_cc &&
          x.type != "SENDER"
        );
      });
      // if(!this.checkPermissions('resendDocument')){
      //   this.$notify.error({
      //     title:"Error",
      //     message:"Permission denied for resend document.Please contact owner."
      //   })
      // }else{
      try {
        let params = {
          document_id: doc._id,
          contact_id: sendinguser.contact_id
            ? sendinguser.contact_id
            : sendinguser.contact_id._id,
        };
        this.esignLoading = true;
        await this.$store.dispatch(
          "documents/resendDocumentLinkToContact",
          params
        );
        this.esignLoading = false;
        if (this.getResendDocumentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document Link sent successfully",
          });
        }
      } catch (err) {
        this.esignLoading = false;
        console.log("resendDocLink", err);
      }
      // }
    },
    DocumentUsers(doc) {
      let users = "";
      let last_user = doc.document_users.length - 1;
      let isDocOwner = false;
      doc.document_users.forEach((user) => {
        if (
          user.email == this.getAuthenticatedUser.email &&
          user.type == "SENDER"
        ) {
          isDocOwner = true;
        }
      });
      if (isDocOwner) {
        users += "To: ";
        doc.document_users.forEach((user, i) => {
          if (user.type == "RECEIVER") {
            let sep = ",";
            if (i == last_user) {
              sep = " ";
            }
            if (!user.first_name) {
              users = "";
              users += "Users not assigned";
            } else {
              users += user.first_name + sep;
            }
          } else if (doc.document_users.length == 1) {
            users += "Only me";
          }
        });
      } else {
        users += "From: ";
        doc.document_users.forEach((user) => {
          if (user.type == "SENDER") {
            users += user.first_name;
          }
        });
      }
      return users;
    },
    ...mapActions({
      fetchConfigurableDocumentSettings:
        "configurableDocuments/fetchConfigurableDocumentExpirationSettignsByID",
      updateDocumentSettings:
        "configurableDocuments/updateConfigurableDocument",
    }),
    async onView(id) {
      let query = this.getNavigationQuery(this.$route.query);
      let newWindow = open(
        `/emp-documents/custom/${id}?key=${query.key}`,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function () {};
      // this.$router.push({
      //   name: "employee-documents-custom-document",
      //   params: {
      //     employee_document_id: id,
      //   },
      //   query,
      // });
    },

    async settings(_id) {
      this.docSettingsVisible = true;
      this.configurableDocumentId = _id;
    },
    async updateSettingsData(settings) {
      try {
        this.settingsLoading = true;
        await this.updateDocumentSettings({
          id: this.$route.params.configurable_document_id
            ? this.$route.params.configurable_document_id
            : this.configIdForSettings,
          data: settings,
        });
        if (this.getConfigurableDocumentUpdateStatus) {
          this.suceesNotify("Document settings updated successfully");
          await this.fetchConfigurableDocumentSettings({
            id: this.$route.params.configurable_document_id,
          });
        } else {
          this.errorNotify("Error in updating document settings");
        }
        this.settingsLoading = false;
      } catch (err) {
        this.settingsLoading = false;
        this.errorNotify("Error in updating document settings");
      }
    },

    gotoLinkedDocuments(id, status, title) {
      this.selectedDocuments = [];
      this.documentsText = "Documents created from " + title;
      if (
        this.getDocumentsCountByTemplatId &&
        this.getDocumentsCountByTemplatId.length
      ) {
        this.getDocumentsCountByTemplatId.forEach((doc) => {
          if (doc.configurable_document_id == id) {
            if (status == "COMPLETED") {
              if (doc.document_status == "COMPLETED") {
                this.selectedDocuments.push(doc);
              }
            } else if (status == "PROGRESS") {
              if (
                doc.document_status == "APPROVER" ||
                doc.document_status == "SENT"
              ) {
                this.selectedDocuments.push(doc);
              }
            } else if (status == "LINKED") {
              if (doc.is_from_template_link) {
                if (
                  doc.document_status == "APPROVER" ||
                  doc.document_status == "SENT" ||
                  doc.document_status == "COMPLETED"
                )
                  this.selectedDocuments.push(doc);
              }
            }
          }
        });
        this.selectedDocumentsModal = true;
      }
    },
    downloadAlldocuments(data) {
      this.onDownload(data);
      let allAttachments = this.attachmentsFiles(data);
      if (allAttachments && allAttachments.length) {
        allAttachments.forEach((el) => {
          let url = el.attachment_url;
          /** MIXINS */
          this.downloadFilesFromS3Paths([url], [el.title]);
        });
      }
    },

    async onDownload(data) {
      let doc = data.document_completed_url
        ? data.document_completed_url
        : data.configurable_document_data.document_upload_url;

      this.configureLoading = true;
      this.configureLoadingMessage = "Document(s) Downloading....";

      /** MIXINS */
      await this.downloadFilesFromS3Paths([doc], [data.name]);
      this.configureLoading = false;
    },
    /* fetchDocumentusers(doc){
      let recipientUsers = []
      if(doc && doc.document_users && doc.document_users.length && doc.document_users.length > 1){
       doc.document_users.forEach((user)=>{
        if(user && user.type != 'SENDER' && !user.is_cc){
          let obj = {}
          let name = user.first_name + ' ' + user.last_name
          if(name && name.length){
            obj.fullname = name;
            obj.email = user.email
             recipientUsers.push(obj);
           return recipientUsers
           
          }else{
            let users = "No Receivers"
            return users
          }
        }
      });
        return recipientUsers
      }else{
        let users = "SENDER"
            return users
      }
    },*/
    checkToOnlyResend(doc) {
      if (
        doc &&
        doc.document_users &&
        doc.document_users.length &&
        doc.document_users.length > 1
      ) {
        let user = doc.document_users.forEach((x) => {
          if (
            x.sent_status &&
            !x.document_filling_status &&
            !x.is_cc &&
            x.type != "SENDER"
          ) {
            if (user) {
              return true;
            } else {
              return false;
            }
          }
        });
        return true;
      } else {
        false;
      }
    },
    async downloadPdfFile(url, name) {
      axios.defaults.headers.common = {
        "Access-Control-Allow-Origin": window.location.origin,
      };

      await fetch(url, { method: "get", responseType: "blob" })
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          var fileURL = window.URL.createObjectURL(new Blob([blob]));
          var fileLink = document.createElement("a");
          let file_name = name.split(".")[0];
          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name + ".pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        });

      // axios({
      //   url: url,
      //   method: "GET",
      //   responseType: "blob",
      // }).then((response) => {
      //   var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      //   var fileLink = document.createElement("a");
      //   let file_name = name.split(".")[0];
      //   fileLink.href = fileURL;
      //   fileLink.setAttribute("download", file_name + ".pdf");
      //   document.body.appendChild(fileLink);

      //   fileLink.click();
      // });
    },
    gotoMainDocument(data) {
      if (data && data.document_completed_url) {
        let url = this.basePdfdownloadUrl + data.document_completed_url;
        window.open(url, "_blank");
      }
    },
    gotoAttachment(file) {
      if (file && file.attachment_url) {
        let url = this.basePdfdownloadUrl + file.attachment_url;
        window.open(url, "_blank");
      }
    },
    isUserIsDocumentOwner() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.user_id &&
        this.getSingleDocumentData.data.user_id.email &&
        this.currentAuthUserEmail ==
          this.getSingleDocumentData.data.user_id.email
      ) {
        return true;
      } else {
        return false;
      }
    },
    getCompletedCount(id) {
      let value = 0;
      if (
        this.getDocumentsCountByTemplatId &&
        this.getDocumentsCountByTemplatId.length
      ) {
        this.getDocumentsCountByTemplatId.forEach((doc) => {
          if (doc.configurable_document_id == id) {
            if (doc.document_status == "COMPLETED") {
              value = value + 1;
            }
          }
        });
      }
      return value;
    },
    getProgressCount(id) {
      let value = 0;
      if (
        this.getDocumentsCountByTemplatId &&
        this.getDocumentsCountByTemplatId.length
      ) {
        this.getDocumentsCountByTemplatId.forEach((doc) => {
          if (doc.configurable_document_id == id) {
            if (
              doc.document_status == "APPROVER" ||
              doc.document_status == "SENT"
            ) {
              value = value + 1;
            }
          }
        });
      }
      return value;
    },
    getLikendCount(id) {
      let value = 0;
      if (
        this.getDocumentsCountByTemplatId &&
        this.getDocumentsCountByTemplatId.length
      ) {
        this.getDocumentsCountByTemplatId.forEach((doc) => {
          if (doc.configurable_document_id == id && doc.is_from_template_link) {
            if (
              doc.document_status == "APPROVER" ||
              doc.document_status == "SENT" ||
              doc.document_status == "COMPLETED"
            )
              value = value + 1;
          }
        });
      }
      return value;
    },
    async closeGetLink() {
      this.getLinkModal = false;
      this.selectedConfigurable = null;
      await this.fetchTemplates();
    },

    async getLinkForTemplate(data) {
      let isCkTemplate = false;
      if (data && data.configurable_document_id) {
        let configurable_document_id = data.configurable_document_id;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: configurable_document_id,
          }
        );
        if (
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.ck_editor_content &&
          this.getConfigurableDocumentDataById.ck_editor_content !== ""
        ) {
          isCkTemplate = true;
        }
        if (
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.document_users
        ) {
          let nonConstantUser =
            this.getConfigurableDocumentDataById.document_users.filter(
              (user) => user.type != "SENDER" && !user.constant_user
            );
          if (
            this.getConfigurableDocumentDataById.is_published &&
            nonConstantUser &&
            nonConstantUser.length == 1
          ) {
            this.selectedConfigurable = this.getConfigurableDocumentDataById;
            this.getLinkModal = true;
          } else {
            let emptyUser = null;
            if (
              this.getConfigurableDocumentDataById &&
              this.getConfigurableDocumentDataById.document_users &&
              this.getConfigurableDocumentDataById.document_users.length
            ) {
              emptyUser =
                this.getConfigurableDocumentDataById.document_users.find(
                  (user) => {
                    if (user && user.type != "SENDER" && !user.contact_type) {
                      return user;
                    }
                  }
                );
            }
            if (
              this.getConfigurableDocumentDataById &&
              this.getConfigurableDocumentDataById.document_users &&
              this.getConfigurableDocumentDataById.document_users.length &&
              this.getConfigurableDocumentDataById.document_users.length == 1
            ) {
              this.$confirm(
                "There is no recipients. Please add atleast one recipient."
              )
                .then(() => {
                  this.onEdit(data);
                })
                .catch(() => {});
            } else if (emptyUser && emptyUser.name) {
              this.$confirm(
                `Recipient ${emptyUser.name} don't have contact-type. Please select.`
              )
                .then(() => {
                  this.onEdit(data);
                })
                .catch(() => {});
            } else if (
              this.getConfigurableDocumentDataById &&
              this.getConfigurableDocumentDataById.document_users &&
              this.getConfigurableDocumentDataById.document_users.length &&
              nonConstantUser &&
              nonConstantUser.length == 1
            ) {
              if (
                !isCkTemplate &&
                this.getConfigurableDocumentDataById.pages &&
                this.getConfigurableDocumentDataById.pages[0] &&
                this.getConfigurableDocumentDataById.pages[0].fields &&
                this.getConfigurableDocumentDataById.pages[0].fields &&
                !this.getConfigurableDocumentDataById.pages[0].fields.length
              ) {
                this.$confirm("There is no fields. Please add some fields.")
                  .then(() => {
                    this.onEdit(data);
                  })
                  .catch(() => {});
              } else if (
                (this.getConfigurableDocumentDataById.pages &&
                  this.getConfigurableDocumentDataById.pages[0] &&
                  this.getConfigurableDocumentDataById.pages[0].fields &&
                  this.getConfigurableDocumentDataById.pages[0].fields &&
                  this.getConfigurableDocumentDataById.pages[0].fields
                    .length) ||
                isCkTemplate
              ) {
                let checkFilledByUses = isCkTemplate
                  ? this.getConfigurableDocumentDataById.ckeditor_fields
                  : this.getConfigurableDocumentDataById.pages[0].fields;
                let signUsers = [];
                let userCount = 0;
                signUsers = checkFilledByUses.flatMap(
                  (element) => element.filled_by
                );
                if (signUsers && signUsers.length) {
                  userCount =
                    this.getConfigurableDocumentDataById.document_users.filter(
                      (user) => {
                        if (
                          user.user_type == "SIGNER" &&
                          user.type != "SENDER" &&
                          signUsers.indexOf(user.value) == -1
                        ) {
                          return user;
                        }
                      }
                    );
                  if (userCount.length) {
                    this.$confirm(
                      "Every signing user must have at least one fillable field."
                    )
                      .then(() => {
                        this.onEdit(data);
                      })
                      .catch(() => {});
                  } else {
                    this.selectedConfigurable =
                      this.getConfigurableDocumentDataById;
                    this.getLinkModal = true;
                  }
                } else {
                  this.$confirm(
                    "Every signing user must have at least one fillable field."
                  )
                    .then(() => {
                      this.onEdit(data);
                    })
                    .catch(() => {});
                }
              }
            } else {
              this.$confirm("There must be only one recipient")
                .then(() => {
                  this.onEdit(data);
                })
                .catch(() => {});
            }
          }
        }
      }
    },
    closeAndSendDocuments() {
      this.documentSettingsVisibleCheckForBulkSendAndTOSendToContacts = false;
      if (this.isBulkSend) {
        this.sendDocumentToUsers();
      } else {
        this.sendTemplateToContacts();
      }
    },
    editSuccessCSVData(row) {
      this.currentActiveSuccessRowIndex = row.id;
    },
    resetSuccessCSVData() {
      let checkError = this.checkSuccessData();
      if (!checkError) {
        this.successfullCSVData.splice(this.currentActiveSuccessRowIndex, 1);
        this.rearrangeTheErrorCSVData();
      }
      this.currentActiveSuccessRowIndex = -1;
      // this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);
      this.totalForBS = this.successfullCSVData.length;
      this.totalForBSError = this.errorfullCSVData.length;
    },
    checkSuccessData() {
      var keys = Object.keys(
        this.successfullCSVData[this.currentActiveSuccessRowIndex]
      );

      let flag = true;
      let not_sender = true;
      let is_filled = false;
      let email_keys = keys.filter((key) => {
        if (key.split("::")[1] == "email") {
          return key;
        }
      });
      email_keys.forEach((key) => {
        if (this.successfullCSVData[this.currentActiveSuccessRowIndex][key]) {
          is_filled = true;
          let re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            this.successfullCSVData[this.currentActiveSuccessRowIndex][key] &&
            !re.test(
              this.successfullCSVData[this.currentActiveSuccessRowIndex][key]
            )
          ) {
            flag = false;
          } else if (
            this.successfullCSVData[this.currentActiveSuccessRowIndex][key] ==
            this.getAuthenticatedUser.email
          ) {
            not_sender = false;
          } else {
            let firstName_key = key.split("::")[0];
            let lastName_key = key.split("::")[0];
            firstName_key += "::first_name";
            lastName_key += "::last_name";
            if (
              !this.successfullCSVData[this.currentActiveSuccessRowIndex][
                firstName_key
              ] ||
              (this.successfullCSVData[this.currentActiveSuccessRowIndex][
                firstName_key
              ] &&
                this.successfullCSVData[this.currentActiveSuccessRowIndex][
                  firstName_key
                ].length < 1) ||
              !this.successfullCSVData[this.currentActiveSuccessRowIndex][
                lastName_key
              ]
            ) {
              flag = false;
            }
          }
        } else {
          flag = false;
        }
      });
      if (!flag || !not_sender || !is_filled) {
        this.errorfullCSVData.push(
          this.successfullCSVData[this.currentActiveSuccessRowIndex]
        );
      }
      return flag;
    },
    editErrorCSVData(row) {
      this.currentActiveRowIndex = row.id;
    },
    resetErrorCSVData() {
      let checkError = this.checkErrorCount();
      if (checkError) {
        this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);
        this.rearrangeTheErrorCSVData();
      }
      this.currentActiveRowIndex = -1;
      // this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);
      this.totalForBS = this.successfullCSVData.length;
      this.totalForBSError = this.errorfullCSVData.length;
    },
    rearrangeTheErrorCSVData() {
      this.errorfullCSVData.map((row, index) => {
        row["id"] = index;
      });
      this.successfullCSVData.map((row, index) => {
        row["id"] = index;
      });
    },
    checkErrorCount() {
      var keys = Object.keys(this.errorfullCSVData[this.currentActiveRowIndex]);
      let flag = true;
      let not_sender = true;
      let is_filled = false;
      let email_keys = keys.filter((key) => {
        if (key.split("::")[1] == "email") {
          return key;
        }
      });
      email_keys.forEach((key) => {
        if (this.errorfullCSVData[this.currentActiveRowIndex][key]) {
          is_filled = true;
          let re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            this.errorfullCSVData[this.currentActiveRowIndex][key] &&
            !re.test(this.errorfullCSVData[this.currentActiveRowIndex][key])
          ) {
            flag = false;
            this.$message.error(
              "Please check email there is an error in email format"
            );
          } else if (
            this.errorfullCSVData[this.currentActiveRowIndex][key] ==
            this.getAuthenticatedUser.email
          ) {
            not_sender = false;
          } else {
            let firstName_key = key.split("::")[0];
            let lastName_key = key.split("::")[0];
            firstName_key += "::first_name";
            lastName_key += "::last_name";
            if (
              !this.errorfullCSVData[this.currentActiveRowIndex][
                firstName_key
              ] ||
              (this.errorfullCSVData[this.currentActiveRowIndex][
                firstName_key
              ] &&
                this.errorfullCSVData[this.currentActiveRowIndex][firstName_key]
                  .length < 1) ||
              !this.errorfullCSVData[this.currentActiveRowIndex][lastName_key]
            ) {
              flag = false;
            }
          }
        } else {
          flag = false;
        }
        // if (
        //   !this.errorfullCSVData[this.currentActiveRowIndex][key] ||
        //   (this.errorfullCSVData[this.currentActiveRowIndex][key] &&
        //     this.errorfullCSVData[this.currentActiveRowIndex][key].length < 3)
        // ) {
        //   flag = false;
        // }
      });
      if (flag && not_sender && is_filled) {
        this.successfullCSVData.push(
          this.errorfullCSVData[this.currentActiveRowIndex]
        );
      }
      return flag;
    },
    goBack() {
      this.showTemplateDataView = false;
    },
    resetHistory() {
      this.viewDetailedHistory = false;
      this.selectedHistroyDocuments.created_at = null;
      this.selectedHistroyDocuments.DocId = null;
      this.selectedHistroyDocuments.Docname = "";
      this.selectedHistroyDocuments.senderName = "";
      this.selectedHistroyDocuments.Doc_recipients = [];
      this.selectedHistroyDocuments.Document_Status = "";
      this.selectedHistroyDocuments.DocumentId = "";
      this.selectedHistroyDocuments.DateCreated = "";
      this.selectedHistroyDocuments.documentIdforHistory = "";
      this.selectedHistroyDocuments.checkDocumentStatus = false;
    },
    async resetShareWithUsers() {
      this.searchedContact = "";
      this.templateShareModal = false;
    },
    resetBulkModal() {
      if (!this.isBulkSendIsProccessing) {
        this.resetCSVData();
        this.selectedCofigurable = null;
        this.selectedCompanyDocument = null;
        this.activeStep = 0;
        this.activeNameForBulkSend = "success";
        this.activeHeadingNames = [];
        this.getBulkSendPercentage = 0;
        this.isContactsCreated = false;
        this.contactLoading = false;
        this.bulkSendActionModal = false;
        this.isGenerateCSVFile = false;
        this.primaryHeaders = [];
        this.userPrimaryDetails = [];
        this.currentActiveSuccessRowIndex = -1;
        this.currentActiveRowIndex = -1;
        this.uploadedCSVFile = "";
        this.docSettingsVisible = false;
        this.selectedConfigurableDocumentId = null;
        this.primaryHeads = [];
      }
    },
    resetCSVData() {
      this.successfullCSVData = [];
      this.errorfullCSVData = [];
      this.uploadedCSVDataResult = [];
    },
    format(percentage) {
      let per = percentage === 100 ? "" : `` || percentage != 100 ? "" : ``;
      console.log(per);
      let doc = "";
      return doc;
    },
    generateUniqueKey(label, keys) {
      const formattedLabel = label.toLowerCase().replace(/ /g, "_");
      if (!keys.includes(formattedLabel)) {
        return formattedLabel;
      }
      return keys.find((key) => key === formattedLabel);
    },
    generateCSVFile() {
      if (this.selectedCofigurable) {
        this.primaryHeaders = [];
        this.primaryHeads = [];
        this.selectedCofigurable.document_users.forEach((row) => {
          if (row.type != "SENDER" && !row.constant_user) {
            this.primaryHeads.push(
              row.name + "::first_name",
              row.name + "::last_name",
              row.name + "::email"
            );
            this.userPrimaryDetails.push(
              row.name + "::first_name",
              row.name + "::last_name",
              row.name + "::email"
            );
            this.selectedCofigurable.pages[0].fields.forEach((el) => {
              if (
                el &&
                el.type != "SIGNATURE" &&
                el.type != "INITIAL" &&
                el.type != "my-signature" &&
                el.type != "MY_INITIAL" &&
                el.type != "ATTACHMENT" &&
                el.type != "IMAGE" &&
                el.type != "DATE_SIGNED"
              ) {
                if (
                  (row.value == el.filled_by ||
                    el.filled_by == "SENDER_OR_RECEIVER" ||
                    (row.value == el.selected_user &&
                      el.selected_tag != "first_name" &&
                      el.selected_tag != "last_name" &&
                      el.selected_tag != "email" &&
                      el.selected_tag != "full_name")) &&
                  row.name &&
                  el.key
                ) {
                  const uniqueKey = this.generateUniqueKey(
                    el.label,
                    Object.keys(this.mapped_entity_fields)
                  );
                  this.mapped_entity_fields[uniqueKey] = el.key;
                  if (
                    el.field_type == "ENTITY_VARIABLE" ||
                    el.field_type == "ENTITY" ||
                    el.field_type == "ENTITY_FIELD"
                  ) {
                    this.primaryHeads.push(row.name + "::" + uniqueKey);
                  } else {
                    this.primaryHeads.push(row.name + "::" + el.key);
                  }
                }
              }
            });
          } else {
            this.selectedCofigurable.pages[0].fields.forEach((el) => {
              if (
                el &&
                el.type != "SIGNATURE" &&
                el.type != "INITIAL" &&
                el.type != "my-signature" &&
                el.type != "MY_INITIAL" &&
                el.type != "ATTACHMENT" &&
                el.type != "IMAGE" &&
                el.type != "DATE_SIGNED"
              ) {
                if (
                  (row.value == el.filled_by ||
                    el.filled_by == "SENDER_OR_RECEIVER" ||
                    (row.value == el.selected_user &&
                      el.selected_tag != "first_name" &&
                      el.selected_tag != "last_name" &&
                      el.selected_tag != "email")) &&
                  row.name &&
                  el.key
                ) {
                  const uniqueKey = this.generateUniqueKey(
                    el.label,
                    Object.keys(this.mapped_entity_fields)
                  );
                  this.mapped_entity_fields[uniqueKey] = el.key;
                  let userName = row.name.toLowerCase();
                  if (
                    el.field_type == "ENTITY_VARIABLE" ||
                    el.field_type == "ENTITY" ||
                    el.field_type == "ENTITY_FIELD"
                  ) {
                    this.primaryHeads.push(userName + "::" + uniqueKey);
                  } else {
                    this.primaryHeads.push(userName + "::" + el.key);
                  }
                }
              }
            });
          }
        });
        this.primaryHeads.push("email_message");
        this.primaryHeaders = this.primaryHeads;
        if (!this.isGenerateCSVFile) {
          this.downloadCSVFile(this.primaryHeads.join(","));
        }
        this.isGenerateCSVFile = false;
      }
    },
    downloadCSVFile(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `${this.selectedTemplate.title}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    uploadBulkSendCSV(file) {
      if (
        file.raw.type == "application/vnd.ms-excel" ||
        file.raw.type == "text/csv"
      ) {
        this.uploadedCSVFile = file.raw.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.divideString(e.target.result);
        };
        reader.readAsText(file.raw);
      } else {
        this.$message.error("Oops, Please upload a .CSV file.");
      }
    },
    async divideString(e) {
      await this.$store.dispatch("contacts/fetchAllContacts", {
        get_all: true,
        type: "CONTACT",
      });
      let dividedString = e.split(/\r?\n/);
      if (dividedString && dividedString.length <= 1) {
        this.$message.error("Oops, Uploaded CSV file is empty.");
      } else {
        const headers = dividedString[0].split(",");
        let differentFields = this.userPrimaryDetails.filter(
          (x) => headers.indexOf(x) === -1
        );
        if (differentFields && differentFields.length) {
          this.$message.error(
            "Oops, Uploaded CSV file is not matched with required CSV."
          );
        } else {
          this.isCSVUploaded = true;
          for (let i = 1; i < dividedString.length; i++) {
            let obj = {};
            let str = dividedString[i];
            if (dividedString[i] && dividedString[i].length) {
              const regex = /[\r\n]+/g;
              let cleanCsvData = str.replace(regex, "");
              let newdatata = cleanCsvData.replace(/"/g, "");

              let properties = newdatata.split(",");
              for (let j in headers) {
                if (properties[j] && properties[j].includes(",")) {
                  obj[headers[j]] = properties[j]
                    .split(",")
                    .map((item) => item.trim());
                } else {
                  obj[headers[j]] = properties[j];
                }
              }
              this.uploadedCSVDataResult.push(obj);
            }
          }
        }
        this.separateSuccessAndErrors();
      }
      // let result = [];
    },
    async separateSuccessAndErrors() {
      let contactEmails = [];
      if (this.getAllContacts && this.getAllContacts.data) {
        contactEmails = this.getAllContacts.data.flatMap((e) => e.email);
      }
      let nonSenderUsers = this.selectedCofigurable.document_users
        .filter((user) => user.type != "SENDER" && user.constant_user)
        .flatMap((el) => el.email);
      this.uploadedCSVDataResult.map((row) => {
        let flag = true;
        let not_sender = true;
        let is_allow = true;
        var keys = Object.keys(row);
        let is_filled = false;
        let email_keys = keys.filter((key) => {
          if (key.split("::")[1] === "email") {
            return key;
          }
        });
        email_keys.forEach((key) => {
          if (row[key]) {
            is_filled = true;
            row[key] = row[key].toLowerCase();
            let re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (row[key] && !re.test(row[key])) {
              flag = false;
              this.$notify.error({
                title: "Error",
                message: "Error in email. Please check email.!",
              });
              this.$message.error(
                "Please check email there is an error in email format"
              );
            } else if (row[key] == this.getAuthenticatedUser.email) {
              not_sender = false;
            } else if (nonSenderUsers.indexOf(row[key]) != -1) {
              is_allow = false;
            } else {
              if (contactEmails.indexOf(row[key]) == -1) {
                let firstName_key = key.split("::")[0];
                let lastName_key = key.split("::")[0];
                firstName_key += "::first_name";
                lastName_key += "::last_name";
                if (
                  !row[firstName_key] ||
                  (row[firstName_key] && row[firstName_key].length < 1) ||
                  !row[lastName_key]
                ) {
                  flag = false;
                }
              } else {
                let existingContact = this.getAllContacts.data.find(
                  (user) => user.email == row[key]
                );
                let firstName_key = key.split("::")[0];
                let lastName_key = key.split("::")[0];
                let newFirstName_key = firstName_key + "::first_name";
                let newLastName_key = lastName_key + "::last_name";
                if (!row[newFirstName_key] || !row[newLastName_key]) {
                  row[newFirstName_key] = existingContact.first_name;
                  row[newLastName_key] = existingContact.last_name;
                }
                this.primaryHeads.forEach((k) => {
                  if (k.split("::")[1] != "email") {
                    if (
                      k.split("::")[0] == firstName_key &&
                      k.split("::").pop().split("_")[0] == "company" &&
                      !row[k]
                    ) {
                      row[k] = existingContact.company_name;
                    }
                    if (
                      k.split("::")[0] == firstName_key &&
                      k.split("::").pop().split("_")[0] == "phone" &&
                      !row[k]
                    ) {
                      row[k] = existingContact.phone_number;
                    }
                    if (
                      k.split("::")[0] == firstName_key &&
                      k.split("::").pop().split("_")[0] == "title" &&
                      !row[k]
                    ) {
                      row[k] = existingContact.title;
                    }
                    if (
                      k.split("::")[0] == firstName_key &&
                      k.split("::").pop().split("_")[0] == "address" &&
                      !row[k]
                    ) {
                      row[k] = existingContact.address;
                    }
                  }
                });
              }
            }
          } else {
            flag = false;
            // this.$message.error(
            //   "Please add atleast one receiver email to bulk send."
            // );
          }
        });
        if (flag && not_sender && is_filled && is_allow) {
          this.successfullCSVData.push(row);
        } else if (not_sender && !flag && is_filled && is_allow) {
          this.errorfullCSVData.push(row);
        } else if (!not_sender) {
          this.$message.error("oops, You have entered sender email");
        }
      });
      if (
        this.successfullCSVData &&
        !this.successfullCSVData.length &&
        this.errorfullCSVData.length
      ) {
        this.$message.error(
          "There are some errors in import. Please correct the errors to complete upload"
        );
      }
      if (this.errorfullCSVData && this.errorfullCSVData.length) {
        this.rearrangeTheErrorCSVData();
      }
      this.successfullCSVData.map((row, index) => {
        row["id"] = index;
      });
      this.totalForBS = this.successfullCSVData.length;
      this.totalForBSError = this.errorfullCSVData.length;
    },
    async getConfigurableDocumentSettings() {
      try {
        let params = {
          id: this.selectedCofigurable._id,
        };
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentExpirationSettignsByID",
          params
        );
      } catch (err) {
        console.log("getConfigurableDocumentSettings", err);
      }
    },
    getWaitingUser(doc) {
      if (doc.enforce_signature_order) {
        let currentUser = "";
        doc.document_users.forEach((user) => {
          if (
            (user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status &&
              user.has_approval_access &&
              !user.approval_status)
          ) {
            currentUser = user.first_name;
          }
        });
        let result = "Waiting for " + currentUser;
        return result;
      } else {
        let currentUser = "";
        doc.document_users.find((user) => {
          if (
            (user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status &&
              user.has_approval_access &&
              !user.approval_status)
          ) {
            currentUser += user.first_name + ",";
          }
        });
        if (currentUser.charAt(currentUser.length - 1) == ",") {
          currentUser = currentUser.slice(0, currentUser.length - 1);
        }
        let result = "Waiting for " + currentUser + " " + "Signatures";
        return result;
      }
    },

    getDocumentTitle(title) {
      if (title.length > 30) {
        return title.slice(0, 30) + "...";
      }
      return title;
    },
    prepareDocumentData() {
      let data = {};
      this.selectedCofigurable.pages[0].fields.forEach((element) => {
        if (element.type == "HEADING" || element.type == "PARAGRAPH") {
          data[element.selected_tag] = element.content;
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else if (element.content) {
          data[element.key] = element.content;
        } else {
          data[element.key] = "";
        }
      });
      return data;
    },
    getDocumentUsers(index) {
      let data = [];
      let newusers = [];
      for (let i = 0; i < this.selectedCofigurable.document_users.length; i++) {
        newusers.push(this.selectedCofigurable.document_users[i]);
      }
      newusers.forEach((user) => {
        if (user.type != "SENDER") {
          let newObj = {};
          newObj = { ...newObj, ...user };
          var keys = Object.keys(this.successfullCSVData[index]);
          keys.forEach((key) => {
            if (key.split("::")[0] == user.name) {
              if (key.split("::")[1] == "first_name") {
                newObj["first_name"] = this.successfullCSVData[index][key];
              }
              if (key.split("::")[1] == "last_name") {
                newObj["last_name"] = this.successfullCSVData[index][key];
              }
              if (key.split("::")[1] == "email") {
                newObj["email"] = this.successfullCSVData[index][key];
              }
              if (key.split("::").pop().split("_")[0] == "address") {
                newObj["address"] = this.successfullCSVData[index][key];
              }
              if (key.split("::").pop().split("_")[0] == "company") {
                newObj["company_name"] = this.successfullCSVData[index][key];
              }
              if (key.split("::").pop().split("_")[0] == "phone") {
                newObj["phone"] = this.successfullCSVData[index][key];
              }
              if (key.split("::").pop().split("_")[0] == "title") {
                newObj["title"] = this.successfullCSVData[index][key];
              }
            }
          });
          data.push(newObj);
        } else {
          newusers.map((el) => {
            if (el && el.type == "SENDER") {
              (user.first_name = this.getAuthenticatedUser.first_name),
                (user.last_name = this.getAuthenticatedUser.last_name),
                (user.email = this.getAuthenticatedUser.email);
            }
          });
          data.push(user);
        }
      });
      newusers = [];
      return data;
    },
    modalToCheck() {
      this.visibleDialog = true;
      this.sentContactsModal = true;
    },
    closeSendToContactsAlert() {
      this.visibleDialog = false;
    },
    async checkSettingsVisibleOrNot() {
      try {
        this.bulkSendBtnLoding = true;
        this.visibleDialog = false;
        await this.$store.dispatch("settings/fetchApplicationSettings");
        if (this.getApplicationSettings && this.getApplicationSettings.data) {
          if (this.getApplicationSettings.data.document_signature) {
            this.senderFilledAllFields = this.getApplicationSettings.data
              .document_signature.allow_sender_auto_fill
              ? this.getApplicationSettings.data.document_signature
                  .allow_sender_auto_fill
              : false;
            if (
              this.getApplicationSettings.data.document_signature
                .show_document_settings_when_sent
            ) {
              this.documentSettingsVisibleCheckForBulkSendAndTOSendToContacts = true;
            } else {
              if (this.isBulkSend) {
                this.uploadedCSVDataResult = this.uploadedCSVDataResult.map(
                  (dataObject) => {
                    const newDataObject = {};
                    for (const key in dataObject) {
                      const parts = key.split("::");
                      if (
                        parts.length === 2 &&
                        parts[1] &&
                        this.mapped_entity_fields[parts[1]]
                      ) {
                        newDataObject[
                          parts[0] + "::" + this.mapped_entity_fields[parts[1]]
                        ] = dataObject[key];
                      } else {
                        newDataObject[key] = dataObject[key];
                      }
                    }
                    return newDataObject;
                  }
                );

                // Resulting array with replaced keys
                this.sendDocumentToUsers();
              } else {
                this.sentContactsModal = true;
                this.sendTemplateToContacts();
              }
            }
          }
        }
        this.bulkSendBtnLoding = false;
      } catch (err) {
        console.log("checkSettingsVisibleOrNot", err);
      }
    },
    async sendDocumentToUsers() {
      let fields = [];
      this.contactLoading = true;
      this.bulkSendBtnLoding = true;
      this.isBulkSendIsProccessing = true;
      this.activeStep == 3;
      let fieldElements = this.selectedCofigurable.pages[0].fields;
      if (this.sender_auto_fill) {
        fieldElements.map((element) => {
          if (
            element.filled_by == "SENDER" ||
            element.selected_user == "SENDER"
          ) {
            if (element.type == "SIGNATURE" || element.type == "my-signature") {
              if (!element.source) {
                if (
                  this.getAuthenticatedUser &&
                  this.getAuthenticatedUser.signature
                ) {
                  element.source = this.getAuthenticatedUser.signature;
                } else {
                  this.isUserHasSign = false;
                }
              }
            } else if (
              element.type == "MY_INITIAL" ||
              element.type == "INITIAL"
            ) {
              if (!element.value) {
                if (this.getInitialUrl) {
                  element.value = this.getInitialUrl;
                } else {
                  this.isUserHasSign = false;
                }
              }
            } else if (element.type == "DATE_SIGNED") {
              let date = new Date();
              element.value = date;
            }
          }
        });
      }
      let params = {
        update_contacts: this.update_contacts,
        id: this.selectedConfigurableDocumentId,
      };
      await this.$store.dispatch(
        "configurableDocuments/updateConfigurableDocument",
        params
      );
      if (
        this.selectedCofigurable &&
        this.selectedCofigurable.document_users &&
        this.selectedCofigurable.pages &&
        this.selectedCofigurable.pages[0] &&
        this.selectedCofigurable.pages[0].fields
      ) {
        let newusers = [];
        for (
          let i = 0;
          i < this.selectedCofigurable.document_users.length;
          i++
        ) {
          newusers.push(this.selectedCofigurable.document_users[i]);
        }
        newusers.forEach((row) => {
          if (!row.constant_user) {
            let obj = {};
            obj["contact_type"] = row.contact_type;
            obj["fields"] = [];
            this.successfullCSVData.forEach((el) => {
              let sm = {};
              var keys = Object.keys(el);
              keys.forEach((key) => {
                if (key.split("::")[0] == row.name) {
                  if (key.split("::")[1] == "first_name") {
                    sm["first_name"] = el[key];
                  }
                  if (key.split("::")[1] == "last_name") {
                    sm["last_name"] = el[key];
                  }
                  if (key.split("::")[1] == "email") {
                    sm["email"] = el[key];
                  }
                  if (key.split("::").pop().split("_")[0] == "address") {
                    sm["address"] = el[key];
                  }
                  if (key.split("::").pop().split("_")[0] == "company") {
                    sm["company_name"] = el[key];
                  }
                  if (key.split("::").pop().split("_")[0] == "phone") {
                    sm["phone_number"] = el[key];
                  }
                  if (key.split("::").pop().split("_")[0] == "title") {
                    sm["title"] = el[key];
                  }
                }
              });
              obj["fields"].push(sm);
            });
            fields.push(obj);
          }
        });
      }
      let data = {
        newUsers: fields,
      };
      await this.$store.dispatch("documents/bulkSend", data);
      if (this.getCreateBulkContactsStatus) {
        this.contactLoading = false;
        this.isContactsCreated = true;
        this.bulkSendBtnLoding = false;
        this.bulkSendTemplate();
      } else {
        this.this.contactLoading = false;
        this.isContactsCreated = false;
        this.bulkSendBtnLoding = false;
        this.isBulkSendIsProccessing = false;
      }
    },
    setEmployeDataToForm() {
      this.documentDataLoading = true;
      for (let index = 0; index < this.elements.length; index++) {
        let field = this.elements[index];
        const documentValue = this.getValueFromSavedDocument(field.key);
        if (
          (this.elements[index].type == "SIGNATURE" ||
            this.elements[index].type == "INITIAL") &&
          documentValue
        ) {
          this.elements[index].source = documentValue;
          this.$set(this.elements[index], "source", documentValue);
        }
        if (
          this.elements[index].type == "PRIMARY_FIELDS" &&
          !this.elements[index].content
        ) {
          this.$set(this.elements[index], "content", documentValue);
        }
        if (documentValue) {
          this.$set(this.elements[index], "value", documentValue);
        }
      }

      if (this.employeeDocumentId && this.getSingleDocumentData.data) {
        this.userDocumentFormData.e_signature_required =
          this.getSingleDocumentData.data.e_signature_required;
        this.userDocumentFormData.e_signature_status =
          this.getSingleDocumentData.data.e_signature_status;
        this.userDocumentFormData.expires_at =
          this.getSingleDocumentData.data.expires_at ||
          this.$moment().endOf("day").toString();
        // this.userDocumentFormData.valid_from = this.getSingleDocumentData.data.valid_from || this.$moment().startOf('day').toString();
        this.userDocumentFormData.e_signature_value = this.getSingleDocumentData
          .data.e_signature_required
          ? "YES"
          : "NO";

        this.documentUsers = this.getSingleDocumentData.data.document_users;
        this.elements = this.elements.map((element) => {
          if (
            element.type == "PRIMARY_FIELDS" &&
            element.selected_tag == "full_name"
          ) {
            element.content = this.getFieldUserVal(element.selected_user);
          }
          return element;
        });
      }
      this.documentDataLoading = false;
    },
    async bulkSendTemplate() {
      try {
        this.completedPercentage = 0;

        this.successfullCSVData.forEach(async (element, index) => {
          this.selectedCofigurable.pages[0].fields.map((el) => {
            if (
              (el && el.type == "SINGLE_LINE_TEXT") ||
              el.type == "MULTI_LINE_TEXT" ||
              el.type == "NUMBER"
            ) {
              if (el.key) {
                this.primaryHeaders.forEach((head) => {
                  if (el.key == head.split("::")[1] && element[head]) {
                    el.value = element[head];
                  }
                });
              }
            } else if (el && el.type && el.type == "CHECKBOX") {
              if (el.key) {
                this.primaryHeaders.forEach((head) => {
                  if (el.key == head.split("::")[1]) {
                    let values = ["true", "false"];
                    if (
                      element[head] &&
                      values.indexOf(element[head].toLowerCase()) != -1
                    ) {
                      if (element[head].toLowerCase() == "false") {
                        el.value = false;
                      } else {
                        el.value = true;
                      }
                    }
                  }
                });
              }
            } else if (el && el.type && el.type == "YES_OR_NO") {
              if (el.key) {
                this.primaryHeaders.forEach((head) => {
                  if (el.key == head.split("::")[1]) {
                    let values = ["yes", "no"];
                    if (
                      element[head] &&
                      values.indexOf(element[head].toLowerCase()) != -1
                    ) {
                      el.value = element[head].toLowerCase();
                    }
                  }
                });
              }
            } else if (el && el.type && el.type == "SELECT") {
              if (el.key) {
                this.primaryHeaders.forEach((head) => {
                  if (el && el.options && el.key == head.split("::")[1]) {
                    const values = el.options.map((name) => name.toLowerCase());
                    el.options = values;
                    if (
                      element[head] &&
                      values.indexOf(element[head].toLowerCase()) != -1
                    ) {
                      el.value = element[head].toLowerCase();
                    }
                  }
                });
              }
            } else if (el && el.type && el.type == "MULTI_SELECT") {
              if (el.key) {
                this.primaryHeaders.forEach((head) => {
                  if (
                    el &&
                    el.options &&
                    el.key == head.split("::")[1] &&
                    element[head] &&
                    element[head].length
                  ) {
                    const values = el.options.map((name) => name.toLowerCase());
                    let userValues = element[head].split("#");
                    el.options = values;
                    let newValues = this.getCompareArrays(values, userValues);
                    // if(values.indexOf(element[head].toLowerCase()) != -1){
                    el.value = newValues;
                    // }
                  }
                });
              }
            } else if (el && el.type && el.type == "DATE") {
              if (el.key) {
                this.primaryHeaders.forEach((head) => {
                  if (
                    el &&
                    el.options &&
                    el.key == head.split("::")[1] &&
                    element[head] &&
                    element[head].length
                  ) {
                    let dateRegex =
                      /^((0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])[-](19|20)?[0-9]{2})*$/;
                    if (dateRegex.test(element[head])) {
                      el.value = element[head];
                    }
                  }
                });
              }
            } else if (
              el &&
              el.type &&
              el.type == "PRIMARY_FIELDS" &&
              el.selected_tag != "full_name"
            ) {
              if (el.key) {
                this.primaryHeaders.forEach((head) => {
                  let userName = this.getSelectedUserName(el.selected_user);
                  if (
                    userName.name == head.split("::")[0] &&
                    (head.split("::").pop().split("_")[0] == el.selected_tag ||
                      (head.split("::").pop().split("_")[0] == "company" &&
                        el.selected_tag == "company_name") ||
                      head.split("::")[1] == el.selected_tag)
                  ) {
                    if (element[head] && element[head].length) {
                      el.content = element[head];
                    } else {
                      el.content = "";
                    }
                  }
                });
              }
            }
          });
          let documentData = this.prepareDocumentData();
          let documentsUsers = this.getDocumentUsers(index);
          let timeZone = this.getTimeZone();
          let params = {
            document_template_id: this.selectedTemplate._id,
            document_users: documentsUsers,
            email_data: this.prepareEmailData(element.email_message),
            document_data: documentData,
            sender_time_zone: timeZone,
            is_anyone_can_approve:
              this.selectedCofigurable.is_anyone_can_approve,
            enforce_signature_order:
              this.selectedCofigurable.enforce_signature_order,
            configurable_document_id: this.selectedCofigurable._id,
            senderFilledAllFields: this.senderFilledAllFields,
            isFromTemplates: true,
          };
          await this.$store.dispatch("documents/createSentDocument", params);
          await this.resetFields();
          if (this.getDraftDocumentCreateStatus) {
            this.completedPercentage = this.completedPercentage + 1;
            this.getBulkSendPercentage =
              (this.completedPercentage / this.successfullCSVData.length) * 100;
            if (this.getBulkSendPercentage == 100) {
              this.isBulkSendIsProccessing = false;
            }
          }
        });
      } catch (err) {
        console.log("bulkSendTemplate", err);
      }
    },
    resetFields() {
      this.selectedCofigurable.pages[0].fields.map((el) => {
        el.value = "";
        el.content = "";
        el.source = "";
      });
    },
    getSelectedUserName(selectedUser) {
      let name = null;
      name = this.selectedCofigurable.document_users.find(
        (user) => user.value == selectedUser
      );
      return name;
    },
    getCompareArrays(a, b) {
      let data = [];
      a.forEach((i) => {
        b.forEach((j) => {
          if (i.toLowerCase() == j.toLowerCase()) {
            data.push(i);
          }
        });
      });
      return data;
    },
    prepareEmailData(dataMail) {
      let emailData = {};
      let mailCCSendTo = [];
      let mailSendTo = [];

      this.selectedCofigurable.document_users.map((user) => {
        if (user.user_type == "CC") {
          mailCCSendTo.push(user.email);
        } else {
          mailSendTo.push(user.email);
        }
        return user;
      });
      let users = [...this.selectedCofigurable.document_users];
      let usersEmail = users.map((user) => user.email);
      emailData["signers"] = mailSendTo.map((user) => {
        let data = {};
        let userIndex = usersEmail.indexOf(user);
        if (userIndex != -1) {
          data["email"] = user;
          data["user_id"] = users[userIndex]._id;
        } else {
          data["email"] = user;
          data["user_id"] = "";
        }
        return data;
      });

      emailData["to_cc"] = mailCCSendTo.map((user) => {
        let data = {};
        let userIndex = usersEmail.indexOf(user);
        if (userIndex != -1) {
          data["email"] = user;
          data["user_id"] = users[userIndex]._id;
        } else {
          data["email"] = user;
          data["user_id"] = "";
        }

        return data;
      });
      emailData["subject"] = this.mailSubject;
      emailData["data"] = this.mailContent ? this.mailContent : dataMail;
      return emailData;
    },
    async bulkSend(data) {
      this.selectedTemplate = data;
      if (data && data.configurable_document_id) {
        let configurable_document_id = data.configurable_document_id;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: configurable_document_id,
          }
        );
        if (this.getConfigurableDocumentDataById) {
          let emptyUser = null;
          if (
            this.getConfigurableDocumentDataById &&
            this.getConfigurableDocumentDataById.document_users &&
            this.getConfigurableDocumentDataById.document_users.length
          ) {
            emptyUser =
              this.getConfigurableDocumentDataById.document_users.find(
                (user) => {
                  if (user && user.type != "SENDER" && !user.contact_type) {
                    return user;
                  }
                }
              );
          }

          if (
            this.getConfigurableDocumentDataById &&
            this.getConfigurableDocumentDataById.document_users &&
            this.getConfigurableDocumentDataById.document_users.length &&
            this.getConfigurableDocumentDataById.document_users.length == 1
          ) {
            this.$confirm(
              "There is no recipients. Please add atleast one recipient."
            )
              .then(() => {
                this.onEdit(data);
              })
              .catch(() => {});
          } else if (emptyUser && emptyUser.name) {
            this.$confirm(
              `Recipient ${emptyUser.name} don't have contact-type. Please select.`
            )
              .then(() => {
                this.onEdit(data);
              })
              .catch(() => {});
          } else if (
            this.getConfigurableDocumentDataById &&
            this.getConfigurableDocumentDataById.document_users &&
            this.getConfigurableDocumentDataById.document_users.length &&
            this.getConfigurableDocumentDataById.document_users.length > 1
          ) {
            if (
              this.getConfigurableDocumentDataById.pages &&
              this.getConfigurableDocumentDataById.pages[0] &&
              this.getConfigurableDocumentDataById.pages[0].fields &&
              this.getConfigurableDocumentDataById.pages[0].fields &&
              !this.getConfigurableDocumentDataById.pages[0].fields.length
            ) {
              this.$confirm("There is no fields. Please add some fields.")
                .then(() => {
                  this.onEdit(data);
                })
                .catch(() => {});
            } else if (
              this.getConfigurableDocumentDataById.pages &&
              this.getConfigurableDocumentDataById.pages[0] &&
              this.getConfigurableDocumentDataById.pages[0].fields &&
              this.getConfigurableDocumentDataById.pages[0].fields &&
              this.getConfigurableDocumentDataById.pages[0].fields.length
            ) {
              let signUsers = [];
              let userCount = 0;
              signUsers =
                this.getConfigurableDocumentDataById.pages[0].fields.flatMap(
                  (element) => element.filled_by
                );
              if (signUsers && signUsers.length) {
                // let senderSign = [];
                // senderSign =
                //   this.getConfigurableDocumentDataById.pages[0].fields.filter(
                //     (element) => element.type == "my-signature"
                //   );
                userCount =
                  this.getConfigurableDocumentDataById.document_users.filter(
                    (user) => {
                      if (
                        user.user_type == "SIGNER" &&
                        user.type != "SENDER" &&
                        signUsers.indexOf(user.value) == -1
                      ) {
                        return user;
                      }
                      //else if (
                      //   user.type == "SENDER" &&
                      //   user.user_type == "SIGNER"
                      // ) {
                      //   if (!senderSign.length) {
                      //     if (signUsers.indexOf(user.value) == -1) {
                      //       return user;
                      //     }
                      //   }
                      // }
                    }
                  );
                if (userCount.length) {
                  this.$confirm(
                    "Every signing user must have at least one fillable field."
                  )
                    .then(() => {
                      this.onEdit(data);
                    })
                    .catch(() => {});
                } else {
                  this.selectedCofigurable =
                    this.getConfigurableDocumentDataById;
                  this.update_contacts =
                    this.selectedCofigurable.update_contacts;
                  this.isGenerateCSVFile = true;
                  this.bulkSendActionModal = true;
                  this.selectedConfigurableDocumentId =
                    configurable_document_id;
                  this.generateCSVFile();
                }
              } else {
                this.$confirm(
                  "Every signing user must have at least one fillable field."
                )
                  .then(() => {
                    this.onEdit(data);
                  })
                  .catch(() => {});
              }
            }
          }
        }
      }
    },
    selectAllContactsTypes() {
      this.selectedContactTypes = [];
      this.selectedUsers = [];
      if (this.selectAllContactTypes) {
        this.contactTypes.forEach((user) => {
          this.selectedContactTypes.push(user.name);
        });
        this.contactUsers.forEach((user) => {
          this.selectedUsers.push(user.email);
        });

        this.selectAllContacts = true;
      } else {
        this.selectAllContacts = false;
        this.selectedContactTypes = [];
        this.selectedUsers = [];
      }
    },
    selectAllEmails() {
      this.selectedUsers = [];
      this.selectedContactTypes = [];
      if (this.selectAllContacts) {
        this.contactUsers.forEach((user) => {
          this.selectedUsers.push(user.email);
        });
      } else {
        this.selectAllContactTypes = false;
        this.selectedUsers = [];
        this.selectedContactTypes = [];
      }
    },
    searchContact(event) {
      this.eventLength = event;
      if (this.activeName == "contacts") {
        if (this.searchedContact) {
          this.searchedContacts = this.contactUsers.filter((el) =>
            el.email.includes(event.toLowerCase())
          );
        }
      } else {
        if (this.searchedContact) {
          this.searchedContactTypes = this.contactTypes.filter((el) =>
            el.name.includes(event)
          );
        }
      }
    },
    async goToAddContacts() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({ path: "/contacts/add", query });
    },
    gotoDashboard() {
      this.$router.push({
        path: "/dashboard",
      });
    },
    getSharedUser(data) {
      let email = "";
      if (
        data &&
        data.created_by &&
        data.created_by.first_name &&
        data.created_by.last_name
      ) {
        email = data.created_by.first_name + " " + data.created_by.last_name;
      }
      return email;
    },
    async shareTemplateToContacts() {
      this.fetchContacts();
      if (
        !this.eventLength ||
        this.eventLength.length == 0 ||
        (this.searchedContacts.length > 0 && this.eventLength.length >= 1)
      ) {
        let params = {
          contact_users: this.selectedUsers,
          companyDocumentId: this.selectedTemplateId,
          TemplateTitle: this.template_title,
        };
        await this.$store.dispatch(
          "templates/shareTemplatesToContactUsers",
          params
        );
        if (this.getShareTemplateStatus) {
          this.successNotify("Template shared users updated successfully");
          this.templateShareModal = false;
          this.searchedContact = "";
          this.currentExistedUsers = [];
          await this.fetchTemplates();
        }
      } else {
        if (
          (this.eventLength &&
            this.eventLength.length &&
            (this.eventLength.length != 0 || this.eventLength.length >= 1) &&
            this.searchedContacts.length === 0) ||
          !this.searchedContacts.length
        ) {
          this.$message.error("No results found with this email.!!");
        }
      }
    },
    selectContactTypeContacts() {
      // this.selectedUsers = [...this.selectedUsers, ...this.currentExistedUsers];
      if (this.selectedContactTypes && this.selectedContactTypes.length) {
        this.contactUsers.forEach((element) => {
          let contactTypes = [];
          if (element?.contact_types) {
            element?.contact_types.forEach((e) => {
              if (e?.contact_type?.name) {
                contactTypes.push(e.contact_type.name);
              }
            });
          } else if (element?.contact_type?.name) {
            contactTypes.push(element.contact_type.name);
          }
          let foundIndex = (contactTypes || []).findIndex((el) => {
            return this.selectedContactTypes.indexOf(el) != -1;
          });
          if (foundIndex != -1) {
            if (this.selectedUsers.indexOf(element.email) == -1) {
              this.selectedUsers.push(element.email);
            }
          } else {
            let index = this.selectedUsers.findIndex((e) => e == element.email);
            if (index != -1) {
              this.selectedUsers.splice(index, 1);
            }
          }
        });
      } else {
        this.selectedUsers = [];
      }
    },
    changetab(event) {
      console.log(event);
    },
    async fetchContactTypes() {
      let params = { get_all: true };
      await this.$store.dispatch("contactTypes/fetchContactTypes", params);
      if (this.getAllContactTypes) {
        this.contactTypes = this.getAllContactTypes.data;
      }
    },
    refreshDocument() {
      window.location.reload();
    },
    async alertvoided(doc) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to Void the document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.DocumentVoid(doc);
        });
      }
    },
    async DocumentVoid(doc) {
      let document_id = doc._id;
      await this.$store.dispatch("documents/voidDocument", { document_id });
      if (this.getDocumentVoidStatus) {
        this.$notify.success({
          title: "Success",
          message: "Document voided successfully",
        });
        this.$emit("reloadDashboardStats", true);
        this.refreshDocument();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in void document",
        });
      }
    },
    async archiveDocument(doc) {
      let document_id = doc._id;
      this.$confirm("Are you sure to delete the document?")
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("documents/archiveDocument", {
            document_id,
          });
          this.loading = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document deleted successfully");
            this.$emit("reloadDashboardStats", true);
            //this.fetchDocuments();
            this.refreshDocument();
          } else {
            errorNotification("Error in deleting document");
          }
        })
        .catch(() => {});
    },

    async getAllDocLogs(doc) {
      let document_id = doc._id;
      await this.$store.dispatch("documents/fetchDocumentDataById", {
        document_id,
      });
      this.checkDocumentStatusForCert = doc.document_status;
      if (doc.document_status === "COMPLETED") {
        // let lastPart = doc.document_completed_url.split("/");
        this.documentCompletedID = this.documentIdforHistory;
      }
      await this.$store.dispatch("documents/fetchDocumentLogsById", {
        document_id,
      });
      if (this.getDocumentLogs && this.getDocumentLogs.data) {
        this.documentLogs = [];
        this.documentLogs = this.getDocumentLogs.data;
      }
      this.viewDetailedHistory = true;
      let senderName = doc.document_users.find((e) => {
        if (e && e.type == "SENDER") {
          return e;
        }
      });

      this.selectedHistroyDocuments.Docname = doc.name;
      0;
      this.selectedHistroyDocuments.senderName =
        senderName.first_name + " " + senderName.last_name;
      this.selectedHistroyDocuments.Document_Status = doc.document_status;
      this.selectedHistroyDocuments.created_at = doc.created_at;
      this.selectedHistroyDocuments.Doc_recipients = doc.document_users;
      this.selectedHistroyDocuments.documentIdforHistory =
        this.documentIdforHistory;
    },
    async alertCorrectedDoc(doc) {
      if (!this.checkPermissionForDocument(doc, "editDraft")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied. Please contact owner for edit draft permission to correct document.",
        });
      } else {
        if (confirm) {
          this.$confirm(
            "Are you sure you want to correct the document?",
            "Warning",
            {
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              type: "warning",
            }
          ).then(() => {
            this.correctDocument(doc);
          });
        }
      }
    },
    async correctDocument(doc) {
      try {
        let document_id = doc._id;
        await this.$store.dispatch("documents/correctDocument", document_id);
        if (this.getDocumentCorrectStatus) {
          successNotification("Document is corrected successfully");
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: document_id,
            },
            query,
          });
        } else {
          let error_message = this.getDocumentCorrectErrors
            ? this.getDocumentCorrectErrors
            : "Error while correcting the document";
          this.$notify.error({
            title: "Error",
            message: error_message,
          });
          this.$emit("reloadDashboardStats", true);
          this.fetchTemplates();
        }
      } catch (err) {
        console.log(" correctDocument", err);
      }
    },
    isfilledOrNot(doc) {
      if (
        doc &&
        doc.document_status &&
        doc.configurable_document_data &&
        doc.configurable_document_data[0]
      ) {
        let fields = doc.configurable_document_data.pages[0].fields.filter(
          (el) => {
            if (
              el.filled_by &&
              el.filled_by != "SENDER" &&
              el.type != "my-signature" &&
              el.type != "MY_INITIAL"
            ) {
              return el;
            }
          }
        );
        let fields_data = doc.document_data;
        let filled_fields = fields.filter((el) => {
          return fields_data[el.key].length;
        });
        if (
          filled_fields.length &&
          doc.document_status != "DECLINED" &&
          doc.document_status != "COMPLETED" &&
          doc.document_status != "ARCHIVED"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    async shareTemplate(data) {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.email) {
        this.configureLoading = true;
        this.LoadingText = "Sharing  Template...";
        this.setSharedContacts(data);
        this.selectedTemplateId = data._id;
        this.template_title = data.title;
        let contactsData = await axios.get("/contact-users");
        if (contactsData && contactsData.data && contactsData.data.data) {
          this.contactUsers = contactsData.data.data;
          this.configureLoading = false;
          this.templateShareModal = true;
        }
      }
      this.selectedUsers = this.selectedUsers.filter((cnt) =>
        this.contactUsers.map((e) => e.email).includes(cnt)
      );
      this.selectedUser = this.selectedUser.map((el) => {
        this.contactUser.find((ele) => {
          if (ele.email != el) {
            delete this.selectedUser.el;
          }
        });
      });
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchTemplates();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchTemplates();
      }
    },
    async fetchTemplates() {
      this.authid = this.getAuthenticatedUser._id;
      try {
        this.configureLoading = true;
        this.LoadingText = "Fetching Templates...";
        let params = {
          limit: this.pageSize > 0 ? this.pageSize : 10,
          page: this.page,
        };
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        if (this.selectedStatus) {
          params.status = this.selectedStatus;
        }
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (this.$route.query.group && this.$route.query.group.length) {
          params.group = this.$route.query.group;
          await this.$store.dispatch(
            "entityGroups/fetEntityGroupDataById",
            this.$route.query.group
          );
        }
        if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
          await this.$store.dispatch(
            "roles/fetchRoleById",
            this.getAuthenticatedUser.activeRole.role_id
          );
          if (this.getRoleById && this.getRoleById) {
            params.groups = this.getRoleById.allowed_groups;
          }
        }
        await this.$store.dispatch("documents/fetchAllTemplates", params);
        this.templates =
          this.getAllTemplates && this.getAllTemplates.data
            ? this.getAllTemplates.data
            : [];
        this.total =
          this.getAllTemplates && this.getAllTemplates.total
            ? this.getAllTemplates.total
            : 0;
        let configureDocumentIds = this.templates.flatMap(
          (temp) => temp.configurable_document_id
        );
        await this.getEmployeeDocumentsCount(configureDocumentIds);
        this.configureLoading = false;
        this.expiraydate =
          this.getSingleDocumentData &&
          this.getSingleDocumentData.data &&
          this.getSingleDocumentData.data.document_settings &&
          this.getSingleDocumentData.data.document_settings
            .expiration_settings &&
          this.getSingleDocumentData.data.document_settings.expiration_settings
            .document_expired_date
            ? this.getSingleDocumentData.data.document_settings
                .expiration_settings.document_expired_date
            : null;
      } catch (err) {
        this.configureLoading = false;
      }
    },
    async getEmployeeDocumentsCount(ids) {
      try {
        let params = {
          configureDocumentIds: ids,
        };
        await this.$store.dispatch(
          "documents/fecthDocumentsCountByTemplatId",
          params
        );
        // if (this.getDocumentsCountByTemplatId) {
        //   this.templates.map((template) => {
        //     let complatedValue = 0;
        //     let progressValue = 0;
        //     this.getDocumentsCountByTemplatId.forEach((doc) => {
        //       if (
        //         template.configurable_document_id ==
        //         doc.configurable_document_id
        //       ) {
        //         if(doc.document_status == 'COMPLETED'){
        //           complatedValue = complatedValue + 1;
        //         }else if(doc.document_status != 'EXPIRED' || doc.document_status != 'DRAFT'){
        //           progressValue = progressValue + 1;
        //         }
        //       }
        //     });
        //     template.completed_document_count = complatedValue
        //     template.progress_document_count = progressValue
        //   });
        // }
      } catch (err) {
        console.log("getEmployeeDocumentsCount", err);
      }
    },
    setSharedContacts(data) {
      if (data && data.shared_users) {
        this.currentExistedUsers = data.shared_users;
        this.selectedUsers = data.shared_users;
      }
    },
    closeisShared() {
      this.is_shared;
    },
    async removeSharedUser(data) {
      if (data && data.shared_users) {
        let Remove_Shared_user = data.shared_users.findIndex(
          (user) =>
            this.getAuthenticatedUser &&
            this.getAuthenticatedUser.email &&
            user == this.getAuthenticatedUser.email
        );
        let new_user = data.shared_users.splice(Remove_Shared_user, 1);
        console.log("Remove_Shared_user", new_user);
        console.log(Remove_Shared_user);
      }
      let params = {
        contact_users: data.shared_users,
        companyDocumentId: data._id,
      };
      await this.$store.dispatch(
        "templates/shareTemplatesToContactUsers",
        params
      );
      this.fetchTemplates();
      this.$notify.success({
        title: "Success",
        message: "Shared template removed successfully",
      });
    },

    async onEdit(document) {
      let isCompanyUser = false;
      let hasUser = false;
      let isCreator =
        document.created_by._id.toString() ==
        this.getAuthenticatedUser._id.toString();
      if (this.getActiveWorkspace && this.getActiveWorkspace.company_id) {
        isCompanyUser =
          document &&
          document.company_id &&
          this.getActiveWorkspace.company_id.toString() ==
            document.company_id.toString();
      }
      if (this.getAuthenticatedUser) {
        hasUser =
          document &&
          document.document_users &&
          document.document_users.find(
            (u) => u.email == this.getAuthenticatedUser.email
          );
      }
      if (
        (!isCreator && !this.checkPermissions("viewDocumentTemplate")) ||
        (!isCreator &&
          this.checkPermissions("editDocument") &&
          !this.checkPermissions("viewDocumentTemplate"))
      ) {
        this.$message.error(
          "Permission Denied For View Template. Please contact Owner"
        );
      } else {
        if (!isCreator && !this.checkPermissions("editDocument")) {
          this.$message.error("Permission Denied. Please contact Owner");
        }
        if (
          hasUser ||
          (this.checkPermissions("editDocument") && isCompanyUser)
        ) {
          if (document.is_published && !this.checkPermissions("getLink")) {
            this.$notify.error({
              title: "Error",
              message:
                "Permission denied to get link .This template is published please contact owner.!",
            });
          } else {
            let query = this.getNavigationQuery(this.$route.query);
            this.$router.push({
              name: "edit-configure-template-image",
              params: {
                document_id: document._id,
                configurable_document_id: document.configurable_document_id,
              },
              query,
            });
          }
        }
        if (
          document &&
          document.created_by &&
          document.created_by._id != this.authid
        ) {
          this.is_shared = true;
          this.selectedSharedTemplate = document;
          this.selectedDuplicateTemplate = document;
        } else {
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: "edit-configure-template-image",
            params: {
              document_id: document._id,
              configurable_document_id: document.configurable_document_id,
            },
            query,
          });
        }
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchTemplates();
      }
    },
    async makeInactive(data) {
      //this.configureLoading = true;
      let params = {};
      if (data.status == "ACTIVE") {
        params.status = "INACTIVE";
      }
      if (data.status == "INACTIVE" || data.status == "ARCHIVED") {
        params.status = "ACTIVE";
      }
      params.id = data._id;
      await this.$store.dispatch(
        "configurableDocuments/updateConfigurableTemplateStatus",
        params
      );
      this.configureLoading = false;
      if (this.getConfigurableDocumentStatusUpdateStatus) {
        this.successNotify("Template status updated successfully");
        await this.fetchTemplates();
      } else {
        this.errorNotify("Error in template status updating");
      }
    },
    async goToCreateDocument() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        name: "upload-templates",
        query,
      });
    },

    // sending data to the modal for sending documents to the users
    async handleSendDocumentToUsers(data) {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          { id: data.configurable_document_id }
        );
        let shareTemplate = false;
        if (
          data.user_id != this.authid &&
          data.company_id != this.getActiveWorkspace.company_id
        ) {
          shareTemplate = true;
        }
        this.createDraft(data, data._id, shareTemplate);
        // this.documentUsers = this.getConfigurableDocumentDataById.document_users;
        // this.showSendDocumentoUserSettings = true;
        // this.selectedCompanyDocument = this.getConfigurableDocumentDataById;
        this.loading = false;
      } catch (err) {
        console.log("handleSendDocumentToUsers", err);
      }
    },
    async createDraft(data, document_id, shareTemplate) {
      try {
        if (
          this.getAuthenticatedUser &&
          data &&
          data.created_by &&
          data.created_by._id != this.getAuthenticatedUser._id &&
          !this.checkPermissions("userCreateDocment")
        ) {
          this.errorNotify(
            "Permission denied for create document.Please contact owner"
          );
        } else if (
          shareTemplate &&
          !this.checkPermissions("sharedUserCreateDocument")
        ) {
          this.errorNotify(
            "Permission denied for shared user create document.Please contact owner"
          );
        } else {
          this.configureLoading = true;
          this.LoadingText = "Loading...";
          let companyDocumentData = this.getDocumentDataByConfigurable(
            this.getConfigurableDocumentDataById
          );

          let documentData = this.prepareFieldsData(
            companyDocumentData &&
              companyDocumentData.pages &&
              companyDocumentData.pages[0] &&
              companyDocumentData.pages[0].fields
          );
          let params = {
            document_template_id: document_id,
            document_users: companyDocumentData.document_users,
            enforce_signature_order:
              companyDocumentData.enforce_signature_order,
            document_data: documentData,
            companayDocumentData: companyDocumentData,
            is_document: false,
            configurabledocumentid: this.getConfigurableDocumentDataById,
            isShareTemplate: shareTemplate,
          };
          if (
            this.getConfigurableDocumentDataById &&
            this.getConfigurableDocumentDataById.ck_editor_content &&
            this.getConfigurableDocumentDataById.ck_editor_content !== ""
          ) {
            params.is_from_ck_editor = true;
            params.is_ck_editor_document = true;
          }

          await this.$store.dispatch("documents/createDraftDocument", params);

          if (this.getDraftDocumentCreateStatus) {
            let draftId = this.getDraftDocumentCreateData.data._id;
            let query = this.getNavigationQuery(this.$route.query);
            this.$router.push({
              name: "employee-documents-custom-document-edit",
              params: {
                employee_document_id: draftId,
              },
              query,
            });
          }
          this.configureLoading = false;
        }
      } catch (err) {
        this.configureLoading = false;
        console.log("createDraft", err);
      }
    },
    onPermanentDeleteConfirmation(template) {
      this.$confirm(`Are you sure to permanently delete the ${template.title}`)
        .then(() => this.onPermanentDelete(template))
        .catch(() => {});
    },
    async onPermanentDelete(template) {
      let CompanyId = template._id;
      try {
        let params = {
          company_document_id: CompanyId,
          configurable_document_id: template.configurable_document_id,
        };

        this.configureLoading = true;
        this.LoadingText = "Deleting Template...";
        await this.$store.dispatch(
          "documents/permanentdeleteDocumentTemplate",
          params
        );
        if (this.getDocumentTemplatePermanentDeleteStatus) {
          this.successNotify("Template permanently deleted successfully");
          await this.fetchTemplates();
        } else {
          this.errorNotify("Error in deleting Template");
        }
        this.configureLoading = false;
      } catch (err) {
        this.errorNotify("Error in deleting Template");
      }
    },
    onDeleteConfirmation(template) {
      this.$confirm(
        `Are you sure to delete the " ${template.title}" template ?`
      )
        .then(() => this.onDelete(template._id))
        .catch(() => {});
    },
    async onDelete(template_id) {
      try {
        this.configureLoading = true;
        this.LoadingText = "Deleting Template...";
        this.isTemplateDeleting = true;
        await this.$store.dispatch(
          "documents/deleteDocumentTemplate",
          template_id
        );
        if (this.getDocumentTemplateDeleteStatus) {
          this.successNotify("Template deleted successfully");
          await this.fetchTemplates();
        } else {
          let message = "";
          if (this.getDeleteDocumentTemplateError) {
            message = this.getDeleteDocumentTemplateError;
          } else {
            message = "Error in deleting Template";
          }
          this.errorNotify(message);
        }
        this.configureLoading = false;
      } catch (err) {
        this.errorNotify("Error in deleting Template");
      }
    },
    openNameModal(id, name, action, userId, configId, companyId) {
      try {
        this.modalData.template_id = id;
        this.modalData.name = name.trim();
        this.modalData.action = action;
        this.userId = userId;
        this.modalData.configId = configId;
        this.companyId = companyId;
        this.documentActionModal = true;
        this.is_shared = false;
      } catch (err) {
        console.log("openNameModal", err);
      }
    },
    handleDocAction() {
      if (this.modalData.action == "duplicate") {
        this.duplicateTemplate();
      } else {
        this.renameTemplate();
      }
    },
    async duplicateTemplate() {
      this.modalLoading = true;
      let shareTemplate = false;
      let duplicateTemp = true;
      if (
        this.userId != this.authid &&
        this.companyId != this.getActiveWorkspace.company_id
      ) {
        shareTemplate = true;
      }
      this.modalData.isShareTemplate = shareTemplate;
      this.modalData.is_duplicate = duplicateTemp;
      await this.$store.dispatch("templates/duplicateTemplate", this.modalData);
      this.modalLoading = false;
      if (this.getDuplicateTemplateStatus) {
        this.successNotify("Document duplicated successfully");
        this.documentActionModal = false;
        this.fetchTemplates();
      } else {
        if (!this.checkPermissions("duplicateTemplate")) {
          this.errorNotify("Permission Denied.Please Contact Owner");
        } else {
          this.errorNotify("Error in duplicating document");
        }
      }
    },
    async renameTemplate() {
      this.modalLoading = true;
      await this.$store.dispatch("templates/renameTemplate", this.modalData);
      this.modalLoading = false;
      if (this.getRenameTemplateStatus) {
        this.successNotify("Document title updated successfully");
        this.documentActionModal = false;
        this.fetchTemplates();
      } else {
        this.errorNotify("Error in updating document title");
      }
    },
    checkPermissions(permission) {
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
  },
  watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchTemplates();
    },
    selectedStatus: function (val) {
      this.selectedStatus = val;
      this.fetchTemplates();
    },
    "$route.query.group"(group) {
      console.log(group);
      Promise.all([
        this.fetchTemplates(),
        this.fetchContactTypes(),
        this.companyEntityDetails(),
        this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          type: "CONTACT_TYPE",
        }),
      ]);
    },
    "$route.query.key"() {
      Promise.all([
        this.fetchTemplates(),
        this.fetchContactTypes(),
        this.companyEntityDetails(),
        this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          type: "CONTACT_TYPE",
        }),
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-csv-file {
  .block-types {
    .upload-file {
      width: 100% !important;
      height: 100%;
    }
  }
}

.download-csv {
  background: transparent;
  border: 1px solid #409eff;
  color: #409eff;
  margin-top: -5px;
  height: 25px;
  text-align: center;
}

.upload-file {
  width: 100%;
}

.modalSty {
  border: 1px solid #ebeef5;
}

.tableMenu {
  height: 350px;
  overflow-y: scroll;
}

.tableMenu::-webkit-scrollbar {
  width: 0.7em;
}

.tableMenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.tableMenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.sidemenu {
  height: 350px;
  overflow-y: scroll;
}

.sidemenu::-webkit-scrollbar {
  width: 0.7em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.doc-title {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  margin-bottom: 8px;
  color: #6c757d !important;
}

.doc-title-mobile {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  margin-bottom: 100px !important;
}

.doc-sub-mobile {
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  margin-top: 0px !important;
}

.doc-sub {
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  margin-top: -10px !important;
}

.all-templates-view {
  // max-width: 768px;
  .search {
    position: relative;

    // right: -199px;
    // width: 200px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .select {
    position: relative;

    // right: -199px;
    // width: 200px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .border {
    position: relative;

    // right: -199px;
    // width: 200px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  tbody {
    tr {
      td {
        .group-grid {
          span {
            background: #ecf5ff;
            border: 0.5px solid #d9ecff;
            border-radius: 3px;
            display: inline-block;
            padding: 0 5px;
            color: #077dff;
            margin: 2px 3px;
          }

          &:not(:last-child) {
            margin-right: 10px;
          }
        }

        &:nth-child(1) {
          img {
            max-width: 20px;
            max-height: 25px;
          }
        }

        &:nth-child(5) {
          td {
            border-bottom: none;
          }

          .cell {
            border-radius: 3px;
            display: inline-block;
            border-bottom: none;
            color: #000;

            &:not(:last-child) {
              margin-right: 10px;
            }

            p {
              margin: 5px 0 0 0;
              font-size: 12px;
              letter-spacing: 0.5px;
              color: rgba(0, 0, 0, 0.7);
              line-height: 15px;
            }
          }
        }

        &:nth-child(2) {
          td {
            border-bottom: none;
          }

          .cell {
            color: #1b487e;
            font-weight: 500;
          }
        }
      }

      .cell {
        color: #404040;
        font-size: 16px !important;
      }
    }
  }

  .add-delete-btn {
    button {
      padding: 7px;
      text-align: center;
    }
  }

  .details {
    font-family: inter;
    font-size: 14px;
    font-weight: 600;
  }

  .table {
    font-family: inter;
    font-size: 12px;
  }

  .pagination-bar {
    padding: 8px 0;
  }

  .icon-block {
    display: grid;
    grid-template-columns: 25px auto;
    gap: 10px;
    align-items: center;

    .icon-text {
      td {
        border-bottom: none !important;
        font-weight: 500;
        color: #000;
      }
    }
  }

  .warningmsg {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }

  .title {
    letter-spacing: 0.019em;
  }

  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;

    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }

    &.active::after {
      background: #08ad36;
    }

    &.inactive::after {
      background: #f562b3;
    }

    &.archived::after {
      background: #404040;
    }

    &.disable::after {
      background: #c4c4c4;
    }

    &.viewed::after {
      background: #2f80ed;
    }
  }
}
</style>

<style lang="scss">
.doc-modal {
  .el-dialog__header {
    .el-dialog__title {
      text-transform: uppercase;
    }
  }

  .error {
    color: red;
  }
}

.el-dialog__body {
  max-height: none;
}
</style>
<style lang="scss" scoped>
.el-tag {
  cursor: pointer;
  text-align: center;
  border-radius: 20px;
  padding: 4px;
  line-height: 1;
  width: 30px;
  height: auto;

  span {
    margin-left: -0.7em !important;
  }
}

.activestatus {
  font-size: 14px;
  color: #6c757d !important;
}

.error-tab {
  // background-color: brown !important;
}

// Put it under a class name to prevent contamination of other styles
.claim_company {
  display: inline-block;

  .el-table {
    display: inline-block;

    // overflow: auto;
    .el-table__body-wrapper {
      display: inline-block !important;
    }
  }

  // .el-table__body-wrapper,
  // .el-table__header-wrapper,
  // .el-table__footer-wrapper {
  //   overflow: visible;
  // }
  // .el-table::after {
  //   position: relative !important;
  // }
}
.top-buttons-wrapper {
  @media (max-width: 991.98px) {
    display: flex;
    margin-top: 20px;
  }
}
.el-scrollbar__thumb {
  width: 20px;
}

.bulk-send-table {
  min-height: 200px;
}

.el-pagination {
  background: white;
}

.el-divider--horizontal {
  margin: 16px 0;
}

.import-dropdown-btn {
  margin-left: 10px;
  height: 40px;

  .el-button {
    height: 32px !important;
    font-size: 14px;
    width: 110px;

    span {
      padding: 0 10px;
    }
  }
}

.import-dropdown-btn-menu {
  .el-dropdown-menu__item {
    display: flex;

    img {
      width: 25px;
      height: 25px;
      padding: 3px;
      background: #f7f7f7;
      border-radius: 10px;
    }

    span {
      margin-top: -2px;
      margin-left: 10px;
    }
  }
}

.DialogueStyle {
  margin-top: 50px;
}

.custom-pagination {
  text-align: center;
  margin-top: 5px;
}

.tabSty {
}

.scaling-button {
  transform: scale(1);
  transition: transform 0.3s;
}

.scaling-button:hover {
  transform: scale(1.03);
}

.backBtn {
  padding: 10px;
  align-items: center;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto;
  scrollbar-width: thin;
}

.all-templates-view .icon-block {
  display: grid;
  grid-template-columns: 25px auto;
  gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px !important;
}
.el-button:hover {
  color: var(--primary-contrast-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:hover {
  color: #fff;
  background-color: var(--lighter-background);
  border-color: var(--lighter-background);
}
.el-button:hover {
  color: var(--primary-color);
  background-color: var(--primary-contrast-color);
  border-color: var(--primary-color);
}
</style>
